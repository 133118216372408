import React, { useEffect, useState } from "react";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { Button } from "../ui/button";
import { auth, db } from "../firebase";
import { useToast } from "../ui/use-toast";
import { collection, query } from "firebase/firestore";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import { fetchUserData } from "../../actions/get-user-data";
import { sendNotification } from "../../actions/send-notification";
import { FriendSelect } from "./FriendSelect";
import { sendAnimateNotification } from "../../actions/send-animate-notification";
import { useSetRecoilState } from "recoil";
import { animateFriendState } from "../../recoilState/animateFriendState";
import { useNavigate } from "react-router-dom";

function Landing() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const usersRef = collection(db, "newusers");
  const [timerFocusMinutes, settimerFocusMinutes] = useState(40);
  const allUsersQuery = query(usersRef);
  const [invitedMember, setInvitedMember] = useState();
  const setFriend = useSetRecoilState(animateFriendState);
  // {
  //   name: auth.currentUser.displayName,
  //   email: auth.currentUser.email,
  //   imageurl: auth.currentUser.photoURL,
  //   id: auth.currentUser.uid,
  // },
  // console.log(auth.currentUser.id,auth.currentUser);

  const [allUsers] = useCollectionData(allUsersQuery, { idField: "id" });
  const [friendEmails, setFriendEmails] = useState([]);
  async function getFriends() {
    const friendList = await fetchUserData();
    setFriendEmails(friendList?.userFriends);
  }
  useEffect(() => {
    getFriends();
  }, []);
  // console.log(invitedMembers);
  async function handleInvite() {
    if (!invitedMember) {
      toast({
        title: "Mate not selected",
        description: "You have not added any friend",
        // variant:"destructive"
      });
      return;
    }
    setFriend(invitedMember);
    navigate("/animate?admin=true");
    //maybe create a atom for invitedMEmber
    // and create another modal for
    // sendAnimateNotification(invitedMember,callId)
  }
  return (
    <>
      <div className="text-center my-2 text-sm text-muted-foreground text-[#928080]">
        Watch Anime Together
      </div>
        <div className="text-center text-xs text-red-600  sm:hidden">Admin should initiate only through a PC</div>
      <div className="gap-4  w-full flex justify-center items-center my-4">
        <Avatar className=" border-2 border-[#3d3b3b]">
          <AvatarImage src={auth.currentUser?.photoURL} />
          <AvatarFallback className=" text-white font-bold bg-[#302d2d] ">
            {localStorage.getItem("userName")[0]}
          </AvatarFallback>
        </Avatar>
        {invitedMember && (
          <Avatar className=" border-2 border-[#3d3b3b]">
            <AvatarImage src={invitedMember.imageurl} />
            <AvatarFallback className=" text-white font-bold bg-[#302d2d] ">
              {invitedMember.name[0]}
            </AvatarFallback>
          </Avatar>
        )}
      </div>
      <div className="dark">
        <div className="text-[#928080] font-semibold text-center text-lg mt-3">
          Select Mate
        </div>
        <div className="flex flex-col justify-center items-center pt-2">
          {allUsers && friendEmails && (
            <FriendSelect
              invitedMember={invitedMember}
              setInvitedMember={setInvitedMember}
              allUsers={allUsers}
              friendEmails={friendEmails}
            />
          )}
          {/* <ProfileScroll memberDisplay={true} members={invitedMembers} /> */}
        </div>
      </div>
      <div className="flex items-center justify-center px-3 my-3">
        <Button
          onClick={() => {
            handleInvite();
          }}
          className="text-[#928080] font-bold rounded-3xl neumorphism bg-[#262424]"
        >
          Invite
        </Button>
      </div>
    </>
  );
}

export default Landing;
