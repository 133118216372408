import { deleteSpace } from "../../../actions/delete-space";
import { addUserSession } from "../../../actions/add-user-session";
import { Minus, Plus } from "lucide-react";
import React, { useEffect, useState } from "react";
import { useTimer } from "react-timer-hook";
import { useRecoilState, useRecoilValue } from "recoil";
import { spaceState } from "../../../recoilState/spaceState";
import { completionModalState } from "../../../recoilState/completionModalState";
import { modalNotificationState } from "../../../recoilState/modalState";

function Timer({
  timerFocusMinutes,
  settimerFocusMinutes,
  runMode = false,
  setMinutesValue,
}) {
  const expiryTimestamp = new Date();
  const [started, setstarted] = useState(false); //for buttons
  const [space, setSpace] = useRecoilState(spaceState);
  const [notification, setNotification] = useRecoilState(
    modalNotificationState
  );
  const [completionModal, setCompletionModal] =
    useRecoilState(completionModalState);
  const { hours, seconds, minutes, isRunning, start, pause, resume, restart } =
    useTimer({
      expiryTimestamp,
      onExpire: async () => {
        setCompletionModal((p) => ({
          duration: timerFocusMinutes,
          open: true,
        }));
        setNotification(null);
        await addUserSession(timerFocusMinutes);
        await deleteSpace(space.id);
        setSpace(null);
      },
      autoStart: false,
    });
  useEffect(() => {
    setTime(timerFocusMinutes);
  }, [timerFocusMinutes]);
  useEffect(() => {
    if (runMode) {
      setMinutesValue(hours * 60 + minutes);
    }
  }, [minutes, hours]);
  async function changeTimer5(add) {
    if (add) {
      if (timerFocusMinutes >= 180) {
        return;
      }
      settimerFocusMinutes((p) => {
        setTime(p + 5);
        return p + 5;
      });
    } else {
      if (timerFocusMinutes <= 5) {
        return;
      }
      settimerFocusMinutes((p) => {
        setTime(p - 5);
        return p - 5;
      }); //todo change
    }
    setTime();
  }
  function setTime(focusMinutes) {
    const curtime = new Date();
    curtime.setSeconds(curtime.getSeconds() + 60 * focusMinutes);
    restart(curtime, runMode ? true : false);
  }
  return (
    <div className="p-2">
      <div
        className={` w-full flex justify-between items-center timer-font text-[4.5rem] ${
          runMode && "!text-[3.3rem] !font-bold"
        } `}
        onChange={async () => {}}
      >
        {" "}
        {!runMode && (
          <span>
            <button
              onClick={() => {
                changeTimer5(false);
              }}
              // className="btn btn-outline-secondary btn-timer m-3"
              className={` btn btn-outline-secondary btn-timer  rounded-full py-[0.7rem]`}
            >
              <Minus className="" size={12} />
            </button>
          </span>
        )}
        <div className="flex items-center">
          <span>
            {hours * 60 + minutes < 10 ? "0" + minutes : hours * 60 + minutes}
          </span>
          <span className="mb-3">:</span>
          <span>{seconds < 10 ? "0" + seconds : seconds}</span>
        </div>
        {!runMode && (
          <span>
            <button
              onClick={() => {
                changeTimer5(true);
              }}
              className={`btn btn-outline-secondary btn-timer  rounded-full p-2`}
            >
              <Plus size={20} />
            </button>
          </span>
        )}
      </div>
    </div>
  );
}

export default Timer;
