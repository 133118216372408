import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db,auth } from "../components/firebase";
import firebase from "firebase/compat/app";

export async function sendAnimateNotification(recipient,callId) {
const ts = firebase.firestore.Timestamp.now();
  const notification = {
    admin_name:auth.currentUser.displayName,
    timestamp:ts,
    callId:callId
  };
  const userRef = doc(db, "users", recipient.id);
  const userSnapshot = await getDoc(userRef);

  if (!userSnapshot.exists()) {
    await setDoc(userRef, {
      status: {
        online: false,
        studying: false,
        email: recipient.email,
        name: recipient.name,
        img: recipient.imageurl,
      },
      room_notifications: [],
    });
  }
  
  await updateDoc(userRef, {
    room_notifications: [notification],
  });
  console.log("Notification sent successfully!");
}