import React from 'react'
import { Button } from '../ui/button'
import ShowFriends from './run/show-friends'
import { AnimatedTimer } from './run/animated-timer';
import { useRecoilState, useRecoilValue } from 'recoil';
import { spaceState } from '../../recoilState/spaceState';

const RunningSpace = () => {
    
      const space = useRecoilValue(spaceState);
// console.log(space);
  return (
    <>
      <div className="text-lg font-semibold text-center -mt-3  text-muted-foreground text-[#928080]">
       
        <ShowFriends friends={space.joined}/>
      </div>

      <div className=" w-full flex justify-center items-center mt-1 mb-2">
            
            <AnimatedTimer time={space.timer.duration}/>
      </div>
      <div className="flex items-center justify-center px-3 my-2">
               
      </div>
    </>
  )
}

export default RunningSpace