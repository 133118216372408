import React, { useEffect } from "react";
import { Button } from "../ui/button";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { useRecoilState } from "recoil";
import { spaceState } from "../../recoilState/spaceState";
import { ripples } from "ldrs";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";
import RunningSpace from "./running-space";
import { startTimer } from "../../actions/start-space-timer";
import { listenForSpaceChanges } from "../../actions/space-listener";

const UsersBlock = ({ users, joined ,deduct }) => {
  // console.log(users);
  return (
    <div className={`w-[50%] ${!joined&&"-mr-1"} flex flex-col justify-center items-center`}>
      <div className="text-muted font-normal mb-1">
        {joined ? "Joined" : "Waiting"}
      </div>
      <div
        className={` ${!joined && "ml-1"} ${
          joined && "border-r"
        } flex flex-wrap   items-center max-w-full w-full min-h-[120px]  `}
      >
        {users.map((user) => {
          if(deduct){
            {/* console.log(deduct) */}
            const found=deduct.some((usr)=>usr.id===user.id);
            {/* console.log(found) */}
            if(found){return;}
          }
          return (
            <TooltipProvider key={user.id}>
              <Tooltip>
                <TooltipTrigger>

                <Avatar
                  className={` ${
                    !joined && "opacity-40"
                  } m-2 !w-[38px] !h-[38px]  border-[#3d3b3b] hover:scale-110 transition-all cursor-pointer`}
                >
                  <AvatarImage
                    className="!w-[38px] !h-[38px]  "
                    src={user.imageurl}
                  />
                  <AvatarFallback className=" text-white font-bold bg-[#302d2d] ">
                    {user.name[0]}
                  </AvatarFallback>
                </Avatar>
                </TooltipTrigger>
                <TooltipContent className=" text-xs ">
                  {user.name}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          );
        })}
      </div>
    </div>
  );
};
const WaitingRoom = () => {
  const [space, setSpace] = useRecoilState(spaceState);
  useEffect(() => {
    function onTimerStart() {
      setSpace(p=>({...p,timer:{...p.timer,isRunning:true}}));
    }
    function onUserJoin(joinedUsers) {
      setSpace(p=>({...p,joined:joinedUsers}))
    }
    let detachListener;
    const listenForChanges = async () => {
      detachListener = await listenForSpaceChanges(
        space.id,onTimerStart,onUserJoin,space.isAdmin,localStorage.getItem("uid")
      );
    };
    listenForChanges();
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      if(detachListener)detachListener();
      event.returnValue = ''; 
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      if(detachListener)detachListener();
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [setSpace, space.id, space.isAdmin]);
  ripples.register();
  
  if(space.timer.isRunning){
    return (<RunningSpace/>);
  }
  
  
  
  return (
    <>
      <div className="text-lg font-semibold text-center my-2  text-muted-foreground text-[#928080]">
        Waiting Room
      </div> 

      <div className=" w-full flex justify-between mt-3 mb-2">
        <UsersBlock users={space.joined} joined={true} />
        <UsersBlock users={space.participants} deduct={space.joined} joined={false} />
      </div>
      <div className="flex items-center justify-center px-3 my-2">
        {space.isAdmin ? (
          <Button
            onClick={() => {
              startTimer(space.id);
            }}
            className="text-[#928080] font-bold rounded-3xl neumorphism bg-[#262424] mt-2"
          >
            Start Timer
          </Button>
        ) : (
          <div className="flex flex-col justify-center items-center">
            <l-ripples size="45" speed="2" color="#867777"></l-ripples>
            <div className="text-theme-100 text-xs mt-1">
              Waiting for Admin to Start
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default WaitingRoom;
