import firebase from "firebase/compat/app";
import {getFirestore} from 'firebase/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/database';
console.log("upen")
  export const app = firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  });
  
export const auth=firebase.auth();
export const db=getFirestore();
export const realtimeDb = app.database();
export  {firebase};
