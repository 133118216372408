import React, { useState } from "react";
import DetailCard from "./DetailCard";
import Tags from "./Tags";


const RoomCard = (props) => {
  
  const [clicked, setclicked] = useState(false);
  // console.log(props);
  return (
    <>

      <div className="col mb-5  d-flex justify-content-center "
        onClick={() => { props.setclicked(props.index) }}>

        <div className="roomcard">
          {props.roomData.image_link?<img className="roomimg" src={props.roomData.image_link} alt="grpimg" />:
          <div
            className="roomimg"
            style={{ backgroundColor: "black",backgroundImage:props.roomData.rand_pattern_url
            }}
          >
          </div>}
          <div className="cardbody">
            <div className="roomtitle">{props.roomData.roomname}</div>
            <div className="descbody">
              <div className="roomdesc">Target Time: {props.roomData.target_time} hrs</div>
              <div className="roomdesc">Members: {props.roomData.members.length}</div>
            </div>
            <div className="descbody">
              <div className="roomdesc"><em> Admin: </em></div>
              <div className="roomdesc"><em> {props.roomData.admin_email}</em></div>

            </div>
            <div><Tags tag1={props.roomData.tag1}
              tag2={props.roomData.tag2}
            /></div>

          </div>
        </div>
      </div>
    </>);
};

export default RoomCard;
