import { realtimeDb } from "../components/firebase";

export const startTimer = async (spaceId) => {
  try {
    const timerRef = realtimeDb.ref(`spaces/${spaceId}/timer`);
    await timerRef.update({ isRunning: true, startTime: Date.now() });
    console.log("Timer started");
  } catch (error) {
    console.error("Error starting timer:", error);
    throw error; // Re-throw the error so the caller can handle it if needed
  }
};
