import React from 'react'
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
  } from "../../ui/tooltip";
  import { Avatar, AvatarFallback, AvatarImage } from "../../ui/avatar";

const ShowFriends = ({friends}) => {
  return (
    <div
        className={`no-scrollbar ${friends.length>5?"justify-start":"justify-center"}  flex overflow-x-scroll  items-center max-w-full w-full min-h-[120px]  `}
      >
        {friends.map((user) => {
          return (
            <TooltipProvider key={user.id} >
              <Tooltip>
                <TooltipTrigger>

                <Avatar
                  className={`mx-2 !w-[50px] !h-[50px]  border-[#3d3b3b] hover:scale-110 transition-all cursor-pointer`}
                >
                  <AvatarImage
                    className="!w-[50px] !h-[50px]  "
                    src={user.imageurl}
                  />
                  <AvatarFallback className=" text-white font-bold bg-[#302d2d] ">
                    {user.name[0]}
                  </AvatarFallback>
                </Avatar>
                </TooltipTrigger>
                <TooltipContent className=" text-xs ">
                  {user.name}
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          );
        })}
      </div>
  )
}

export default ShowFriends