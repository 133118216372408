import React, { useState } from "react";
import {db} from "./firebase";
import {arrayRemove, updateDoc,arrayUnion,setDoc ,doc} from "firebase/firestore";

export default function Requestcards(props) {
    const [loading, setloading] = useState(false);
    
    async function addmember() {
        
        setloading(true);
        const roomRef = doc(db, "Rooms", props.roomname);
        const newMember = {
            name: props.profile.name,
            email: props.profile.email,
            uid: props.profile.uid,
            imageurl: props.profile.imageurl
        };
// console.log("memcreation")
        await updateDoc(roomRef, {
            members: arrayUnion(newMember)
        }).then(setloading(false));
        // console.log("aded");
    }
    async function decline() {
        setloading(true);
        const roomRef = doc(db, "Rooms", props.roomname);

        await updateDoc(roomRef, {
            requests: arrayRemove({
                name: props.profile.name,
                email: props.profile.email,
                uid: props.profile.uid,
                imageurl: props.profile.imageurl
            }),
          }).then(setloading(false));
          
        //   console.log("declined");
        //   props.requests. = array.filter((item) => item.id !== 3);

    }
    return (
        <div className=" mb-3 col">
            <div className="requestcard">
                <img
                    src={props.profile.imageurl}
                    alt=""
                />
                <div className="requestname">{props.profile.name}</div>

                <div className="requestbtncont">
                    <button className="raccept" onClick={()=>{addmember();decline();}} disabled={loading}>
                        Accept
                    </button>
                    <button className="rdecline"
                    onClick={()=>{decline();}}
                    disabled={loading}
                    >
                        Decline
                    </button>
                </div>

            </div>
        </div>
    );
}
