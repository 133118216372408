import React, { useEffect } from "react";
import Draggable from "react-draggable";
import { useRef } from "react";
import { useState } from "react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { SortableItem } from "./SortableItem";
import Confetti from "react-dom-confetti";
import { Howl } from "howler";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Maximize2, Minimize2 } from "lucide-react";
import { cn } from "../utils/cn";

function SelectPriority({ priority, setpriority, disabled }) {
  return (
    <Select onValueChange={setpriority} disabled={disabled}>
      <SelectTrigger
        className={cn(
          "w-[61px] right-7 font-semibold text-[#504a4a] bg-[#1b1919] absolute  border-[1px]  border-[#504a4a]",
          disabled && "mr-5"
        )}
      >
        <SelectValue className="dark " placeholder="🔴" defaultValue={"0"} />
      </SelectTrigger>
      <SelectContent className="dark bg-[#1b1919] border-none border-[#504a4a]">
        <SelectGroup className="dark bg-[#1b1919] border-[#504a4a]">
          <SelectLabel>Priority</SelectLabel>
          <SelectItem value="0">🔴 Urgent</SelectItem>
          <SelectItem value="1">🟡 Moderate</SelectItem>
          <SelectItem value="2">🟢 Low</SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

function TodoList({ listVisible }) {
  const nodeRef = useRef(null);
  const todos = [
    { id: 1, priority: 1, title: "Physics Homework", completed: false },
    { id: 2, priority: 2, title: "Laundry", completed: false },
    { id: 3, priority: 0, title: "Presentation", completed: true },
  ];
  useEffect(() => {
    const savedTodoList = JSON.parse(localStorage.getItem("todoList"));
    if (savedTodoList) {
      setItems(savedTodoList);
    }
  }, []);
  const [items, setItems] = useState([]);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  function addTodo() {
    if (title.length === 0) {
      return;
    }

    setItems((arr) => {
      arr.push({
        id: Math.floor(Math.random() * 1000000),
        title: title,
        completed: false,
        priority: priority,
      });
      localStorage.setItem("todoList", JSON.stringify(arr));
      return arr;
    });
    settitle("");
  }
  function handleKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default Enter key behavior
      document.getElementById("submitButton").click(); // Trigger button click
    }
  }
  const [minimize, setminimize] = useState(false);
  const [priority, setpriority] = useState(0);
  const [title, settitle] = useState("");
  const [showConfetti, setShowConfetti] = useState(false);
  const triggerConfetti = () => {
    setShowConfetti(true);
    sound.play();
    setTimeout(() => {
      sound.stop();
    }, 300);
    setTimeout(() => {
      setShowConfetti(false);
    }, 3000);
  };
  const [sound] = useState(
    new Howl({
      src: ["./audios/fireworks.wav"],
      volume: 0.5,
    })
  );
  function handleDragEnd(event) {
    const { active, over } = event;
    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }
  function setchecked(todoid) {
    setItems((arritems) => {
      const newarr = arritems.map((item) => {
        if (item.id === todoid) {
          if (!item.completed) {
            triggerConfetti();
          }
          item.completed = !item.completed;
        }
        return item;
      });
      localStorage.setItem("todoList", JSON.stringify(newarr));
      return newarr;
    });
  }
  function deletetodo(todoid) {
    setItems((arritems) => {
      const newarr = arritems.filter((item) => item.id !== todoid);
      localStorage.setItem("todoList", JSON.stringify(newarr));
      return newarr;
    });
  }
  return (
    <Draggable
      nodeRef={nodeRef}
      handle=".header"
      bounds="parent"
      defaultPosition={{ x: 10, y: 10 }}
    >
      <div
        className={`absolute todo-container modern-font group/mini  ${
          minimize ? "h-[250px]" : ""
        }`}
        ref={nodeRef}
        style={{ visibility: listVisible ? "visible" : "hidden" }}
      >
        <div
          onClick={() => {
            setminimize((p) => !p);
          }}
          className=" absolute right-5 top-3 cursor-pointer  "
        >
          {minimize ? (
            <Maximize2
              className="opacity-0 group-hover/mini:!opacity-100"
              size={20}
            />
          ) : (
            <Minimize2
              className="opacity-0 group-hover/mini:!opacity-100"
              size={20}
            />
          )}
        </div>
        <div className="header  flex  justify-center items-center ">
          <div className="grabbar   mr-[40px] "></div>
        </div>
        <div
          className={` my-2 text-center text-2xl font-semibold text-[#867777] ${
            minimize ? "hide-toggle" : "open-toggle"
          }`}
        >
          Todo-List
        </div>
        <div className="flex justify-center items-center">
          <Confetti active={showConfetti} />
        </div>
        <div
          className={` flex justify-center items-center w-[310px] h-[3rem] bg-[#232020] text-white text-base rounded-xl my-2 mb-3 ${
            minimize ? "hide-toggle " : "open-toggle"
          }`}
        >
          <input
            onKeyDown={handleKeyDown}
            value={title}
            onChange={(e) => settitle(e.target.value)}
            type="text"
            className="font-semibold text-[#797070] rounded-xl   h-[2.7rem] outline-none p-3 bg-[#232020] "
          />
          <SelectPriority
            priority={priority}
            setpriority={setpriority}
            disabled={minimize}
          />
          <button
            id="submitButton"
            onClick={addTodo}
            className=" relative top-7 right-[35%] text-sm ml-2 py-2 px-[1.2rem] rounded-xl text-[#797070] border-2 border-[#504a4a] font-semibold  bg-[#1b1919]  shadow-xl hover:-translate-y-1 transition-all"
          >
            Add
          </button>
        </div>

        <div
          className={`w-[320px]   h-[384px] overflow-x-hidden  p-2  ${
            minimize && "h-full"
          }`}
        >
          <div className=" flex flex-col w-[300px]   overflow-x-hidden">
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={items}
                strategy={verticalListSortingStrategy}
              >
                {items.length !== 0 &&
                  items.map((item) => (
                    <SortableItem
                      key={item.id}
                      {...item}
                      setchecked={setchecked}
                      deletetodo={deletetodo}
                    />
                  ))}
              </SortableContext>
            </DndContext>
          </div>
          {/* <div className='h-[470px]  flex justify-center items-center'>
     <dir className=" text-3xl p-0 opacity-30 font-semibold">Coming Soon</dir> 
    </div> */}
        </div>
      </div>
    </Draggable>
  );
}

export default TodoList;
