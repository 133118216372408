import React from "react";
import TelegramIcon from "@mui/icons-material/Telegram";

export default function InputBar(props) {
  function handleChange(e) {
    props.setmessage(e.target.value);
  }
  function handleKeyPress(e) {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevent new line on Enter press
      props.sendMessage(e);
    }
  }
  
  return (
    <div className="inputbar ">
      <textarea
        type="text"
        placeholder="Type Something..."
        onChange={handleChange}
        value={props.message}
        required
        onInvalid={(e) => {
          e.target.setCustomValidity("Please enter a message.");
        }}
        onInput={(e) => e.target.setCustomValidity("")}
        onKeyDown={handleKeyPress}
      />
      <button type="submit">
        <TelegramIcon fontSize="large" />
      </button>
    </div>
  );
}
