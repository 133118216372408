import React from "react";
import { auth, db } from "./firebase";
import firebase from "firebase/compat/app";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import { collection, query, where, getDocs, updateDoc, doc } from "firebase/firestore";

export default function Message(props) {
  // console.log(props);
  const RoommessagesRef = collection(
    db,
    `roomchats/${props.roomname}/messages`
  );

  const q = query(
    RoommessagesRef,
    where("createdAt", "==", props.msg.createdAt)
  );

  function deletemsg() {
  // console.log("del msg")
    getDocs(q)
      .then((querySnapshot) => {
        // console.log(querySnapshot)
        querySnapshot.forEach((doc) => {
          // Update the message document
          updateDoc(doc.ref, {
            text: "*This message was deleted*",
            // timestamp: serverTimestamp(),
          })
            .then(() => {
              console.log("Message updated successfully");
            })
            .catch((error) => {
              console.error("Error updating message:", error);
            });
        });
      })
      .catch((error) => {
        console.error("Error getting messages:", error);
      });
  }
  function timestampMsg() {
    const curTime = firebase.firestore.Timestamp.now();
    if (!props.msg.createdAt) {
      return;
    }
    const timeDiff = curTime.toMillis() - props.msg.createdAt.toMillis();
    const mins = Math.floor(timeDiff / (1000 * 60));
    if (mins > 60 * 24) {
      return `${Math.floor(mins / (60 * 24))} days ago`;
    }
    if (mins > 60) {
      return `${Math.floor(mins / 60)} hrs ago`;
    }
    return `${mins} mins ago`;
  }
  // console.log(props.msg.text)
  const deletedmsg=(props.msg.text==="*This message was deleted*");
  const owner = props.msg.uid === auth.currentUser.uid;
  return (
    <div className={`message mt-4 ${owner == 1 ? "owner" : ""}`}>
      <div className="messageInfo">
        <img src={props.msg.photoURL} alt="" />
        {/* <span>{props.msg.createdAt}</span> */}
      </div>
      <div className="messageContent ">
        <p>
        {deletedmsg&&
        <div className="deletedmsg">
          This message was deleted
        </div>
        }
        {!deletedmsg&&<div>{props.msg.text}
        <div className="msg-bottom-cont">
            <div className="timestamp ">{timestampMsg()}</div>
            <button
              className="ownerdel"
              style={{ display: owner ? "" : "none" }}
              onClick={() => {
                deletemsg();
              }}
            >
              <DeleteOutlineRoundedIcon fontSize="small" />
            </button>
          </div>
          </div>}
        </p>
      </div>
    </div>
  );
}
