import React from 'react'
import Message from './Message'
import { CleaningServices } from '@mui/icons-material'

export default function Messages(props) {
    // console.log(props);
  return (
    <div className='messages '>
        {props.allmessages&&props.allmessages.map((msg)=>{
          
          return (            
          <Message key={msg.createdAt}
                msg={msg}
                roomname={props.roomname}
            />
        )})}
        <span ref={props.dummy}></span>
    </div>
  )
}
