import React from "react";
import Navbar from "./Navbar";
import Card from "./Card";
// import "./index.css"
import { WavyBackground } from "./ui/WavyBackground";
import {
  doc,
  setDoc,
  updateDoc,
  collection,
  getDoc,
  onSnapshot,
} from "firebase/firestore";
import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { db, auth } from "./firebase";
import { useState } from "react";
import Widgetsidebar from "./Widgetsidebar";
import InvitePopup from "./ui/invite-popup";
import { Button } from "./ui/button";
import { useRecoilState, useSetRecoilState } from "recoil";
import Completion from "./spaces/run/completion";
import { completionModalState } from "../recoilState/completionModalState";
import InviteAnimatePopup from "./ui/invite-animate-popup";
// import Draggable from "react-draggable";
// import { useRef } from "react";

function App() {
  const [customlink, setcustomlink] = useState("https://i.ibb.co/k8t6STs/owall-7.jpg");
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [timerrunning, setTimerrunning] = useState(false);
  const [user] = useAuthState(auth);
  const [completionModal, setCompletionModal] =
    useRecoilState(completionModalState);
  const handleFullscreenToggle = () => {
    if (!document.fullscreenElement) {
      // If currently not in fullscreen, enter fullscreen
      document.documentElement.requestFullscreen().then(() => {
        setIsFullscreen(true);
      });
    } else {
      // If currently in fullscreen, exit fullscreen
      document.exitFullscreen().then(() => {
        setIsFullscreen(false);
      });
    }
  };
  // const setIsOpen = useSetRecoilState(modalState);
  useEffect(() => {
    const prevWallpaper=localStorage.getItem("wallpaper");
    if(prevWallpaper&&!customlink&&customlink.length===0){
      setcustomlink(prevWallpaper);
    }
    else if(customlink&&prevWallpaper!==customlink){
      localStorage.setItem("wallpaper",customlink);
    }
  }, [customlink]);
  return (
    <div
      className="home "
      style={{
        backgroundImage:
          (customlink&&customlink.length !== 0)
            ? `url(${customlink})`
            : "url('./images/homeimge4.webp')",
      }}
    >
      <Completion
        time={completionModal.duration}
        isOpen={completionModal.open}
        setIsOpen={(value) => {
          setCompletionModal((p) => ({ ...p, open: value }));
        }}
      />
      <Navbar timerrunning={timerrunning} />
      <InvitePopup user={user} />
      <InviteAnimatePopup user={user}/>
      {/* <Button onClick={()=>{setIsOpen(true)}}>MODAL</Button> */}
      <Card
        setcustomlink={setcustomlink}
        setTimerrunning={setTimerrunning}
        handleFullscreenToggle={handleFullscreenToggle}
        isFullscreen={isFullscreen}
      />
      <a href="https://www.buymeacoffee.com/divyamauto5" target="_blank">
        <img
          className="buycoffee"
          src="./images/bmc-logo-yellow.png"
          alt="bmc"
        />
      </a>
    </div>
  );
}

export default App;
