import React, { useEffect, useState } from "react";
import {
  Chart,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
} from "chart.js/auto";
import { Line } from "react-chartjs-2";
import NumberTicker from "./ui/number-ticker";

Chart.register(LineElement, CategoryScale, LinearScale, PointElement);

const LineChart = (props) => {
  // console.log("rece);
  // console.log(props.monthdata);
  // if(!props){return ;}
  // let moreThanTen=0;

  const data = {
    labels: props.monthdata["monthdates"],
    datasets: [
      {
        label: "Focus Minutes",
        data: props.monthdata["monthcounts"],
        fill: false,
        borderColor: "rgb(75, 192, 192)",
        tension: 0.1,
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
    },
    scales: {
      x: {
        type: "category",
        min:
          props.monthdata["monthdates"].length > 10
            ? props.monthdata["monthdates"][
                props.monthdata["monthdates"].length - 10
              ]
            : props.monthdata["monthdates"][0], // Specify the first label you want to display
        max: props.monthdata["monthdates"][
          props.monthdata["monthdates"].length - 1
        ], // Specify the last label you want to display
        ticks: {
          stepSize: 1, // Set the step size to 1 to ensure that every label is displayed
        },
        display: false,
      },
      y: {
        display: false,
      },
    },
  };
  //filter and only display the last 10 sessions
  // useEffect(() => {
  //   if(props&&props.monthdata&&props.monthdata.length>10){
  //   setoptions({
  //     scales: {
  //       x: {
  //         type: 'category',
  //         min: props.monthdata["monthdates"][props.monthdata["monthdates"].length - 10], // Specify the first label you want to display
  //         max: props.monthdata["monthdates"][props.monthdata["monthdates"].length - 1], // Specify the last label you want to display
  //         ticks: {
  //           stepSize: 1 // Set the step size to 1 to ensure that every label is displayed
  //         },
  //         display:false
  //       }
  //     }
  //   });
  // }
  // }, []);

  return (
    <div className="focuschart h-[300px]">
    <div className="mb-3  flex items-center justify-center">
      <div className="text-center text-xl" style={{ fontWeight: "bold" }}>
        Focus Chart 
      </div>
      <div className="relative left-4 ">
          <svg
            className="fontdull"
            width="22px"
            height="22px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 3L2 6M22 6L19 3M6 19L4 21M18 19L20 21M12 16V10M9 13H15M12 21C14.1217 21 16.1566 20.1571 17.6569 18.6569C19.1571 17.1566 20 15.1217 20 13C20 10.8783 19.1571 8.84344 17.6569 7.34315C16.1566 5.84285 14.1217 5 12 5C9.87827 5 7.84344 5.84285 6.34315 7.34315C4.84285 8.84344 4 10.8783 4 13C4 15.1217 4.84285 17.1566 6.34315 18.6569C7.84344 20.1571 9.87827 21 12 21Z"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        </div>
      <div
      className="justify-between"
        style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}
      >
        <div className="mr-3 text-sm ">Total Focus Time:</div>{" "}
        <div className="flex items-center justify-center gap-1">
        <div className="text-2xl font-bold">
          <NumberTicker value={Math.floor(props.totalfocusminutes / 60)} />
        </div>
        <div style={{ fontSize: "0.8em", margin: "4px" }}>hours</div>{" "}
        </div>
        <div className="flex items-center justify-center gap-1">
        <div className="text-2xl font-bold">
          <NumberTicker value={props.totalfocusminutes % 60} />
          {}{" "}
        </div>
        <div style={{ fontSize: "0.8em", margin: "4px" }}>mins</div>
        </div>
      </div>
      <Line data={data} options={options} />
    </div>
  );
};

export default LineChart;
