import React, { useState } from "react";
import { Button } from "../ui/button";
import { Input } from "../ui/input";
import { useRecoilState } from "recoil";
import { spaceState } from "../../recoilState/spaceState";
import { checkSpaceExists } from "../../actions/check-space-exists";
import { useToast } from "../ui/use-toast";
import { joinSpace } from "../../actions/join-space";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase";

function JoinSpace({ setchoice }) {
  const [user] = useAuthState(auth);

  const { toast } = useToast();
  const [spaceId, setSpaceId] = useState("");
  const [space, setSpace] = useRecoilState(spaceState);
  return (
    <div>
      <div className="text-[#928080] font-semibold text-center text-lg mt-3">
        Enter Space Id :
      </div>
      <div className="dark flex flex-col justify-center items-center p-5 !pt-3 !pb-3">
        <Input
          onChange={(e) => {
            setSpaceId(e.target.value);
          }}
          value={spaceId}
          className="text-sm"
          type="text"
          placeholder="Space ID"
        />
      </div>
      <div className="flex items-center justify-between px-5 my-3">
        <Button
          onClick={() => {
            setchoice(0);
          }}
          className=" text-[#928080] font-bold rounded-3xl neumorphism bg-[#262424]"
        >
          Back
        </Button>
        <Button
          onClick={async () => {
            const newSpace = await checkSpaceExists(spaceId);
            if (newSpace) {
              setSpace({...newSpace,isAdmin:false});
              
              const userData = {
                name: user.displayName,
                email: user.email,
                imageurl: user.photoURL,
                id: user.uid,
              };
              await joinSpace(newSpace.id, userData);
              toast({
                title: "Joined Space",
                description: "Joined the Space",
              });
            } else {
              // alert("space not found");
              toast({
                title: "Space Not Found",
                description: "Space not found please recheck your Space Id",
                variant: "destructive",
              });
            }
            setSpaceId("");
          }}
          className="text-[#928080] font-bold rounded-3xl neumorphism bg-[#262424]"
        >
          Join
        </Button>
      </div>
    </div>
  );
}

export default JoinSpace;
