import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "./alert-dialog";

import React, { useState } from "react";
import { Button } from "./button";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  modalAnimateNotificationState,
  modalAnimateState,
} from "../../recoilState/modalState";
import { deleteNotification } from "../../actions/delete-notification";
import { useToast } from "./use-toast";
import { useNavigate } from "react-router-dom";


const InviteAnimatePopup = ({ user }) => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const notification = useRecoilValue(
    modalAnimateNotificationState
  );
  const [isOpen, setIsOpen] = useRecoilState(modalAnimateState);
  // const spaceId = space.spaceId;
  if (!notification) {
    return null;
  }

  async function acceptInvite() {
    //write redirect logic to animate with callId
    await deleteNotification(user);
    const userData = {
      name: user.displayName,
      email: user.email,
      imageurl: user.photoURL,
      id: user.uid,
    };
    toast({
      title: "Accepted Invite",
      description: "Invite Accepted. Starting AniMate Session.",
    });
    navigate(`/animate?admin=false&&callId=${notification.callId}`)
  }

  return (
    <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
      <AlertDialogContent className="dark  !border-[#363131]">
        <AlertDialogHeader>
          <AlertDialogTitle className="text-3xl">
            AniMate Invitation
          </AlertDialogTitle>
          <AlertDialogDescription className="!text-base not-italic ">
            <span className="font-bold">{notification.createdBy}</span> invited
            you for watching Anime Together.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel
            onClick={async () => {
              await deleteNotification(user);
              toast({
                title: "Declined Invite",
                description: "Declined the AniMate Invitation",
                variant: "destructive",
              });
            }}
            className="!border-[#8c888870] bg-black hover:!bg-red-500 hover:!border-none !px-4 "
          >
            Ignore
          </AlertDialogCancel>
          <AlertDialogAction
            onClick={async () => {
              await acceptInvite();
            }}
            className="!border-[#8c888870] bg-black hover:!bg-green-500 hover:!border-none !px-4"
          >
            Join
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default InviteAnimatePopup;
