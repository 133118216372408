import { CleaningServices, Padding } from "@mui/icons-material";
import React from "react";
import { Link } from "react-router-dom";
import Stats from "../pages/Stats";
import { useState,useEffect } from "react";
import Diversity1OutlinedIcon from '@mui/icons-material/Diversity1Outlined';
const Profiles = (props) => {
  let onlyFriends=props.onlyFriends;
  const [userfriends, setuserfriends] = useState([]);
  const fetchUserData = async () => {
    // console.log("getting data---");
    const oldDate = new Date();
    var curdate = oldDate.toDateString();
    let userEmail = localStorage.getItem("userEmail")||"example@gmail.com";

    let response = await fetch(
      "https://animedoro-backend.vercel.app/api/getstats",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: userEmail,
          curDate: curdate,
        }),
      }
    );
    const json = await response.json();
    console.log("profread")
    if (response.status === 200) {
      let newarr=json.userFriends;
      newarr.push(userEmail);
      setuserfriends(json.userFriends);
      // console.log(newarr);
    }
  };
  console.log("in profiles");
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredboarddata, setfilteredboarddata] = useState([]);
  function onSearch(query) {
    setSearchQuery(query);
    let tempfbd=[];
    for(var i=0;i<props.boarddata.length;i++){
      let lowerword=(props.boarddata[i].username.toLowerCase());
      if(lowerword.includes(query.toLowerCase())){
        // console.log(onlyFriends);
        // console.log(userfriends);
        // console.log(props.boarddata[i].useremail);
        // console.log(userfriends.includes(props.boarddata[i].useremail));
        if(onlyFriends===1){
          if(userfriends.includes(props.boarddata[i].useremail)===true){tempfbd.push(props.boarddata[i]);}
        }
        else{
        tempfbd.push(props.boarddata[i]);
        }
      }
    }
    setfilteredboarddata(tempfbd);
    // console.log(filteredboarddata);
  }
  useEffect(() => {
    fetchUserData();
  }, []);
  // if(onlyFriends==1&&filteredboarddata.length){onSearch("a");onSearch("");}

  return (
    <div style={{paddingTop:"15px"}}>
    
      <input
      placeholder= "  Search"
      type="text"
        style={{  borderRadius:"25px",padding:"10px",marginTop:"20px"}}
        onChange={(e) => onSearch(e.target.value)}
      />
      
      
      <div id="profile">
      {Item(searchQuery==""?props.boarddata:filteredboarddata, props.mode,userfriends,onlyFriends)}
      
      </div>
    </div>
  );
};

export default React.memo(Profiles);

function Item(data, mode,userfriends,onlyFriends) {
  // console.log(data);
  if(onlyFriends===1){
    let newdata=[];
    for(var i=0;i<data.length;i++)
    {
      if(userfriends.includes(data[i].useremail)){
        newdata.push(data[i]);
      }
    }
    data=newdata;
  }
  async function sendreq(friendmail){
      let useremail = localStorage.getItem("userEmail");
      let response = await fetch(
        "https://animedoro-backend.vercel.app/api/friendrequest",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            useremail: useremail,
            friendmail: friendmail,
          }),
        }
      );
      const json = await response.json();
      if(json.msg){
        alert(json.msg);
      }
      else{
        alert("Request Sent");
      }
    };
    
  
  // console.log("in item");
  // console.log(data);
  return (
    <>
      {data.map((value, index) => (
        <div onClick={() => {
            console.log("clicked");
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            // window.location.reload();
          }}
          key={value.useremail}
          >
        <Link
          className="nav-link nav-item "
          to="/stats"
          state={{ email: value.useremail }}
          
        >
          <div className="flex" key={index}>
            <div className="item">
              {/* {console.log(value.username)} */}
              <div style={{ padding: "10px" }}>{index + 1}</div>
              <button
                className="btn btn-outline-secondary nav-butt "
                style={{ marginBottom: "0px" }}
              >
                <strong>{value.username[0].toUpperCase()}</strong>
              </button>
              
              <div className="info">
                <h3 className="name text-dark">
                  {value.username.charAt(0).toUpperCase()+value.username.slice(1).toLowerCase()}
                </h3>
                {/* <span>Location</span> */}
              </div>
            </div>
            <div className="item " >
            <div className="d-flex flex-column mr-4 align-items-center">
              <span >
                {mode === 1 ? value.totalminutes : value.todayminutes} 
              </span>
              <span>Minutes </span>
              </div>
              {localStorage.getItem("userEmail")&&<div>
              {userfriends.includes(value.useremail)?<Diversity1OutlinedIcon/>:<button
                type="submit"
                className="btn btn-outline-secondary nav-butt btn-sm"
                onClick={() => { sendreq(value.useremail);}}
                style={{ fontSize: "12px", marginLeft:"1px",}}
              ><strong>+</strong>
              </button>}
              {/* <button></button> */}
              </div>}
            </div>
          </div>
          
        </Link>
        
              </div>
      ))}
    </>
  );
}
