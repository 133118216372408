import React,{useState, useEffect} from "react";
import Navbar from "../components/Navbar";
import { Typewriter } from "react-simple-typewriter";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import firebase from "firebase/compat/app";
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

const Login = () => {
  let navigate=useNavigate();
  const [credentials, setcredentials] = useState({
    name: "",
    email: "",
  });
  const [submitload, setsubmitload] = useState(false);

  const handleEmailPasswordSignIn = (event) => {
    event.preventDefault();setsubmitload(true);
    firebase.auth().signInWithEmailAndPassword(credentials.email, credentials.password)
      .then((userCredential) => {
        const user = userCredential.user;
        localStorage.setItem("userEmail",user.email);
        localStorage.setItem("uid",user.uid);
        user.getIdToken().then(token => {
          localStorage.setItem('authToken', token);
          handleCallback(user.displayName,user.email);
        });
    localStorage.setItem("userName",user.displayName);
      })
      .catch((error) => {
        alert("Error signing in with email and password");
        console.error('Error signing in with email and password:', error);
      });
      setsubmitload(false);
      
  };

  const handleGoogleSignIn = () => {
    console.log("Inside Google");
    setsubmitload(true);
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithPopup(provider)
      .then((result) => {
        // Signed in with Google
        const user = result.user;
        localStorage.setItem("userEmail",user.email);
        localStorage.setItem("uid",user.uid);
        user.getIdToken().then(token => {
          localStorage.setItem('authToken', token);
          navigate("/");
        });
    localStorage.setItem("userName",user.displayName);
    handleCallback(user.displayName,user.email);
      })
      .catch((error) => {
        alert("Error Signing in with Google");
        console.error('Error signing in with Google:', error);
      });
      setsubmitload(false);
      
  };

  
//////////////////////////up firebase
//1)
const handleCallback=async (name,email) =>{
  setsubmitload(true);
  const response1 = await fetch("https://animedoro-backend.vercel.app/api/googleentry", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({name:name,email:email})
    });
    const json =await response1.json()
    console.log(email);
    if(!json.success){
      alert("Sign in Failed");
    }
    else{
    navigate("/");}
    setsubmitload(false);
}
////////////
  
  // useEffect(() => {
  //   /*global google*/
  //   google.accounts.id.initialize({
  //    client_id:"718156679991-vlktjgr7mkbpgqpu8iml8mnvjhguj2gt.apps.googleusercontent.com" ,
  //    callback: handleCallback
  //   });
  //   google.accounts.id.renderButton(
  //     document.getElementById("signInDiv"),
  //     {theme:"outline",size:"large",theme:"dark"}
  //   );
  // }, []);
  // const handleSubmit = async (e) => {
  //   setsubmitload(true);
  //   e.preventDefault();
  //   // console.log("in");
  //   const response = await fetch("https://animedoro-backend-production.up.railway.app/api/loginuser", {
  //     method: 'POST',
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({email:credentials.email,password:credentials.password})
  //   });
  //   const json =await response.json()
  //   console.log(json)
  //   if(!json.success){
  //     alert("Enter valid credentials!");
  //   }
  //   else{
  //   localStorage.setItem("userEmail",credentials.email);
  //   localStorage.setItem("authToken",json.authToken);
  //   localStorage.setItem("userName",json.name);
  //   navigate("/");
  //   //every time you try to login a different auth token will be generated
  // }
  // setsubmitload(false);
  // };

  const onChange=(event)=>{
    setcredentials({...credentials,[event.target.name]:event.target.value})
  }

  return (
    <div className="login">
      <Navbar />
      <div className="container-fluid col-11 col-sm-6 col-md-10">
        
        <div className="row">
          <div className="card infocard">
            <div
              style={{
                paddingBottom: "0px",
                marginLeft: "8px",
                marginRight: "0px",
              }}
              className="container h-100 card-body"
            >
              <h1 className="text-center headingtitle mt-3">Animedoro </h1>
              
              <p  className="text-center quote">
                <Typewriter
                  loop
                  cursor
                  cursorStyle="|"
                  typeSpeed={60}
                  deleteSpeed={80}
                  delaySpeed={1000}
                  words={["Focus", "Watch Anime", "Repeat!"]}
                  // onLoop={(loopCount) =>
                  //   console.log(`Just completed loop ${loopCount}`)
                  // }
                />
              </p>
              <form style={{margin:"0px"}}>
              <div className="form-group" style={{marginLeft:"35px"}}>
                <label for="email">Email : </label>
                <br />
                <input
                //   style={{backgroundColor:"grey"}}
                  type="email"
                  className="form-control-sm"
                  id="email"
                  aria-describedby="emailHelp"
                  name="email"
                  value={credentials.email}
                  onChange={onChange}
                />
              </div>
              <div className="form-group" style={{marginLeft:"35px"}}>
                <label for="password">Password :</label>
                <br />
                <input
                  type="password"
                  className="form-control-sm"
                  id="password"
                  name="password"
                  value={credentials.password}
                  onChange={onChange}
                />
              </div>
              <button type="submit" className="btn btn-outline-secondary btn-timer" disabled={submitload}
              style={{marginLeft:"123px",marginBottom:"20px"}}
              onClick={handleEmailPasswordSignIn}
            >
              {submitload&&<span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>}

           Login
              </button>
              {/* <button type="submit" className="btn btn-outline-secondary btn-timer"
              style={{marginBottom:"20px"}}
            >
                Sign in
              </button> */}
              </form>
              

              <div className="col-md-0" 
              style={{display:"flex",alignContent:"center",justifyContent:"center"}}>
                <br />
                {/* <div id="signInDiv"></div> */}
                <a
                  className="btn btn-outline-secondary btn-timer"
                  // href="/users/googleauth"
                  role="button"
                  style={{
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                  textTransform: "none",
                  width:"300px",
                  textTransform: "none"
                }}
                  // style={{ }}
                  onClick={handleGoogleSignIn}
                >
                  <img
                    width="20px"
                    style={{ marginBottom: "3px", marginRight: "5px" }}
                    alt="Google sign-in"
                    src="https://user-images.githubusercontent.com/700503/58114683-e12ffd00-7c2a-11e9-8d40-d7dc14342950.jpg"
                    
                  />
                  Login with Google
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
