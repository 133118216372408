import React, { useState } from "react";
import firebase from "firebase/compat/app";

import {auth,db} from "./firebase";
import { setDoc ,doc} from "firebase/firestore";
import { useNavigate } from "react-router-dom";


function CreateRoom(props) {
  // function randcol() {
  //   const aestcolors = ["#DFFFD8", "#F7C8E0", "#95BDFF", "#D9ACF5", "#D14D72", "#A6D0DD", "#FEFF86","black"];
  //   return aestcolors[Math.floor(Math.random() * aestcolors.length)];

  // }
  const navigate=useNavigate();
  function randpatt() {
      const transparentbacks=["food.png","foggy-birds.png","gray-floral.png","washi.png"];
      const randindex=Math.floor(Math.random() * transparentbacks.length);
      const rpatt=transparentbacks[randindex];
      return `url("https://www.transparenttextures.com/patterns/${rpatt}")`
  }
  const [credentials, setcredentials] = useState({
    groupname: "",
    tag1: "",
    tag2: "",
    target_time: "",
    description: "",
    image_link:""
  });
  const onChange = (event) => {
    setcredentials({ ...credentials, [event.target.name]: event.target.value });
  };
  async function handleCreation(e) {
    e.preventDefault();
    // console.log(auth.currentUser);
  // console.log(credentials);
  await setDoc(doc(db, "Rooms", credentials.groupname), {
    roomname: credentials.groupname,
    tag1: credentials.tag1,
    tag2: credentials.tag2,
    target_time:credentials.target_time,
    description:credentials.description,
    image_link:credentials.image_link,
    rand_pattern_url:randpatt(),
    admin_email:auth.currentUser.email,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    members:[
      {
      name:auth.currentUser.displayName,
      email:auth.currentUser.email,
      uid:auth.currentUser.uid,
      imageurl:auth.currentUser.photoURL
    }],
    requests:[]

  });
await setDoc(doc(db,"roomchats",credentials.groupname),{messages:[]});
// navigate("/rooms");
props.setclicked(-1);
// console.log("mustbeadded");
  }
  const [submitload, setsubmitload] = useState(0);
  return (
    <div className="detailswrapper ">
    <div className="detailscard "
    style={{minWidth:"350px"}}
    >
      <form  className="createroomform" onSubmit={(e)=>{handleCreation(e)}}>
        <div className="detailsheader " >
          <p className="detailstitle">Create Room</p>
          <div className="seperaterline" style={{width:"200px"}}></div>
          <div className="form-group mr-2 mt-2" >
            <label htmlFor="name">Room Name : </label>
            <br />
            <input
              
              autoComplete="off"
              onChange={onChange}
              name="groupname"
              value={credentials.groupname}
              type="text"
              className="form-control-sm"
              id="name"
              required
            />
          </div>
          <div className="form-group " >
            <label>Tags : </label>
            <br />
            <div className=" ">
              <input
              placeholder="  Cosmology"
              style={{marginRight:"10px"}}
                autoComplete="off"
                name="tag1"
                onChange={onChange}
                value={credentials.tag1}
                className="form-control-sm  taginput"
                required
              />
              <input
              placeholder="       JEE"
                autoComplete="off"
                name="tag2"
                onChange={onChange}
                value={credentials.tag2}
                className="form-control-sm  taginput"
                required
              />
            </div>
            <div className="mt-3 mb-3">
              <label>Weekly Hours : </label>
              <input
              placeholder="e.g. 20"
                autoComplete="off"
                name="target_time"
                onChange={onChange}
                value={credentials.target_time}
                className="form-control-sm hoursinput "
                pattern="[0-9]+" 
                onInvalid={(e) => {
    if (e.target.value === "") {
      e.target.setCustomValidity("Please enter a value");
    } else {
      e.target.setCustomValidity("Please enter only digits");
    }
  }}
  onInput={(e) => e.target.setCustomValidity("")}
                required
              />
            </div>
            <div className="mt-3 mb-3" >
              <label>Image link : </label>
              <input
              placeholder="      optional"
                autoComplete="off"
                name="image_link"
                onChange={onChange}
                value={credentials.image_link}
                className="form-control-sm hoursinput"
                style={{width:"128px",marginLeft:"10px"}}
              />
            </div>
            <div className="form-group">
              <label>Description / Rules:</label>
              <br />
              <textarea
              // placeholder=""
                onChange={onChange}
                autoComplete="off"
                name="description"
                rows={5}
                cols={30}
                value={credentials.description}
                className="descinput"
                required
              />
            </div>
          </div>
        </div>
        <div className="detailscardfooter "
        style={{marginTop:"0px"}}>
          <button
            className="footerbtn btn btn-outline-secondary mr-2 createbtn mt-0"
            disabled={submitload}
            type="submit"
          >
            Create
          </button>
          <button
            onClick={() => props.setclicked(-1)}
            className="footerbtn btn btn-outline-secondary mt-0 ml-4 cancelbtn "
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
    </div>
  );
}

export default CreateRoom;
