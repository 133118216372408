import React, { useEffect, useState } from "react";
import { Button } from "../../ui/button";
import Timer from "./timer";
import ProfileScroll from "../../ProfileScroll";
import { auth, db } from "../../firebase";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs";
import { AddMember } from "./add-members";

import { useCollectionData } from "react-firebase-hooks/firestore";
import {
  collection,
  query,
} from "firebase/firestore";
import { fetchUserData } from "../../../actions/get-user-data";
import { sendNotification } from "../../../actions/send-notification";
import { createSpace } from "../../../actions/create-space";
import { joinSpace } from "../../../actions/join-space";
import { useRecoilState } from "recoil";
import { spaceState } from "../../../recoilState/spaceState";
import { addUserSession } from "../../../actions/add-user-session";
import { useToast } from "../../ui/use-toast";

function CreateSpace({ setchoice }) {
  const { toast } = useToast();
  const usersRef = collection(db, "newusers");
  const [timerFocusMinutes, settimerFocusMinutes] = useState(40);
  const allUsersQuery = query(usersRef);
  const [invitedMembers, setInvitedMembers] = useState([
    {
      name: auth.currentUser.displayName,
      email: auth.currentUser.email,
      imageurl: auth.currentUser.photoURL,
      id: auth.currentUser.uid,
    },
  ]);
  // console.log(auth.currentUser.id,auth.currentUser);
  const [space, setSpace] = useRecoilState(spaceState);

  const [allUsers] = useCollectionData(allUsersQuery, { idField: "id" });
  const [friendEmails, setFriendEmails] = useState([]);
  async function getFriends() {
    const friendList = await fetchUserData();
    setFriendEmails(friendList?.userFriends);
    // console.log("friends",friendList);
    // console.log("allusers",allUsers);
  }
  useEffect(() => {
    getFriends();
  }, []);
  // console.log(invitedMembers);
  async function handleInvite() {
    if(invitedMembers&&invitedMembers.length===1){
      toast({
        title: "No Members Added",
        description: "You have not added any friends to your space.",
        // variant:"destructive"
      });
      return;
    }
    const adminData={
      name: auth.currentUser.displayName,
      email: auth.currentUser.email,
      imageurl: auth.currentUser.photoURL,
      id: auth.currentUser.uid,
    };
    const spaceData = {
      createdByEmail:auth.currentUser.email,
      createdBy: auth.currentUser.displayName,
      timer: {
        duration: timerFocusMinutes,
        isRunning: false,
        // endTime: ,
      },
      participants: invitedMembers,
      joined: [adminData],
    };

    const space = await createSpace(spaceData);

    setSpace({...space,isAdmin:true});
    invitedMembers.forEach((friend) => {
      if (friend.email === auth.currentUser.email) {
        return;
      }
      sendNotification(friend,space);
    });
    if(space){
      toast({
        title: "Invitations Sent",
        description: "Invitations sent to your friends.",
        variant:"success"
      });
    }
  }
  
  return (
    <div>
      <div>
        <Tabs defaultValue="timer" className="w-full mt-3  dark ">
          <div className="flex justify-center items-center">
            <TabsList className="text-[#928080] neumorphism !rounded-md ">
              <TabsTrigger value="timer">Timer</TabsTrigger>
              <TabsTrigger
                value="members"
                className="!data-[state=active]:bg-blue"
              >
                Members
              </TabsTrigger>
            </TabsList>
          </div>
          <TabsContent value="members">
            <div className="text-[#928080] font-semibold text-center text-lg mt-3">
              Add Members
            </div>
            <div className="flex flex-col justify-center items-center pt-2">
              {allUsers && friendEmails && (
                <AddMember
                  invitedMembers={invitedMembers}
                  setInvitedMembers={setInvitedMembers}
                  allUsers={allUsers}
                  friendEmails={friendEmails}
                />
              )}
              <ProfileScroll memberDisplay={true} members={invitedMembers} />
            </div>
          </TabsContent>
          <TabsContent value="timer">
            <div className="text-[#928080] font-semibold text-center text-lg mt-3">
              Set Time
            </div>

            <Timer
              timerFocusMinutes={timerFocusMinutes}
              settimerFocusMinutes={settimerFocusMinutes}
            />
          </TabsContent>
        </Tabs>

        {/* <Separator className="mx-auto my-3 h-[2px] rounded-xl w-[70%] bg-[#928080a0]" /> */}
      </div>

      <div className="flex items-center justify-between px-5 my-3">
        <Button
          onClick={() => {
            setchoice(0);
          }}
          className=" text-[#928080] font-bold rounded-3xl neumorphism bg-[#262424]"
        >
          Back
        </Button>
        <Button
          onClick={() => {
            handleInvite();
            
          }}
          className="text-[#928080] font-bold rounded-3xl neumorphism bg-[#262424]"
        >
          Invite
        </Button>
      </div>
    </div>
  );
}

export default CreateSpace;
