import React, { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";

import { Separator } from "../ui/separator";
// import { auth } from "../firebase";
import Landing from "./landing";
import CreateSpace from "./create/create-space";
import JoinSpace from "./join-space";
import { spaceState } from "../../recoilState/spaceState";
import { useRecoilState } from "recoil";
import WaitingRoom from "./waiting-room";

function Spaces({ studyTogether }) {

  const nodeRef = useRef(null);
  const [choice, setchoice] = useState(0); //0 means landing, 1 means create and 2 means join
  const [space, setSpace] = useRecoilState(spaceState);
  
  return (
    <Draggable
      nodeRef={nodeRef}
      handle=".header"
      bounds="parent"
      defaultPosition={{ x: 10, y: 10 }}
    >
      <div
        className={`absolute space-container p-2 modern-font   `}
        ref={nodeRef}
        style={{ visibility: studyTogether ? "visible" : "hidden" }}
      >
        <div className="header  w-full  flex  justify-center items-center ">
          <div className="grabbar   "></div>
        </div>
        <div
          className={` my-2 text-center text-3xl font-semibold text-[#867777]`}
        >
          Spaces
        </div>

        <Separator className="m-auto h-[2px] rounded-xl w-[70%] bg-[#928080]" />
        {!space&&!localStorage.getItem("userEmail") && (
          <div className="h-[300px] w-full flex items-center justify-center">
            <div className="mb-4 text-xl"> SignIn/Login for Spaces</div>
          </div>
        )}
        {!space&&localStorage.getItem("userEmail") && choice === 0 && (
          <Landing setchoice={setchoice} />
        )}
        {!space&&localStorage.getItem("userEmail") && choice === 1 && (
          <CreateSpace setchoice={setchoice} />
        )}
        {!space&&localStorage.getItem("userEmail") && choice === 2 && (
          <JoinSpace setchoice={setchoice} />
        )}
        {space&&<WaitingRoom/>}
      </div>
    </Draggable>
  );
}

export default Spaces;
