import React, { useEffect, useRef } from "react";
import { Button } from "../components/ui/button";
import {
  adminInitialize,
  AdminPermissionsStream,
  answerButtonFucntion,
  answerButtonFunction,
  callButtonFunction,
  cameraStream,
  friendInitialize,
  hangUp,
  Initialize,
  localStream,
  NewAdminPermissionsStream,
  NewParticipantPermissionsStream,
  ParticipantPermissionsStream,
  remoteStream,
  screenStream,
  webcamButtonFunction,
} from "../components/webrtc/connections";
import { useState } from "react";
import {
  Eye,
  EyeOff,
  Maximize2,
  Mic,
  MicOff,
  Minimize2,
  Phone,
  Video,
  VideoOff,
} from "lucide-react";
import Draggable from "react-draggable";
import { animateFriendState } from "../recoilState/animateFriendState";
import { useRecoilState, useRecoilValue } from "recoil";
import { sendAnimateNotification } from "../actions/send-animate-notification";
import { useNavigate } from "react-router-dom";
const AniMate = () => {
  const navigate=useNavigate();
  // const [callId, setcallId] = useState("");
  const [admin, setAdmin] = useState(0);
  const [visibilitySelf, setvisibilitySelf] = useState(1);
  const [visibilityFriend, setvisibilityFriend] = useState(1);
  const [connectionOn, setconnectionOn] = useState(0);
  const [micOn, setmicOn] = useState(1);
  const webcamVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);
  const shareVideoRef = useRef(null);
  const [isButtonsVisible, setIsButtonsVisible] = useState(true);
  const [videoSharing, setvideoSharing] = useState(1);
  const [friend,setFriend]=useRecoilState(animateFriendState);
  const [fullScreen, setfullScreen] = useState(0);
  let timeout;
  const toggleVideo = () => {
    if (cameraStream) {
      const videoTrack = cameraStream.getVideoTracks()[0];
      // console.log(videoTrack);
      if (videoTrack) {
        videoTrack.enabled = !videoTrack.enabled;
        // console.log(videoTrack.enabled);
        setvideoSharing(videoTrack.enabled);
        // toggleVideoButton.textContent = videoTrack.enabled ? "Turn Video Off" : "Turn Video On";
      }
    }
  };
  const toggleMicScreenShare = () => {
    if (screenStream) {
      const audioTrack = screenStream.getAudioTracks()[0];
      if (audioTrack) {
        audioTrack.enabled = !audioTrack.enabled;
        // toggleMicButton.textContent = audioTrack.enabled ? "Turn Mic Off" : "Turn Mic On";
      }
    }
  };
  const toggleMicVideo = () => {
    if (cameraStream) {
      const audioTrack = cameraStream.getAudioTracks()[0];
      if (audioTrack) {
        audioTrack.enabled = !audioTrack.enabled;
        setmicOn(audioTrack.enabled);
        // toggleMicButton.textContent = audioTrack.enabled ? "Turn Mic Off" : "Turn Mic On";
      }
    }
  };
  const handleFullscreenToggle = (fullscreen) => {
    if (fullscreen) {
      document.documentElement.requestFullscreen().then(() => {});
    } else {
      // If currently in fullscreen, exit fullscreen
      document.exitFullscreen().then(() => {});
    }
  };
  const timeoutButtonsClassName=`${isButtonsVisible ? 'opacity-100 mb-10' : 'opacity-0'} transition-all duration-300 `;
  const urlParams = new URLSearchParams(window.location.search);
  async function handleHangUp() {
    await hangUp();
    navigate("/");
  }
  const handleActivity = () => {
    setIsButtonsVisible(true);
    clearTimeout(timeout);
    timeout = setTimeout(() => setIsButtonsVisible(false), 3000);
  };

  useEffect(() => {
    // Set initial timeout
    timeout = setTimeout(() => setIsButtonsVisible(false), 3000);

    // Add event listeners for user activity
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);

    // Cleanup function
    return () => {
      clearTimeout(timeout);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
    };
  }, []);
  useEffect(() => {
    async function Initialize() {
      const urlParams = new URLSearchParams(window.location.search);

      if (urlParams.size === 0) {
        return;
      }
      const isAdmin = urlParams.get("admin") === "true";
      setAdmin(isAdmin);
      
      if (isAdmin) {
        const { screenStream, cameraStream, remoteStream } =
          await NewAdminPermissionsStream();
        webcamVideoRef.current.srcObject = cameraStream;
        remoteVideoRef.current.srcObject = remoteStream;
        shareVideoRef.current.srcObject = screenStream;
        const cid = await callButtonFunction();
        await sendAnimateNotification(friend,cid);
        setFriend(null);
      } else {
        const cId=urlParams.get("callId");
        const { localStream, remoteScreenStream, remoteCameraStream } =
        await NewParticipantPermissionsStream();
        webcamVideoRef.current.srcObject = localStream;
        remoteVideoRef.current.srcObject = remoteCameraStream;
        shareVideoRef.current.srcObject = remoteScreenStream;
        await answerButtonFunction(cId);
      }
    }
    Initialize();
    return () => {
      hangUp();
    };
    // For simplicity, we're using URL parameters to determine admin status and room ID
  }, []);
  const nodeRef = useRef(null);
  const v1nodeRef = useRef(null);
  useEffect(() => {
    // console.log(remoteVideoRef.current.srcObject);
    if (
      remoteVideoRef.current.srcObject &&
      remoteVideoRef.current.srcObject.active
    ) {
      setconnectionOn(1);
    }
  }, [remoteVideoRef]);
  if (urlParams.size === 0) {
    return <>Add admin params</>;
  }
  const isMobile = () => {
    return false;
    // return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    //   navigator.userAgent
    // );
  };

  // Apply styles for fullscreen
  const screenShareStyle = {
    width: fullScreen ? "auto" : "100vw",
    height: fullScreen ? "100vh" : "auto",
    maxHeight: fullScreen && isMobile() ? "100vw" : "none",
    transform: fullScreen && isMobile() ? "rotate(90deg)" : "none",
    objectFit: "contain",
  };

  return (
    <div className=" flex flex-col justify-center items-center ">
      {/* {!connectionOn && callId && <div>Id: {callId}</div>} */}

      {/* <div className="modern-font text-4xl font-semibold">AniMate</div> */}
      <video
        ref={shareVideoRef}
        className=""
        // className={`${
        //   fullScreen && "z-2 !w-[100vw] !h-[100vh] "
        // } w-100 h-90  rounded-xl`}
        style={screenShareStyle}
        autoPlay
        playsInline
        muted={admin ? "true" : "false"}
      ></video>
      <Button
        // variant
        className={"bg-black border-[1px] absolute top-3 left-3 px-3 py-2 "+timeoutButtonsClassName}
        onClick={() => {
          if (!fullScreen) {
            setfullScreen(1);
            handleFullscreenToggle(1);
          } else {
            setfullScreen(0);
            handleFullscreenToggle(0);
          }
        }}
      >
        {fullScreen ? <Minimize2 size={17} /> : <Maximize2 size={17} />}
      </Button>
      {/* <div className="flex gap-3 justify-between items-center w-100 mt-5"> */}
      <Draggable
        nodeRef={v1nodeRef}
        handle=".header"
        bounds="parent"
        defaultPosition={{ x: 10, y: 10 }}
      >
        <div
          ref={v1nodeRef}
          className="   absolute flex flex-col justify-center items-center "
        >
          <h4 className={`${!visibilitySelf && "hidden"}`}>You</h4>
          <div className="header  h-100 w-100">
            <video
              ref={webcamVideoRef}
              className={`${
                !visibilitySelf && "hidden"
              } border-[1px] w-[150px] sm:w-[200px]  md:w-[220px]  lg:w-[270px] h-auto rounded-lg`}
              autoPlay
              playsInline
              muted
            ></video>
            </div>
            <div className="-mt-2 flex justify-center items-center gap-2">
              <Button
                onClick={async () => {
                  toggleVideo();
                }}
                className={`dark px-2 ${!visibilitySelf && "hidden"}`}
                variant="outline"
              >
                {videoSharing ? <Video size={20} /> : <VideoOff size={20} />}
              </Button>

              <Button
                onClick={async () => {
                  // toggleMicScreenShare();
                  toggleMicVideo();
                }}
                className={`${!visibilitySelf && "hidden"} dark px-2`}
                variant="outline"
              >
                {micOn ? <Mic size={20} /> : <MicOff size={20} />}
              </Button>
              <Button
                onClick={async () => {
                  setvisibilitySelf((p) => !p);
                }}
                className={`dark px-2 ${!visibilitySelf&&timeoutButtonsClassName}`}
                variant="outline"
              >
                {visibilitySelf ? <Eye size={20} /> : <EyeOff size={20} />}
              </Button>
            </div>
          
        </div>
      </Draggable>
      <Draggable
        nodeRef={nodeRef}
        handle=".header"
        bounds="parent"
        defaultPosition={{ x: 10, y: -130 }}
      >
        <div
          ref={nodeRef}
          className=" absolute flex flex-col justify-center items-center "
        >
          <h4 className={`${!visibilityFriend && "hidden"}`}>Friend</h4>
          <div className="header h-100 w-100">
            <video
              ref={remoteVideoRef}
              className={`${
                !visibilityFriend && "hidden"
              } border-[1px]  w-[150px] sm:w-[200px]  md:w-[220px]  lg:w-[270px] h-auto rounded-lg`}
              autoPlay
              playsInline
            ></video>
            </div>
            <div className="flex items-center justify-center">
              <Button
                className={`-mt-2 dark px-2 ${!visibilityFriend&&timeoutButtonsClassName}`}
                onClick={async () => {
                  setvisibilityFriend((p) => !p);
                }}
                variant="outline"
              >
                {visibilityFriend ? <Eye size={20} /> : <EyeOff size={20} />}
              </Button>
            </div>
          
        </div>
      </Draggable>
      <Button
      onClick={()=>{
        handleHangUp();
      }} 
       className={timeoutButtonsClassName+" fixed bottom-4 bg-red-600 text-white p-4 rounded-full"}><Phone/></Button>
      {/* {!connectionOn && (
        <>
          <Button
            onClick={async () => {
              const cid = await callButtonFunction();
              setcallId(cid);
              // setconnectionOn(1);
            }}
            className="dark mt-5"
            variant="outline"
          >
            Call
          </Button>
          <input
            placeholder="call Id"
            className="mt-5 px-2 border-2 my-2 rounded-lg"
            value={callId}
            onChange={(e) => {
              setcallId(e.target.value);
            }}
            type="text"
          />
          <Button
            onClick={async () => {
              await answerButtonFunction(callId);
              setconnectionOn(1);
            }}
            className="dark my-2"
            variant="outline"
          >
            Answer
          </Button>
        </>
      )} */}
    </div>
  );
};

export default AniMate;
