import React, { useState, useRef, useEffect } from "react";
import Messages from "./Messages";
import InputBar from "./InputBar";
import { auth, db } from "./firebase";
import firebase from "firebase/compat/app";
import { useCollectionData } from "react-firebase-hooks/firestore";

import {
  limitToLast,
  setDoc,
  doc,
  collection,
  addDoc,
  query,
  updateDoc,
  arrayUnion,
  orderBy,
  limit,
} from "firebase/firestore";

const Chat = (props) => {
  const [message, setmessage] = useState("");
  const dummy = useRef();
  //   const allmessages=[];

  const messagesRef = collection(
    db,
    `roomchats/${props.roomData.roomname}/messages`
  );
//   const allmessages=[];
  const q = query(messagesRef, orderBy("createdAt"), limitToLast(50));
  const [allmessages] = useCollectionData(q, { idField: "id" });
  console.log("read");
  
  
  
  async function sendMessage(e) {
    e.preventDefault();
    setmessage("");
    const { uid, photoURL } = auth.currentUser;

    const newmessage = {
      text: message,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid,
      photoURL,
    };
    await addDoc(messagesRef,newmessage);
    
    dummy.current.scrollIntoView({ behavior: "smooth" });
  }

  useEffect(() => {
    dummy.current.scrollIntoView({ behavior: "smooth" });
  }, [allmessages]);
// console.log(allmessages);
  return (
    <>
      <div className="top-bar">
        <div className="joined-heading mt-2">{props.roomData.roomname}</div>
      </div>
      <div className="seperaterline mt-2 " style={{ width: "100%" }}></div>
        <Messages 
            allmessages={allmessages}
            dummy={dummy}
            roomname={props.roomData.roomname}
        />
      <form onSubmit={sendMessage} id="chatform">
        <InputBar
          sendMessage={sendMessage}
          message={message}
          setmessage={setmessage}
        />
      </form>
    </>
  );
};

export default Chat;
