import React from "react";
import MemberCards from "./MemberCards";
import { Link } from "react-router-dom";
import { auth } from "./firebase";
import StudyTogetherBar from "./StudyTogetherBar";

const Members = (props) => {

  return (
    <>
      <div className="top-bar  ">
        <div className="joined-heading mt-2">Members</div>
      </div>
      <div className="seperaterline mt-2" style={{ width: "100%" }}></div>
      <div className="reqcont">
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 p-2 ">
          {props.roomData.members.map((profile, index) => (
            <Link key={profile.email}
          className="nav-link nav-item "
          to="/stats"
          state={{ email: profile.email }}
          onClick={() => {
            // console.log("clicked");
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            // window.location.reload();
          }}
        >
            <MemberCards
              key={index}
              index={index}
              profile={profile}
              roomname={props.roomData.roomname}
            />
            </Link>
          ))}
        </div>
      </div>
      {/* {auth.currentUser.email===props.roomData.admin_email&&<div>
          <StudyTogetherBar
            roomData={props.roomData}
          />
        </div>} */}
    </>
  );
};

export default Members;
