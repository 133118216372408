import React from "react";
import { WavyBackground } from "../components/ui/WavyBackground";
import Navbar from "../components/Navbar";
import Showcard from "../components/ui/Showcard";
// import { TextGenerateEffect } from "../components/ui/text-generate-effect";
import { StickyScroll } from "../components/ui/sticky-scroll-reveal";

const content = [
  {
    title: "Collaborative Editing",
    description:
      "Work together in real time with your team, clients, and stakeholders. Collaborate on documents, share ideas, and make decisions quickly. With our platform, you can streamline your workflow and increase productivity.",
    content: (
      <div className="h-full w-full bg-[linear-gradient(to_bottom_right,var(--cyan-500),var(--emerald-500))] flex items-center justify-center text-white">
        Collaborative Editing
      </div>
    ),
  },
  {
    title: "Real time changes",
    description:
      "See changes as they happen. With our platform, you can track every modification in real time. No more confusion about the latest version of your project. Say goodbye to the chaos of version control and embrace the simplicity of real-time updates.",
    content: (
      <div className="h-full w-full  flex items-center justify-center text-white">
        {/* <Image
          src="/linear.webp"
          width={300}
          height={300}
          className="h-full w-full object-cover"
          alt="linear board demo"
        /> */}
      </div>
    ),
  },
  {
    title: "Version control",
    description:
      "Experience real-time updates and never stress about version control again. Our platform ensures that you're always working on the most recent version of your project, eliminating the need for constant manual updates. Stay in the loop, keep your team aligned, and maintain the flow of your work without any interruptions.",
    content: (
      <div className="h-full w-full bg-[linear-gradient(to_bottom_right,var(--orange-500),var(--yellow-500))] flex items-center justify-center text-white">
        Version control
      </div>
    ),
  },
  {
    title: "Running out of content",
    description:
      "Experience real-time updates and never stress about version control again. Our platform ensures that you're always working on the most recent version of your project, eliminating the need for constant manual updates. Stay in the loop, keep your team aligned, and maintain the flow of your work without any interruptions.",
    content: (
      <div className="h-full w-full bg-[linear-gradient(to_bottom_right,var(--cyan-500),var(--emerald-500))] flex items-center justify-center text-white">
        Running out of content
      </div>
    ),
  },
];

function About() {
  const features = [
    {
      title: "Draggable",
      description: "draggable widgets",
      imagelink: "./images/Dragable-ss.webp",
    },
    {
      title: "Stats",
      description: "track your progress and streaks",
      imagelink: "./images/stats-ss1.webp",
    },
    {
      title: "Breathe",
      description: "stay calm between sessions",
      imagelink: "./images/Breathe-ss2.webp",
    },
    {
      title: "Study Together",
      description: "study with your friends",
      imagelink: "./images/study-together-ss3.png",
    },
    {
      title: "Themes",
      description: "customize however you like",
      imagelink:
        "https://wallpapers.com/images/hd/4k-andromeda-galaxy-68af0gq8brnv6qkk.webp",
    },
    {
      title: "Rooms",
      description: "connect and learn with friends",
      imagelink: "./images/Rooms-ss.webp",
    },
  ];
  const contentfeatures = [
    {
      title: "Draggable Widgets",
      description: "Customize your workspace effortlessly with draggable widgets.",
      content: (<div className="scrollcardimg" style={{
        backgroundImage:`url("${"./images/Dragable-ss.webp"}")`
      }}></div>),
    },
    {
      title: "Statistics",
      description: "Monitor your learning journey and track progress and streaks.",
      content: (<div className="scrollcardimg" style={{
        backgroundImage:`url("${"./images/stats-ss1.webp"}")`
      }}></div>),
    },
    {
      title: "Breathe Widget",
      description: "Stay centered and relaxed with tranquil breathing exercises.",
      content: (<div className="scrollcardimg" style={{
        backgroundImage:`url("${"./images/Breathe-ss2.webp"}")`
      }}></div>),
    },
    {
      title: "Study Together",
      description: "Collaborate and boost productivity by studying with your peers.",
      content: (<div className="scrollcardimg" style={{
        backgroundImage:`url("${"./images/study-together-ss3.png"}")`
      }}></div>),
    },
    {
      title: "Themes",
      description: "Personalize your experience with customizable themes.",
        content: (<div className="scrollcardimg" style={{
            backgroundImage:`url("${"https://wallpapers.com/images/hd/4k-andromeda-galaxy-68af0gq8brnv6qkk.webp"}")`
          }}></div>),
    },
    {
      title: "Rooms",
      description: "Connect with friends and create conducive learning environments.",
      content: (<div className="scrollcardimg" style={{
        backgroundImage:`url("${"./images/Rooms-ss.webp"}")`
      }}></div>),
    },
    {
      title: "",
      description: "",
      content: (<div className="scrollcardimg" style={{
        backgroundImage:`url("${"./images/Rooms-ss.webp"}")`
      }}></div>),
    },
  ];
  return (
    <>
    <Navbar />
    {/* <TextGenerateEffect words={"Bullaa hai mera naam tera naam kya hai"} /> */}

      <WavyBackground/>
      {/* <div className="showcardcontainer "> */}
        {/* <div className="container-fluid">
          <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-3 ">
            {features.map((feature) => {
              return (
                <Showcard
                  title={feature.title}
                  description={feature.description}
                  imagelink={feature.imagelink}
                />
              );
            })}
            
          </div>
        </div> */}
        
      {/* </div> */}
      {/* <div className="p-10"> */}
      <div className="overflow-hidden dark:bg-[#0B0B0F] bg-white w-full">
      {/* <MacbookScroll
        title={
          <span>
            This Macbook is built with Tailwindcss. <br /> No kidding.
          </span>
        }
        badge={
          <Link href="https://peerlist.io/manuarora">
            <Badge className="h-10 w-10 transform -rotate-12" />
          </Link>
        }
        src={`/linear.webp`}
        showGradient={false}
      /> */}
    </div>
    <StickyScroll content={contentfeatures} />
            {/* </div> */}
    </>
  );
}

export default About;
