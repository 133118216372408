import React, { useEffect, useState } from "react";
import ProfileScroll from "./ProfileScroll";
import Tags from "./Tags";

import {auth,db} from "./firebase";
import { updateDoc,arrayUnion,setDoc ,doc} from "firebase/firestore";

function DetailCard(props) {
  useEffect(() => {
    check();
  }, []);
  const newMember = {
    name:auth.currentUser.displayName,
    email:auth.currentUser.email,
    uid:auth.currentUser.uid,
    imageurl:auth.currentUser.photoURL
  };
  const [loading, setloading] = useState(false);
  const [alreadysent, setalreadysent] = useState(false);
  function check() {
    // console.log("checkin")
    for(var i=0;i<props.roomData.requests.length;i++)
    {
      // console.log(props.roomData.requests[i]);
      if(props.roomData.requests[i].uid===newMember.uid){
        setalreadysent(true);
        break;
      }
    }
  }
  async function addrequest() {
    setloading(true);
    const roomRef = doc(db, "Rooms", props.roomData.roomname);
    
    await updateDoc(roomRef, {
        requests: arrayUnion(newMember)
    }).then(setloading(false));
    // console.log("requestsent");
    setalreadysent(true);
}
  // console.log(props);
  return (
    <div className="detailswrapper ">

    <div className="detailscard ">
      <div className="detailsheader ">
        <p className="detailstitle mt-3">{props.roomData.roomname}</p>
        <div className="seperaterline"></div>
        <div className="m-2"><Tags tag1={props.roomData.tag1}
          tag2={props.roomData.tag2}
        /></div>
        <div style={{paddingBottom:"10px",paddingTop:"10px"}}>
        <div className="descbody" style={{width:"300px"}}>
            <div className="roomdesc" style={{fontSize:"12px"}}>Target Time: {props.roomData.target_time} hrs/week</div>
            <div className="roomdesc" style={{fontSize:"12px"}}>Members: {props.roomData.members.length}</div>
          </div>
          <div className="descbody" style={{width:"300px"}}>
            <div className="roomdesc" style={{fontSize:"12px"}}><em> Admin: </em></div>
            <div className="roomdesc" style={{fontSize:"12px"}}><em> {props.roomData.admin_email}</em></div>
          </div> 
        </div>
        <ProfileScroll members={props.roomData.members}/>
        <p className="detailsdesc">
          {props.roomData.description}
        </p>
      </div>
      <div className="detailscardfooter">
        <button className="footerbtn btn btn-outline-secondary mr-2 "
        onClick={()=>{addrequest()}}
        disabled={alreadysent}>
         {alreadysent?"Sent":"Request"} 
        </button>
        <button
          onClick={() => props.setclicked(-1)}
          className="footerbtn btn btn-outline-secondary ml-5"
        >
          Cancel
        </button>
      </div>
    </div>
    </div>
  );
}

export default DetailCard;
