"use client";

import React, { useEffect, useState } from "react";
import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";

import { cn } from "../../lib/utils";
import { Button } from "../ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/popover";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";

export function FriendSelect({
  friendEmails,
  allUsers,
  invitedMember,
  setInvitedMember,
}) {
  const [open, setOpen] = useState(false);

  function toggle(value){
    if(value&&value.length!==0){
        const entries = Object.entries(allUsers[0]);
        const valueObject=entries.map(([key,obj])=>{return {...obj,imageurl:obj.img,id:key}}).filter((u)=>u.email===value)[0];
        setInvitedMember(p=>p&&(valueObject.email===p.email)?null:valueObject);
      }
    }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger className="!border-[#928080]" asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[200px] justify-between"
        >
          {"Select Friend..."}
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0 dark !border-0  !border-[#928080]">
        <Command>
          <CommandInput placeholder="Search Friend..." className="h-9 " />
          <CommandEmpty>No Friends Found.</CommandEmpty>
          <CommandGroup className="!border-[#928080] ">
            <CommandList className="!text-[#928080] ">
              {allUsers &&
                Object.values(allUsers[0]).map((usr) => {
                  if (
                    friendEmails.find(
                      (friendEmail) => usr.email === friendEmail
                    )
                  ) {
                    return (
                      <CommandItem
                        className="mx-1"
                        key={usr.email}
                        value={usr.email}
                        onSelect={(currentValue) => {
                          toggle(currentValue);
                          // setOpen(false);
                        }}
                      >

                        <Avatar className=" border-2 border-[#3d3b3b] !w-10 !h-10 mr-2">
                          <AvatarImage className="" src={usr.img} />
                          <AvatarFallback className=" text-white font-bold bg-[#302d2d] ">
                            {usr.name[0]}
                          </AvatarFallback>
                        </Avatar>
                        <div className=" ml-auto text-muted-foreground  truncate">
                          {usr.name}
                        </div>
                        <CheckIcon
                          className={cn(
                            "ml-auto h-4 w-4",
                            (invitedMember&&(invitedMember.email===usr.email)) ? "opacity-100" : "opacity-0"
                          )}
                        />
                      </CommandItem>
                    );
                  }
                })}
            </CommandList>
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}