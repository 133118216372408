import { realtimeDb } from "../components/firebase";

export const deleteSpace = async (spaceId) => {
    try {
      const spaceRef = realtimeDb.ref(`spaces/${spaceId}`);
  
      // Check if the space exists
      const snapshot = await spaceRef.once('value');
      if (snapshot.exists()) {
        // Delete the space
        await spaceRef.remove();
        console.log(`Space with ID ${spaceId} has been deleted.`);
      } else {
        console.log(`Space with ID ${spaceId} does not exist.`);
      }
    } catch (error) {
      console.error('Error deleting space:', error);
    }
  };
  