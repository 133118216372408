import React from 'react';
import ChatOutlined from '@mui/icons-material/ChatOutlined';
import Diversity2OutlinedIcon from '@mui/icons-material/Diversity2Outlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';// import ot from "../../public/android-chrome-512x512.png"
// import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import {db,auth} from "./firebase";

// For now i want 3 things inside this
//sidebar
//1) Messages of the group
//2)Members of the group 
// We can also add the study together function in this
// and whether the user is offline or online, study hours,warnings, admin logo etc.
//3) Description of the group
//maybe in future we can add more options such as polling 


const SideBar = (props) => {
    return (
        <div>
        <div className='logo' ></div>
           <div className="verticalbutton"
           onClick={()=>{props.setmainmode(0);}}>
            <ChatOutlined fontSize='large' />
           </div>
           <div className="verticalbutton"
           onClick={()=>{props.setmainmode(1);}}>
           <PeopleAltOutlinedIcon fontSize='large' />
           </div>
           <div className="verticalbutton"
           onClick={()=>{props.setmainmode(2);}}>
            <Diversity2OutlinedIcon fontSize='large' />
           </div>
           {props.roomData.admin_email===auth.currentUser.email&&<div className="verticalbutton"
           onClick={()=>{props.setmainmode(3);}}>
            <LibraryAddOutlinedIcon fontSize='large' />
           </div>}
           
        </div>
    );
}

export default SideBar;
