import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
  } from "../../ui/alert-dialog";
  import React, { useState } from "react";
 
  
  const Completion = ({ time,isOpen,setIsOpen}) => {
   
  
    return (
      <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
        <AlertDialogContent className="dark  !border-[#363131]">
          <AlertDialogHeader>
            <AlertDialogTitle className="text-3xl">
              Success !
            </AlertDialogTitle>
            <AlertDialogDescription className="!text-base not-italic ">
              Space Timer of <strong>{time} minutes</strong>  successfully completed .
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter className="justify-center">
            <AlertDialogCancel
              onClick={() => {
              }}
              className="!border-[#8c888870] bg-black hover:!bg-green-500 hover:!border-none !px-4 -mb-2"
            >
              Done
            </AlertDialogCancel>
            {/* <AlertDialogAction
              onClick={() => {
                acceptInvite();
              }}
              className="!border-[#8c888870] bg-black hover:!bg-green-500 hover:!border-none !px-4"
            >
              Join
            </AlertDialogAction> */}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    );
  };
  
  export default Completion;