import { useAuthState } from "react-firebase-hooks/auth";
import { db, auth } from "../components/firebase";
import {
  doc,
  updateDoc,
} from "firebase/firestore";
 
export const deleteNotification = async (user) => {
    const userRef = user ? doc(db, "users", user.uid) : null;
    try {
      await updateDoc(userRef, {
        room_notifications:[]
      });
      console.log("Notification deleted successfully.");
    } catch (error) {
      console.error("Error deleting notification:", error);
    }
  };