import React from "react";

function Music({onlyTimer}) {
  return (
    <main className={`${onlyTimer ? "hide-toggle" : "open-toggle"}`}>
      <div className="card ">
        <div className="face face1">
          <div className="content">
            <span className="stars"></span>
            <iframe
              title="spot"
              src="https://open.spotify.com/embed/playlist/1t0v03QvAMdDdKo1WeyaQI?utm_source=generator&theme=0" //anime playlist  https://open.spotify.com/embed/playlist/4WtqLI6gaRFaWB4g6mDnAX?utm_source=generator&theme=0
              width="100%"
              height="100"
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="lazy"
            ></iframe>
          </div>
        </div>
        <div style={{ fontSize: "5px" }} className="face face2">
          <h2>LoFi</h2>
        </div>
      </div>
    </main>
  );
}
export default Music;




  