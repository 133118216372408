import React, { useState } from "react";
import { Link } from "react-router-dom";
// import { auth } from "./firebase";
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  arrayUnion,
  serverTimestamp,
  FieldValue,
} from "firebase/firestore";
import { db,auth } from "../components/firebase";
// Import your Firebase app instance
import firebase from "firebase/compat/app";

// this notification is for spaces feature

export async function sendNotification(recipient,space) {
    // space:{id,createdBy,participants,timer:{isRunning,endTime,duration} }
  
  //space{spaceId}
  const ts = firebase.firestore.Timestamp.now();
  const notification = {
    focus_time: space.timer.duration,
    admin_name: space.createdBy,//auth.currentUser.displayName,
    timestamp: ts,
    spaceId:space.id
  };
  // Check if the recipient user exists in the users collection
//  console.log(recipient.id);
  const userRef = doc(db, "users", recipient.id);
  // console.log("lololl",userRef)
  const userSnapshot = await getDoc(userRef);

  if (!userSnapshot.exists()) {
    // User doesn't exist, add them to the users collection
    await setDoc(userRef, {
      status: {
        online: false,
        studying: false,
        email: recipient.email,
        name: recipient.name,
        img: recipient.imageurl,
      },
      room_notifications: [],
    });
  }

  // Retrieve the user document
  // const userDoc = userSnapshot.data();
  // console.log(userDoc);
  // Check if the notificationsArray exists, initialize if it doesn't

  // if (!userDoc.room_notifications) {
    // await updateDoc(userRef, {
    //   room_notifications: [],
    // });
  // }


  // Add the notification to the user's notificationsArray
  // console.log(notification);
  
  await updateDoc(userRef, {
    room_notifications: [notification],
  });

  // await updateDoc(
  //   userRef,
  //   {
  //     room_notifications: arrayUnion(),
  //   },
  //   { merge: true }
  // );

  console.log("Notification sent successfully!");
}
