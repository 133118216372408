// modalState.js
import { atom } from "recoil";

export const modalState = atom({
  key: "modalState",
  default: false,
});

export const modalNotificationState = atom({
  key: "modalNotificationState",
  default: null,
  // {spaceId:"",createdBy:"",timer:{duration:0}},
});

export const modalAnimateState = atom({
  key: "modalAnimateState",
  default: false,
});

export const modalAnimateNotificationState = atom({
  key: "modalAnimateNotificationState",
  default: null,
});
