import React, { useState } from "react";

function Custom({ setlink, link }) {
  return (
    <div
      className="detailsdesc container  !overflow-x-hidden"
      style={{ height: "250px", width: "270px" }}
    >
      <div className="flex flex-col">
        <label className="text-md" htmlFor="wallpaper">Link:</label>
        <input
          //   autoComplete="off"
          onChange={(e) => {
            setlink(e.target.value);
          }}
          name="wallpaper"
          value={link}
          type="text"
          className="form-control-sm "
          style={{ paddingLeft: "" }}
          //   id="name"
          //   required
        />
      </div>
      <div style={{ fontSize: "9px", marginTop: "30px" }}>
        *links should end with .png or .jpeg
      </div>
      <div style={{ fontSize: "9px", marginTop: "20px" }}>
        *In order to get an image link right click on the image and click on
        copy image link instead of copy image
      </div>
    </div>
  );
}
function DefaultThemes({ link, setlink, setcustomlink, setcustommode }) {
  // console.log(link)
  const cosmos = [
    "https://wallpapers.com/images/hd/4k-andromeda-galaxy-68af0gq8brnv6qkk.webp",
    "https://wallpapers.com/images/hd/purple-and-pink-galaxy-hdg0eaitkhrstbyu.webp",
    "https://wallpapers.com/images/hd/planet-earth-4k-space-hd-qis7o51sock2fwsr.webp",
  ];
  const anime = [
    "https://images5.alphacoders.com/131/1316393.png",
    "https://4kwallpapers.com/images/wallpapers/izuku-midoriya-deku-my-hero-academia-green-background-2560x1440-9151.png",
    "https://images7.alphacoders.com/134/1341150.png",
    "https://images.hdqwalls.com/download/goku-arrival-in-the-bleach-universe-2m-7680x4320.jpg",
  ];
  const work = [
    "https://i.ibb.co/4g5MsWy/owall-9.jpg",
    "https://i.ibb.co/XjdDVkS/owall-8.jpg",
    "https://i.ibb.co/k8t6STs/owall-7.jpg",
    
    "https://i.ibb.co/B64V7qn/owall-10.jpg",
    "https://i.ibb.co/dmCF0Nv/owall-6.jpg",
    "https://i.ibb.co/GcPKkzK/owall-4-transformed.jpg",
    "https://i.ibb.co/Wt9YD7q/owall-3-transformed.jpg",
    "https://i.ibb.co/WntFXDf/0wall-1.jpg",
    "https://i.ibb.co/c1yzKfx/owall-2.jpg",
    "https://i.ibb.co/RpVf4kp/owall-5.jpg",
  ];
  return (
    <div
      className="flex justify-center flex-wrap gap-3"
      style={{ height: "250px", width: "250px" }}
    >
      <Theme
        setlink={setlink}
        current={link}
        setcustomlink={setcustomlink}
        array={work}
        name={"Work"}
        imglink={
          "https://i.ibb.co/RpVf4kp/owall-5.jpg"
        }
      />
      <Theme
        setlink={setlink}
        current={link}
        setcustomlink={setcustomlink}
        array={cosmos}
        name={"Cosmos"}
        imglink={
          "https://wallpapers.com/images/hd/4k-andromeda-galaxy-68af0gq8brnv6qkk.webp"
        }
      />
      <Theme
        setlink={setlink}
        current={link}
        setcustomlink={setcustomlink}
        array={anime}
        name={"Anime"}
        imglink={
          "https://images.hdqwalls.com/download/goku-arrival-in-the-bleach-universe-2m-7680x4320.jpg"
        }
      />

      <Theme
        setcustommode={setcustommode}
        name={"Custom"}
        imglink={
          "https://imageio.forbes.com/specials-images/imageserve/5ed68e8310716f0007411996/A-black-screen--like-the-one-that-overtook-the-internet-on-the-morning-of-June-2-/960x0.jpg?format=jpg&width=960"
        }
      />

      <div className="row row-cols-1 row-cols-sm-2 d-flex flex-column align-items-center "></div>
    </div>
  );
}
function getRandomElement(fullarray, exclude) {
  let array = fullarray.filter((element) => element !== exclude);
  const randomIndex = Math.floor(Math.random() * array.length);

  return array[randomIndex];
}
function Theme({
  imglink,
  name,
  setcustomlink,
  array,
  current,
  setcustommode,
  setlink,
}) {
  return (
    <div
      className="theme "
      onClick={() => {
        if (name === "Custom") {
          setcustommode(1);
        }
        setcustomlink((prev) => {
          const rand = getRandomElement(array, prev);
          setlink(rand);
          return rand;
        });
      }}
    >
      <img className="theme-img" src={imglink} alt="" />

      <div className="theme-text text-center opacity-50">{name}</div>
    </div>
  );
}
function Wallpaper(props) {
  const [link, setlink] = useState("");
  const [custommode, setcustommode] = useState(0);

  return (
    <div className="detailscard online-popup">
      <div className="detailsheader ">
        <p className="detailstitle">Themes</p>
        <div className="seperaterline w-100"></div>

        {custommode != 0 ? (
          <Custom setlink={setlink} link={link} />
        ) : (
          <DefaultThemes
            setcustommode={setcustommode}
            setlink={setlink}
            link={link}
            setcustomlink={props.setcustomlink}
          />
        )}
      </div>
      <div className="detailscardfooter">
        <button
          onClick={() => props.setwallpaperclick(0)}
          className="footerbtn btn btn-outline-secondary "
        >
          Cancel
        </button>
        <button
          onClick={() => {
            props.setcustomlink(link);
            props.setwallpaperclick(0);
          }}
          className="footerbtn btn btn-outline-secondary ml-3"
        >
          Save
        </button>
      </div>
    </div>
  );
}

export default Wallpaper;
