import React from 'react'
export default function OnlinePop(props) {
  return (
    <div className="detailscard online-popup">
      <div className="detailsheader ">
        <p className="detailstitle">{props.Atime?"Resting":"Focusing"}</p>
        <div className="seperaterline w-100"></div>
        
        
        <div className="detailsdesc container "
        style={{height:"250px"}}>
          
          <div className="row row-cols-1 row-cols-sm-2 d-flex flex-column align-items-center ">
          
        {props.allusers&&props.allusers.map((profile) => {
          
            if((props.Atime==0)&&(profile.studying==1)){
                return(<div className="col profile-card m-2" key={profile.email}
                style={{width:"200px"}}
                >
            <img src={profile.img} />
            <p>{profile.name}</p>
          </div>);}
          else if((props.Atime==1)&&(profile.studying==0)){
            return(<div className="col profile-card m-2" key={profile.email}
                style={{width:"200px"}}
                >
            <img src={profile.img} />
            <p>{profile.name}</p>
          </div>);
          }
          }
        )}

          </div>
        </div>
      </div>
      <div className="detailscardfooter">
        <button
          onClick={() => props.setfocusingclick(0)}
          className="footerbtn btn btn-outline-secondary "
        >
          Cancel
        </button>
      </div>
    </div>
  )
}
