import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import Stats from "./pages/Stats";
import Rooms from "./pages/Rooms";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import {app,auth} from "./components/firebase";
import firebase from "firebase/compat/app";
import PreventSleep from "./components/PreventSleep";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';
import UserContext from './UserContext';
import OfflinePopup from "./components/OfflinePopup";
import { AuthContextProvider } from "./context/AuthContext";
import About from "./pages/About";
import { RecoilRoot } from "recoil";
import { Toaster } from "./components/ui/toaster"
import AniMate from "./pages/AniMate";

const handleSignOut = () => {
  auth.signOut()
    .then(() => {
      // setUser(null);
      console.log("Successfully signed out")
    })
    .catch((error) => {
      console.error('Error signing out:', error);
    });
};
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthContextProvider>
  {/* <React.StrictMode> */}
  {/* <PreventSleep/> */}
      <UserContext.Provider value={{handleSignOut }}>
{/* <OfflinePopup/> */}
<RecoilRoot>
<Toaster/>
    <Router>
      <Routes>
        <Route exact path="/" element={<App />} />
        <Route exact path="/login" element={<Login/>}/>
        <Route exact path="/signup" element={<SignUp/>}></Route>
        <Route exact path="/stats" element={<Stats/>}></Route>
        <Route exact path="/privacypolicy" element={ <Link to="https://divyam1705.github.io/Privacy-Policy/"></Link>}>  </Route>
        <Route exact path="/rooms" element={<Rooms/>}></Route>
        <Route exact path="/about" element={<About/>}></Route>
        <Route exact path="/animate" element={<AniMate/>}></Route>
      </Routes>
    </Router>
    </RecoilRoot>
    </UserContext.Provider>
  {/* </React.StrictMode> */}
  </AuthContextProvider>
);



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();