"use client";

import { useEffect, useRef } from "react";
import { useInView, useMotionValue, useSpring } from "framer-motion";

import { cn } from "../../lib/utils";
export default function NumberTicker({
  value,
  direction = "up",
  delay = 0,
  className,
}) // : {
//   value: number;
//   direction?: "up" | "down";
//   className?: string;
//   delay?: number; // delay in s
// }
{
  
  const ref = useRef(null);
  const motionValue = useMotionValue(direction === "down" ? value : -1);
  const springValue = useSpring(motionValue, {
    damping: 60,
    stiffness: 100,
  });
  const isInView = useInView(ref, { once: true, margin: "0px" });

  useEffect(() => {
    isInView &&
      setTimeout(() => {
        motionValue.set(direction === "down" ? 0 : value);
      }, delay * 1000);
  }, [motionValue, isInView, delay, value, direction]);

  useEffect(
    () =>
      springValue.on("change", (latest) => {
        if (ref.current) {
          ref.current.textContent = Intl.NumberFormat("en-US").format(
            latest.toFixed(0)
          );
        }
      }),
    [springValue]
  );
  if (value === 0) {
    return <div>{value}</div>;
  }
  return (
    <span
      className={cn(
        "inline-block tabular-nums tracking-tighter  dark:text-white ",
        className
      )}
      ref={ref}
    />
  );
}
