import React, { useEffect, useState } from "react";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar";
import { Button } from "../ui/button";
import { auth } from "../firebase";

function Landing({setchoice}) {
 
  return (
    <>
            <div className="text-center my-2 text-sm text-muted-foreground text-[#928080]">Create Your Own Space</div>

      <div className=" w-full flex justify-center items-center my-4">
        <Avatar className=" border-2 border-[#3d3b3b]">
          <AvatarImage src={auth.currentUser?.photoURL} />
          <AvatarFallback className=" text-white font-bold bg-[#302d2d] ">{localStorage.getItem("userName")[0]}</AvatarFallback>
        </Avatar>
      </div>
      <div className="flex items-center justify-between px-3 my-3">
        <Button 
        onClick={()=>{setchoice(1)}}
        className=" text-[#928080] font-bold rounded-3xl neumorphism bg-[#262424]">
          Create Space
        </Button>
        <Button 
        onClick={()=>{setchoice(2)}}
        className="text-[#928080] font-bold rounded-3xl neumorphism bg-[#262424]">
          Join Space
        </Button>
      </div>
    </>
  );
}

export default Landing;
