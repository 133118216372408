import React, { useEffect, useState } from "react";

function PreventSleep() {
  const [wakeLock, setWakeLock] = useState(null);

  useEffect(() => {
    if ("wakeLock" in navigator) {
      navigator.wakeLock
        .request("screen")
        .then((wl) => {
          setWakeLock(wl);
        })
        .catch((err) => {
          console.error(`${err.name}, ${err.message}`);
        });
    }
    return () => {
      if (wakeLock !== null) {
        wakeLock.release();
        setWakeLock(null);
      }
    };
  }, [wakeLock]);

  return null;
}

export default PreventSleep;
