import React from "react";

// let button = document.querySelector('.button');
// let testA = document.querySelector('a.net');
// let backgroundButton = document.querySelector('.background__button');

//   button.addEventListener('mouseenter', function(){
//     testA.classList.add('is-white')
//     backgroundButton.classList.add('is-hover');
//   });

//   button.addEventListener('mouseleave', function(){
//     testA.classList.remove('is-white')
//     backgroundButton.classList.remove('is-hover');
//   });
function NetButton({onlyTimer}) {
  return (
    <main className={`net ${onlyTimer ? "hide-toggle" : "open-toggle"}`}>
      <div class="button">
        <a
          class="net"
          href="https://www.netflix.com/in/browse/genre/7424"
          target="_blank"
          rel="noopener noreferrer"
        >
          NETFLIX
        </a>
        <div class="background__button"></div>
      </div>
    </main>
  );
}
export default NetButton;
