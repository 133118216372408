import React, { useState, useEffect } from "react";
import { useTimer } from "react-timer-hook";
import NetButton from "./NetButton";
import Music from "./Music";
import { useLocation } from "react-router-dom";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import {
  doc,
  setDoc,
  updateDoc,
  collection,
  getDoc,
  where,
  onSnapshot,
  query,
} from "firebase/firestore";
import AutohideToast from "./AutohideToast";
import { useCollectionData } from "react-firebase-hooks/firestore";
import AirOutlinedIcon from "@mui/icons-material/AirOutlined";
import { useAuthState } from "react-firebase-hooks/auth";
import { db, auth } from "./firebase";
//Ring, Hypnosis, TwinSpin,
// import CustomBots from "./CustomBots";
import { SpinStretch } from "react-cssfx-loading";
import { Typewriter } from "react-simple-typewriter";
import { Toast } from "react-bootstrap";
import Wallpaper from "./Wallpaper";
import OnlinePop from "./OnlinePop";
function Timer({
  onlyTimer,
  expiryTimestamp,
  setheading,
  allusers,
  user,
  setcustomlink,
  setwallpaperclick,
  wallpaperclick,
  setfocusingclick,
  focusingclick,
  setAtime,
  Atime,
  setbreathevisible,
  setTimerrunning
}) {
  // console.log(navigator.onLine)
  // console.log(allusers);
  // const [user] = useAuthState(auth);
  // const [AllUsers, setAllUsers] = useState(allusers);

  const [userRef, setUserRef] = useState(null);

  // const usersRef = collection(db, "users");
  // const allusersQuery = query(usersRef);
  // ,where("status.studying", "==", true)
  // const [allusers, setallusers] = useState(props.allusers);
  // const [allusers] = useCollectionData(allusersQuery, { idField: "id" });

  // console.log("read");
  const [focuspeople, setfocuspeople] = useState(0);

  const location = useLocation();

  // audiooo
  let radioaudio = new Audio("./audios/radio-click_out.aac");
  radioaudio.playbackRate = 1.5;
  radioaudio.volume = 0.8;
  let timeraudio = new Audio("./audios/tick-tock2_out.aac");
  timeraudio.playbackRate = 2;
  timeraudio.volume = 0.6;
  let pauseaudio = new Audio("./audios/pause_out.aac");
  let resumeaudio = new Audio("./audios/resume_out.aac");
  let finishaudio = new Audio("./audios/finish_out.aac");

  ////////////////

  async function setQuote() {
    //true if anime quote
    // console.log(minutes+"bul");
    // {localStorage.getItem("authToken")&&await fetchUserData();}
    const minutes = todayFocusMinutes,
      epi = epiAllowed;
    if (initialQuotes) {
    } else if (Atime) {
      {
        !localStorage.getItem("authToken")
          ? setquote("Login in for Stats & Rooms") //"You've earned yourself a break! "
          : setquote("Episodes Earned: " + epi);
      }
      setheading("Break !");
    } else {
      const hours = Math.floor(minutes / 60);
      const focminutes = minutes % 60;
      {
        !localStorage.getItem("authToken")
          ? setquote("Login in for Stats & Rooms") //"Turn procastination to productivity"
          : setquote(
              "Focus Time : " + hours + " hours " + focminutes + " minutes"
            );
      }
      setheading("Focus !");
    }
  }

  const handleAnimeCompletion = async () => {
    let userEmail = localStorage.getItem("userEmail");
    let response = await fetch(
      "https://animedoro-backend.vercel.app/api/delanime",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: userEmail,
        }),
      }
    );
  };

  // const handleFocusCompletion = async () => {
  //   const oldDate = new Date();
  //   var curdate = oldDate.toDateString();
  //   let userEmail = localStorage.getItem("userEmail");
  //   let response = await fetch(
  //     "https://animedoro-backend-production.up.railway.app/api/addsession",
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         email: userEmail,
  //         minutes: timerFocusMinutes,
  //         curDate: curdate,
  //       }),
  //     }
  //   );
  // };
  const handleFocusCompletion = async (olddata) => {
    // console.log(olddata);
    const oldDate = new Date();
    var curdate = oldDate.toDateString();
    let userEmail = localStorage.getItem("userEmail");
    let mins = timerFocusMinutes;
    if (olddata) {
      // console.log("old timer", olddata);
      mins = olddata.minutes;
      curdate = olddata.curDate;
    }
    mins=Math.round(mins);
    try {
      // console.log("storing");
      let response = await fetch(
        "https://animedoro-backend.vercel.app/api/addsession",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: userEmail,
            minutes: mins,
            curDate: curdate,
          }),
        }
      );
      if (!response.ok) {
        // console.log("could not send offline");
        // Save session data locally if offline
        const offlineData = {
          type: "focus",
          userEmail: userEmail,
          minutes: mins,
          curDate: curdate,
        };
        localStorage.setItem("offlineData", JSON.stringify(offlineData));
      }
    } catch (error) {
      // Handle other errors
      // console.log("could not send offline");
      // Save session data locally if offline
      const offlineData = {
        type: "focus",
        userEmail: userEmail,
        minutes: timerFocusMinutes,
        curDate: curdate,
      };
      localStorage.setItem("offlineData", JSON.stringify(offlineData));
      console.error("Error during fetch:", error);
    }
  };

  const fetchUserData = async () => {
    // console.log("getting data");
    const oldDate = new Date();
    var curdate = oldDate.toDateString();
    let userEmail = localStorage.getItem("userEmail");
    let response = await fetch(
      "https://animedoro-backend.vercel.app/api/getstats",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: userEmail,
          curDate: curdate,
        }),
      }
    );

    const json = await response.json();
    if (response.status === 200) {
      // console.log(json.epiAllowed);
      settodayFocusMinutes(json.todayFocusMinutes);
      setepiAllowed(json.epiAllowed);
    }
  };
  // const [loading, setloading] = useState(0);
  const [quote, setquote] = useState('"Study hard, binge harder"');
  const [epiAllowed, setepiAllowed] = useState(-1);
  const [todayFocusMinutes, settodayFocusMinutes] = useState(-1);
  const [started, setstarted] = useState(false); //for buttons
  const [initialQuotes, setinitialQuotes] = useState(true);
  const [timerFocusMinutes, settimerFocusMinutes] = useState(40);
  const [timerAnimeMinutes, settimerAnimeMinutes] = useState(20);
  // const [takeStoredOnce, settakeStoredOnce] = useState(false);

  const [showToast, setshowToast] = useState(0);

  useEffect(() => {
    // Check for pending session data on page load
    const pendingSessionData = localStorage.getItem("offlineData");
    if (pendingSessionData) {
      const parsedSessionData = JSON.parse(pendingSessionData);
      // console.log(pendingSessionData);
      if (parsedSessionData.type === "anime") {
        handleAnimeCompletion(parsedSessionData);
      } else if (parsedSessionData.type === "focus") {
        handleFocusCompletion(parsedSessionData);
      }

      // Remove pending session data from local storage
      localStorage.removeItem("offlineData");
    }
  }, []);

  useEffect(() => {
    setQuote();
    if (localStorage.getItem("FocusPeriod")) {
      settimerFocusMinutes(Number(localStorage.getItem("FocusPeriod")));
    }
    if (localStorage.getItem("AnimePeriod")) {
      settimerAnimeMinutes(Number(localStorage.getItem("AnimePeriod")));
    }

    Atime ? Animetime(-1) : Focustime(-1);
  }, [
    epiAllowed,
    todayFocusMinutes,
    Atime,
    timerAnimeMinutes,
    timerFocusMinutes,
    initialQuotes,
  ]);

  const { hours, seconds, minutes, isRunning, start, pause, resume, restart } =
    useTimer({
      expiryTimestamp,
      onExpire: async () => {
        finishaudio.play();
        Atime ? await Focustime(1) : await Animetime(1);
      },
      autoStart: false,
    });
    useEffect(() => {
      setTimerrunning(isRunning)
    }, [isRunning, setTimerrunning]);
  function changeTimer5(add) {
    resumeaudio.play();
    if (add) {
      if (Atime) {
        if (timerAnimeMinutes >= 60) {
          return;
        }
        settimerAnimeMinutes(timerAnimeMinutes + 5);
        localStorage.setItem("AnimePeriod", timerAnimeMinutes + 5);
      } else {
        if (timerFocusMinutes >= 180) {
          return;
        }
        settimerFocusMinutes(timerFocusMinutes + 5);
        localStorage.setItem("FocusPeriod", timerFocusMinutes + 5);
      }
    } else {
      if (Atime) {
        //change all 1 to 5
        if (timerAnimeMinutes <= 5) {
          return;
        }
        settimerAnimeMinutes(timerAnimeMinutes - 5);
        localStorage.setItem("AnimePeriod", timerAnimeMinutes - 5);
      } else {
        if (timerFocusMinutes <= 5) {
          return;
        }
        settimerFocusMinutes(timerFocusMinutes - 5); //todo change
        localStorage.setItem("FocusPeriod", timerFocusMinutes - 5);
      }
    }
    // restart(time, false);
  }

  async function Animetime(completed) {
    if (completed !== -1) {
      setinitialQuotes(false);
    }
    if (localStorage.getItem("authToken")) {
      if (completed === 1) {
        await handleFocusCompletion();
        await fetchUserData();
      }
    }
    const time = new Date();
    time.setSeconds(time.getSeconds() + 60 * timerAnimeMinutes);

    restart(time, false);
    setAtime(true);
    setstarted(false);
  }
  async function Focustime(completed) {
    if (completed !== -1) {
      setinitialQuotes(false);
    }
    if (localStorage.getItem("authToken")) {
      if (completed === 1) {
        await handleAnimeCompletion();
        await fetchUserData();
      }
    }

    const time = new Date();
    time.setSeconds(time.getSeconds() + 60 * timerFocusMinutes); //
    restart(time, false);
    setAtime(false);
    setstarted(false);
  }
  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    // console.log("updating status")
    if (user) {
      const ref = doc(db, "newusers", "allusers");
      setUserRef(ref);
      const updatestatus = async (status) => {
        if (!ref) {
          return;
        }
        await updateDoc(ref, { [user.uid]: status });
      };

      // Set the user's status to online when they log in
      let status;
      if (!isRunning) {
       
        status = {
          online: true,
          studying: false,
          email: user.email,
          name: user.displayName,
          img: user.photoURL,
        };
        updatestatus(status);
      } else if (!Atime && isRunning) {
        status = {
          online: true,
          studying: true,
          email: user.email,
          name: user.displayName,
          img: user.photoURL,
        };
        updatestatus(status);
      }

      // Set the user's status to offline when they close the tab or log out
      const onBeforeUnload = async (event) => {
        event.preventDefault();
        if (ref) {
          const status = {
            online: false,
            studying: false,
            email: user.email,
            name: user.displayName,
            img: user.photoURL,
          };
          await updatestatus(status);
        }
        pause();
      };
      window.addEventListener("beforeunload", onBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", onBeforeUnload);
      };
    }
  }, [isRunning]);
  useEffect(() => {
    // console.log(location.state)
    if (location.state && location.state.starttimer) {
      setautomaticTimer(location.state.starttimer);
      const newState = { ...location.state, starttimer: 0 };

      // Replace the original location state with the modified copy
      window.history.replaceState({ ...location }, '', `${location.pathname}${location.search}`);
      
    }
  }, [location.state]);

  const [automaticTimer, setautomaticTimer] = useState(
    location.state?.starttimer
  );

  useEffect(() => {
    // console.log(allusers)
    if (allusers) {
      var c = 0;
      for (var i = 0; i < allusers.length; i++) {
        if (allusers[i].studying) {
          c++;
        }
      }
      setfocuspeople(c);
    }
  }, [allusers]);
  useEffect(() => {
    const button = document.getElementById("startbtn");

    if (automaticTimer) {
      setshowToast(1);
      setAtime(0);
      const checkButtonInterval = setInterval(() => {
        const button = document.getElementById("startbtn");
        if (button) {
          clearInterval(checkButtonInterval); // Stop checking for the button once it is found
          setTimeout(() => {
            // console.log("button found");
            button.click();
            // timeraudio.play();
          }, 2000);
        }
      }, 1000);
      setautomaticTimer(0);
      // setshowToast(0)
    }
  }, [automaticTimer]);

  return (
    <div style={{ textAlign: "center" }}>
      <AutohideToast setShow={setshowToast} show={showToast} delay={3000} />

      {epiAllowed === -1 && !initialQuotes ? (
        <div style={{ paddingLeft: "46%", paddingBottom: "15px" }}>
          <SpinStretch color="#F5E8C7" />
        </div>
      ) : (
        <div 
        className={` ${onlyTimer ? "hide-toggle" : "open-toggle"}`}
        >
          {initialQuotes ? (
            <p className="text-center quote">
              <Typewriter
                loop
                cursor
                cursorStyle="|"
                typeSpeed={60}
                deleteSpeed={80}
                delaySpeed={1000}
                words={["Focus Hard . . .", "Binge Harder !"]}
                // onLoop={(loopCount) =>
                //   console.log(`Just completed loop ${loopCount}`)
                // }
              />
            </p>
          ) : isRunning && user ? (
            <div className="w-100 flex justify-center items-center">
            <div
              onClick={() => {
                setfocusingclick(1);
              }}
              className="tag-card  "
              style={{
                width: "140px ",
              }}
            >
              <div
                className="tag-dot"
                style={{
                  backgroundColor: Atime ? "red" : "green",
                  width: "9px",
                  height: "9px",
                  marginRight: "10px",
                }}
              ></div>
              <p style={{ fontSize: "13px", fontWeight: "bold" }}>
                {Atime ? "Resting" : "Focusing live"} :{" "}
                {!Atime ? focuspeople : allusers.length - focuspeople}
              </p>
            </div>
            </div>
          ) : (
            <p
              className={`text-center quote ${
                onlyTimer ? "hide-toggle" : "open-toggle"
              }`}
              // className="text-center quote"
            >
              {" "}
              {quote}
            </p>
          )}
        </div>
      )}
      <div
        className={`btn-group mt-3 ${onlyTimer ? "hide-toggle" : "open-toggle"}`}
        // className="btn-group"
        role="group"
        aria-label="Basic radio toggle button group"
      >
        <input
          onClick={async () => {
            radioaudio.play();
            await Focustime(0);
          }}
          type="radio"
          className="btn-check"
          name="btnradio"
          id="btnradio1"
          autoComplete="off"
          checked={!Atime}
          disabled={isRunning}
        />
        <label
          // className=""
          className={`btn btn-outline-secondary radio-left ${
            onlyTimer ? "hide-toggle" : "open-toggle"
          }`}
          htmlFor="btnradio1"
        >
          Focus
        </label>
        <input
          onClick={async () => {
            radioaudio.play();
            await Animetime(0);
          }}
          type="radio"
          className="btn-check"
          name="btnradio"
          id="btnradio3"
          autoComplete="off"
          checked={Atime}
          disabled={isRunning}
        />
        <label
          className={`btn btn-outline-secondary radio-right ${
            onlyTimer ? "hide-toggle" : "open-toggle"
          }`}
          // className="btn btn-outline-secondary radio-right"
          htmlFor="btnradio3"
        >
          Anime
        </label>
      </div>

      <div
        style={{ fontSize: "100px" }}
        className={`clock-font ${onlyTimer ? "enlarged" : ""}`}
        onChange={async () => {
          await Animetime(0);
        }}
      >
        {" "}
        {!started && (
          <span className={`${onlyTimer ? "hide-toggle" : "open-toggle"}`}>
            <button
              onClick={() => {
                changeTimer5(false);
              }}
              // className="btn btn-outline-secondary btn-timer m-3"
              className={`btn btn-outline-secondary btn-timer m-3 ${
                onlyTimer ? "hide-toggle" : "open-toggle"
              }`}
              style={{
                padding: "3px",
                paddingLeft: "6px",
                paddingRight: "6px",
              }}
            >
              -
            </button>
          </span>
        )}
        {/* timer display */}
        <span>
          {hours * 60 + minutes < 10 ? "0" + minutes : hours * 60 + minutes}
        </span>
        :<span>{seconds < 10 ? "0" + seconds : seconds}</span>
        {!started && (
          <span>
            <button
              onClick={() => {
                changeTimer5(true);
              }}
              // className=""
              className={`btn btn-outline-secondary btn-timer m-3 ${
                onlyTimer ? "hide-toggle" : "open-toggle"
              }`}
              style={{
                padding: "3px",
                paddingLeft: "6px",
                paddingRight: "6px",
              }}
            >
              +
            </button>
          </span>
        )}
      </div>

      <p
        className={`m-3 quote ${onlyTimer ? "hide-toggle" : "open-toggle"}`}
        //  className="quote"
      >
        {isRunning ? "Running" : "Paused"}
      </p>
      {!started && (
        <div
          // style={{}}
          className={`mt-3 ${onlyTimer ? "hide-toggle" : "open-toggle"}`}
          style={{ marginLeft: "-10px" }}
        >
          <button
            id="startbtn"
            onClick={async () => {
              Atime ? await Animetime(0) : await Focustime(0);
              timeraudio.play();
              setstarted(true);
              start();
            }}
            className="btn btn-outline-secondary btn-timer d-inline-block"
          >
            {/* todo:start */}
            Start
          </button>
          {/* <div className="d-inline-block ml-2 settingsbutton"
        onClick={()=>{setwallpaperclick(1)}}>
        <SettingsOutlinedIcon fontSize="small"/>
        </div> */}
          {/* <div className="d-inline-block ml-2 settingsbutton"
        onClick={()=>{setbreathevisible(prev=>!prev)}}>
        <AirOutlinedIcon fontSize="small"/>
        </div> */}
        </div>
      )}
      {started && isRunning && (
        <button
          // style={{display:!onlyTimer?"":"none"}}
          className={`btn btn-outline-secondary btn-timer ${
            onlyTimer ? "hide-toggle" : "open-toggle"
          }`}
          // className="btn btn-outline-secondary btn-timer"
          onClick={() => {
            pauseaudio.play();
            pause();
          }}
        >
          Pause
        </button>
      )}
      {started && !isRunning && (
        <button
          // style={{display:!onlyTimer?"":"none"}}
          className={`btn btn-outline-secondary btn-timer ${
            onlyTimer ? "hide-toggle" : "open-toggle"
          }`}
          disabled={minutes === 0 && seconds === 0}
          // className="btn btn-outline-secondary btn-timer"
          onClick={() => {
            resumeaudio.play();
            resume();
          }}
        >
          {minutes === 0 && seconds === 0 ? (
            <span
              className="spinner-border spinner-border-sm "
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            <>Resume</>
          )}
        </button>
      )}
      {Atime && <NetButton onlyTimer={onlyTimer} />}

      {/* Spotifyyyyyyyyyy */}

      {!Atime && <Music onlyTimer={onlyTimer} />}
    </div>
  );
}
// const MemoizedTimer = React.memo(Timer);

// export default MemoizedTimer;

export default Timer;
