import React from "react";

function Showcard({title,description,imagelink}) {
  return (
    <div className="col mb-5  d-flex justify-content-center ">
      <div className="showcard">
        <div className="showcardimg" style={{
        backgroundImage:`url("${imagelink}")`
      }}>
          <div className="showcardbody">
            <div className="roomtitle" style={{fontSize:"30px"}}>{title}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Showcard;
