export const addUserSession = async (sessionMinutes, oldData) => {
  const oldDate = new Date();
  var curdate = oldDate.toDateString();
  let userEmail = localStorage.getItem("userEmail");
  let mins = sessionMinutes;
  if (oldData) {
    mins = oldData.minutes;
    curdate = oldData.curDate;
  }
  mins = Math.round(mins);
  try {
    let response = await fetch(
      "https://animedoro-backend.vercel.app/api/addsession",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: userEmail,
          minutes: mins,
          curDate: curdate,
        }),
      }
    );
    if (!response.ok) {
      const offlineData = {
        type: "focus",
        userEmail: userEmail,
        minutes: mins,
        curDate: curdate,
      };
      localStorage.setItem("offlineData", JSON.stringify(offlineData));
    }
  } catch (error) {
    const offlineData = {
      type: "focus",
      userEmail: userEmail,
      minutes: mins,
      curDate: curdate,
    };
    localStorage.setItem("offlineData", JSON.stringify(offlineData));
    console.error("Error during fetch:", error);
  }
};
