import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import "../components/rooms.css";
import RoomCard from "../components/RoomCard";
import DetailCard from "../components/DetailCard";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import CreateRoom from "../components/CreateRoom";
import JoinedRoom from "../components/JoinedRoom";
import { auth,db } from "../components/firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";
import {
  setDoc,
  doc,
  collection,
  addDoc,
  query,
  updateDoc,
  arrayUnion,
  orderBy,
  limit,
  getDocs,
  where,
} from "firebase/firestore";
import { useNavigate} from "react-router-dom";

const Rooms = () => {
  const navigate = useNavigate();
  if(!localStorage.getItem("authToken")){
    navigate("/signup");
   
  }
  const [joinedmode, setjoinedmode] = useState(true);
  const [clicked, setclicked] = useState(-1);

  const currentUserUid = localStorage.getItem("uid");
  const RoomsRef = collection(db, "Rooms");
  const allRoomsQuery = query(RoomsRef);
  const [allRooms] = useCollectionData(allRoomsQuery, { idField: "id" });
  console.log("read");

  async function addmember(roomname) {
    const roomRef = doc(db, "Rooms", roomname);
    const newMember = {
      name:auth.currentUser.displayName,
      email:auth.currentUser.email,
      uid:auth.currentUser.uid,
      imageurl:auth.currentUser.photoURL
    };
    await updateDoc(roomRef, {
      members: arrayUnion(newMember),
    });
  }

  return (
    <>
      <OverlayTrigger placement="top" overlay={<Tooltip>Create Room</Tooltip>}>
        <div
          className="floating-button"
          onClick={() => {
            setclicked(-2);
          }}
          style={{ filter: `blur(${clicked === -1 ? 0 : 5}px)` }}
        >
          <i className="fas fa-plus"></i>
        </div>
      </OverlayTrigger>
      <Navbar />

      {clicked !== -1 && <div className="blur-container"></div>}
      {clicked !== -1 && clicked !== -2 && joinedmode === 0 && (
        <div className="detailscontainer">
          <div style={{ position: "absolute", zIndex: "15" }}>
            <DetailCard
              setclicked={setclicked}
              roomData={allRooms[clicked]}
              clicked={clicked}
            />
          </div>
        </div>
      )}
      {clicked != -1 && clicked != -2 && joinedmode == 1 && (
        <div className="detailscontainer">
          <div style={{ position: "absolute", zIndex: "15" }}>
            <JoinedRoom
              setclicked={setclicked}
              roomData={allRooms[clicked]}
              clicked={clicked}
            />
          </div>
        </div>
      )}
      {clicked == -2 && (
        <div className="detailscontainer">
          <div style={{ position: "absolute", zIndex: "15" }}>
            <CreateRoom setclicked={setclicked} />
          </div>
        </div>
      )}

      <div
        className="cardcontainer  m-0"
        style={{ filter: `blur(${clicked == -1 ? 0 : 5}px)` }}
      >
        <div className="d-flex align-items-center  headingtoggle">
          <div
            className={`rooms-heading mr-1 ${joinedmode && "headingunderline"}`}
            onClick={() => {
              setjoinedmode(1);
            }}
          >
            Joined Rooms
          </div>
          <div
            className={`rooms-heading mr-1 ${
              !joinedmode && "headingunderline"
            }`}
            onClick={() => {
              setjoinedmode(0);
            }}
          >
            Public Rooms
          </div>
        </div>
        <div className="mb-5" style={{ width: "220px" }}></div>
        <div className="container-fluid">
          <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 ">
            {joinedmode == 0 &&
              allRooms &&
              allRooms.map((roomData, index) => {
                if (
                  roomData.members.some((obj) => obj.uid === currentUserUid)
                ) {
                  return null;
                }
                return (
                  <RoomCard
                    roomData={roomData}
                    key={roomData.roomname}
                    index={index}
                    setclicked={setclicked}
                  />
                );
              })}
            {joinedmode == 1 &&
              allRooms &&
              allRooms.map((roomData, index) => {
                if (
                  roomData.members.some((obj) => obj.uid === currentUserUid)
                ) {
                  
                  return (
                    <RoomCard
                      roomData={roomData}
                      key={roomData.roomname}
                      index={index}
                      setclicked={setclicked}
                    />
                  );
                }
                if (roomData.roomname === "Feedback ✍️") {
                  addmember(roomData.roomname);
                  return (
                    <RoomCard
                      roomData={roomData}
                      key={index}
                      index={index}
                      setclicked={setclicked}
                    />
                  );
                }
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Rooms;
