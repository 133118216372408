import {createContext,useEffect,useState} from "react";
// import {auth} from "../firebase"
import { db, auth } from "../components/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
// import {onAuthStateChanged} from "firebase/auth"
export const AuthContext=createContext()

export const AuthContextProvider =({children})=>{
    const [currentUser, setcurrentUser] = useState({})
    // useEffect(() => {
    //     const unsubs=onAuthStateChanged(auth,(user)=>{
    //         setcurrentUser(user)
    //     })
    //     //if you are listening to a real-time operation them you should use a clean up function 
    //     //otherwise it will lead to memory leaking
    //     return ()=>{
    //         unsubs();
    //     }
    // }, []);
    const [user] = useAuthState(auth);
    return (<AuthContext.Provider value={currentUser}>
        {children}
    </AuthContext.Provider>)

}