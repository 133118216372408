import React, { useEffect, useState } from "react";
import Timer from "./Timer";
import { useRef } from "react";
import Widgetsidebar from "./Widgetsidebar";
import {
  doc,
  setDoc,
  updateDoc,
  collection,
  getDoc,
  where,
  onSnapshot,
  query,
} from "firebase/firestore";
import { WavyBackground } from "./ui/WavyBackground";

import { useCollectionData } from "react-firebase-hooks/firestore";
import { db, auth } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import CustomBots from "./Custbots";
import Draggable from "react-draggable";
import Wallpaper from "./Wallpaper";
import OnlinePop from "./OnlinePop";
import { DragHandleOutlined } from "@mui/icons-material";
import BreathingCircle from "./BreathingCircle";
import TodoList from "./TodoList";
import { Switch } from "./ui/switch";
import Spaces from "./spaces/Spaces";
import InvitePopup from "./ui/invite-popup";
import { useRecoilState } from "recoil";
import { modalNotificationState } from "../recoilState/modalState";
import { spaceState } from "../recoilState/spaceState";
import WatchTogether from "./webrtc/WatchTogether";
function Card(props) {
  const nodeRef = useRef(null);
  // const [hovered, sethovered] = useState(false);
  const [user] = useAuthState(auth);
  const [onlyTimer, setonlyTimer] = useState(0);
  const allUsersDocRef = collection(db, "newusers");
  const allusersQuery = query(allUsersDocRef);
  const [allusrs] = useCollectionData(allusersQuery, { idField: "id" });
  // console.log("read")
  // console.log(allusers);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [aniMatevisible, setAniMatevisible] = useState(0);
  const [heading, setheading] = useState("Animedoro");
  const [focusingclick, setfocusingclick] = useState(0);
  const [wallpaperclick, setwallpaperclick] = useState(0);
  const [Atime, setAtime] = useState(false); //for anime time
  const [breathevisible, setbreathevisible] = useState(0);
  const [listVisible, setlistVisible] = useState(false);
  const [studyTogether, setstudyTogether] = useState(false);
  // const [themeOpen, setthemeOpen] = useState(0);
  const [timerOpen, settimerOpen] = useState(1);
  // const [breatheOpen, setbreatheOpen] = useState(0);
  const time = new Date();
  time.setSeconds(time.getSeconds() + 60 * 40); // 40 minutes timer
  const bounds = { left: -500, top: -30, right: 700, bottom: 100 };
  let allusers = allusrs ? Object.values(allusrs[0]) : null;
  const [space, setSpace] = useRecoilState(spaceState);
  useEffect(() => {
    if(space){
      setstudyTogether(true);
      settimerOpen(false);
      setlistVisible(false);
      setbreathevisible(false);
      setIsSidebarOpen(false);
    }
  }, [space]);
  return (
    <div className="relative  h-full w-full  ">
    
      {focusingclick == 1 && (
        <OnlinePop
          Atime={Atime}
          allusers={allusers}
          setfocusingclick={setfocusingclick}
        />
      )}
      {wallpaperclick == 1 && (
        <Wallpaper
          setcustomlink={props.setcustomlink}
          setwallpaperclick={setwallpaperclick}
        />
      )}
      {(focusingclick == 1 || wallpaperclick == 1) && (
        <div className="blur-container" />
      )}
      {/* <div className="container" style={{width:"100vw",height:"100vh"}}> */}
      <Widgetsidebar
        breathevisible={breathevisible}
        setbreathevisible={setbreathevisible}
        timerOpen={timerOpen}
        settimerOpen={settimerOpen}
        setwallpaperclick={setwallpaperclick}
        wallpaperclick={wallpaperclick}
        handleFullscreenToggle={props.handleFullscreenToggle}
        isFullscreen={props.isFullscreen}
        listVisible={listVisible}
        setlistVisible={setlistVisible}
        studyTogether={studyTogether}
        setstudyTogether={setstudyTogether}
        setIsOpen={setIsSidebarOpen}
        isOpen={isSidebarOpen}
        setAniMatevisible={setAniMatevisible}
        aniMatevisible={aniMatevisible}
      />
      <BreathingCircle breathevisible={breathevisible} />

      <Draggable
        nodeRef={nodeRef}
        handle=".header"
        bounds="parent"
        defaultPosition={{ x: 10, y: 10 }}
      >
        {/* <div  className="relative"> */}
        <div
          className=" card infocard absolute"
          ref={nodeRef}
          style={{ visibility: timerOpen ? "visible" : "hidden" }}
        >
          <div
            onClick={() => {
              setonlyTimer((t) => !t);
            }}
            className="minimax"
          >
            {onlyTimer ? (
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 10L21 3M21 3H15M21 3V9M10 14L3 21M3 21H9M3 21L3 15"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            ) : (
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 14H10M10 14V20M10 14L3 21M20 10H14M14 10V4M14 10L21 3"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            )}
          </div> 
          <div className="header d-flex  justify-content-center align-items-center">
            {/* <DragHandleOutlined className="grabicon" fontSize='large' /> */}
            <div className="grabbar" style={{ marginTop: "-25px" }}></div>

            {/* <BackHandOutlinedIcon /> */}
          </div>
          <div
            className={`container  h-100 card-body ${onlyTimer ? "nopad" : ""}`}
            style={{ paddingBottom: "0px" }}

            // style={{}}
          >
            <h1
              className={` headingtitle ${
                onlyTimer ? "hide-toggle" : "open-toggle"
              }`}
            >
              {heading}{" "}
            </h1>
            {user && !user.photoURL && <CustomBots user={user} />}
            <Timer
              setTimerrunning={props.setTimerrunning}
              onlyTimer={onlyTimer}
              setonlyTimer={setonlyTimer}
              expiryTimestamp={time}
              setheading={setheading}
              allusers={allusers}
              user={user}
              setcustomlink={props.setcustomlink}
              setwallpaperclick={setwallpaperclick}
              wallpaperclick={wallpaperclick}
              setfocusingclick={setfocusingclick}
              focusingclick={focusingclick}
              Atime={Atime}
              setAtime={setAtime}
              setbreathevisible={setbreathevisible}
            />
          </div>
        </div>
        {/* </div> */}
      </Draggable>
      <TodoList listVisible={listVisible} />
      <Spaces studyTogether={studyTogether}/>
      <WatchTogether setAniMatevisible={setAniMatevisible} aniMatevisible={aniMatevisible}/>
      {/* </div> */}
    </div>
  );
}
export default Card;
