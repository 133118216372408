import React from "react";
import Tags from "./Tags";

const Rules = (props) => {
  return (
    <div style={{height:"100%"}}>
      <div className="top-bar  ">
        <div className="joined-heading mt-2">Description</div>
      </div>

      <div className="seperaterline mt-2" style={{ width: "100%" }}></div>
      <div className="detailsheader ">
        <p className="detailstitle" style={{fontSize:"30px"}}>{props.roomData.roomname}</p>
        {/* <div className="seperaterline"></div> */}
        <div className="m-2">
          <Tags tag1={props.roomData.tag1} tag2={props.roomData.tag2} />
        </div>
        <div style={{ paddingBottom: "25px", paddingTop: "20px" }}>

          <div className="descbody" style={{ width: "100%" }}>
            <div className="roomdesc" style={{ fontSize: "12px" }}>
              Target Time: {props.roomData.target_time} hrs/week
            </div>
            <div className="roomdesc" style={{ fontSize: "12px" }}>
              Members: {props.roomData.members.length}
            </div>
          </div>
          <div className="descbody" style={{ width: "295px" }}>
            <div className="roomdesc" style={{ fontSize: "12px" }}>
              <em> Admin: </em>
            </div>
            <div className="roomdesc" style={{ fontSize: "12px" }}>
              <em> {props.roomData.admin_email}</em>
            </div>
          </div>
        </div>
        <div className="detailstitle" style={{fontSize:"26px",marginBottom:"0px"}}>Rules</div>

        {/* <ProfileScroll members={props.roomData.members}/> */}
        <div className="detailsdesc rulesdesc p-2">{props.roomData.description}</div>
      </div>
    </div>
  );
};

export default Rules;
