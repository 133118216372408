import React, { useState ,useEffect} from "react";
import Navbar from "../components/Navbar";
import { Typewriter } from "react-simple-typewriter";
import { useNavigate } from "react-router-dom";
import { Google } from "@mui/icons-material";

import firebase from "firebase/compat/app";
import 'firebase/compat/firestore';
import 'firebase/compat/auth';



const SignUp = () => {
  let navigate=useNavigate();
  const [credentials, setcredentials] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [submitload, setsubmitload] = useState(false);



  const handleGoogleSignIn = () => {
    console.log("Inside Google");
    setsubmitload(true);
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithPopup(provider)
      .then((result) => {
        // Signed in with Google
        const user = result.user;
        localStorage.setItem("userEmail",user.email);
        localStorage.setItem("uid",user.uid);
        user.getIdToken().then(token => {
          localStorage.setItem('authToken', token);
          navigate("/");
        });
    localStorage.setItem("userName",user.displayName);
    handleCallback(user.displayName,user.email);
      })
      .catch((error) => {
        alert("Error Signing in with Google");
        console.error('Error signing in with Google:', error);
      });
      setsubmitload(false);
      
  };

  
  const handleSignUp = (event) => {
    event.preventDefault();
    setsubmitload(true);
    const displayName=credentials.name;
    firebase.auth().createUserWithEmailAndPassword(credentials.email, credentials.password)
      .then(async(userCredential) => {
        // Handle successful sign up here
        const user = userCredential.user;
        await user.updateProfile({
          displayName: credentials.name
        })
        console.log('Sign up successful!', user);
        localStorage.setItem("userEmail",user.email);
        localStorage.setItem("uid",user.uid);
        user.getIdToken().then(token => {
          localStorage.setItem('authToken', token);
        });
    localStorage.setItem("userName",credentials.name);
    console.log("Created");
    handleCallback(displayName,user.email);
        
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error('Sign up failed:', errorCode, errorMessage);
        alert("Sign up Failed : "+errorMessage);
      });
      setsubmitload(false);
  };
//////////////////////////up firebase
//1)
const handleCallback=async (name,email) =>{
  setsubmitload(true);
  const response1 = await fetch("https://animedoro-backend.vercel.app/api/googleentry", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({name:name,email:email})
    });
    const json =await response1.json()
    if(!json.success){
      alert("Sign in Failed");
    }
    else{
    navigate("/");
  }
    setsubmitload(false);
}
// useEffect(() => {
//   /*global google*/
//   google.accounts.id.initialize({
//    client_id:"718156679991-vlktjgr7mkbpgqpu8iml8mnvjhguj2gt.apps.googleusercontent.com" ,
//    callback: handleCallback
//   });
//   google.accounts.id.renderButton(
//     document.getElementById("signInDiv"),
//     {theme:"outline",size:"large",theme:"dark"}
//   );
// }, []);

  

  // const handleSubmit = async (e) => {
  //   setsubmitload(true);
  //   e.preventDefault();
  //   const response = await fetch("https://animedoro-backend-production.up.railway.app/api/createuser", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({name:credentials.name,email:credentials.email,password:credentials.password})
  //   });
  //   const json =await response.json()
  //   // console.log()
  //   if(!json.success){
  //     if(json.errors){ alert(json.errors[0].msg);}
  //     if(json["msg"]){alert(json["msg"]);}
  //     // else{alert("Enter valid credentials!");}
  //   }
  //   else{
  //   localStorage.setItem("userEmail",credentials.email);
  //   localStorage.setItem("authToken",json.authToken);
  //   localStorage.setItem("userName",credentials.name);
  //   navigate("/");}
  //   setsubmitload(false);
  // };

  const onChange=(event)=>{
    setcredentials({...credentials,[event.target.name]:event.target.value})
  }

  return (
    <div className="login">
    
      <Navbar />
      <div style={{}} className="container-fluid col-11 col-sm-6 col-md-10">
        <div className="row">
          <div className="card infocard">
            <div
              style={{
                paddingBottom: "0px",
                marginLeft: "8px",
                marginRight: "0px",
              }}
              className="container h-100 card-body"
            >
              <h1 className="text-center headingtitle mt-3">Animedoro </h1>

              <p class="text-center quote" style={{ margin: "0px" }}>
                <Typewriter
                  loop
                  cursor
                  cursorStyle="|"
                  typeSpeed={60}
                  deleteSpeed={80}
                  delaySpeed={1000}
                  words={["Focus", "Watch Anime", "Repeat!"]}
                  // onLoop={(loopCount) =>
                  //   console.log(`Just completed loop ${loopCount}`)
                  // }
                />
              </p>
              {/* formm startsss */}
              <form style={{ margin: "0px" }} >
                <div className="form-group " style={{ marginLeft: "35px" }}>
                  <label htmlFor="name">Name : </label>
                  <br />
                  <input
                  onChange={onChange}
                    name="name"
                    value={credentials.name}
                    type="text"
                    className="form-control-sm"
                    id="name"
                  />
                </div>
                <div className="form-group " style={{ marginLeft: "35px" }}>
                  <label htmlFor="email">Email : </label>
                  <br />
                  <input
                    name="email"
                    onChange={onChange}
                    value={credentials.email}
                    type="email"
                    className="form-control-sm"
                    id="email"
                    aria-describedby="emailHelp"
                  />
                </div>
                <div className="form-group" style={{ marginLeft: "35px" }}>
                  <label htmlFor="password">Password :</label>
                  <br />
                  <input
                  onChange={onChange}
                    name="password"
                    value={credentials.password}
                    type="password"
                    className="form-control-sm"
                    id="password"
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-outline-secondary btn-timer"
                  style={{ marginLeft: "115px", marginBottom: "20px" }}
                  disabled={submitload}
                  onClick={handleSignUp}
                >
                {submitload&&<span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>}
                  Sign Up
                </button>
                {/* <button type="submit" className="btn btn-outline-secondary btn-timer"
              style={{marginBottom:"20px"}}
            >
                Sign in
              </button> */}
              </form>

              <div
                className="col-md-0"
                
              >
                <br />
                {/* <div id="signInDiv"></div> */}
                <a
                
                  className="btn btn-outline-secondary btn-timer "
                  // href="/users/googleauth"
                  role="button"
                  style={{
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                  textTransform: "none",
                  width:"300px"
                }}
                  // style={{  }}
                  onClick={handleGoogleSignIn}
                >
                  <img
                  className="sign-in"
                    width="20px"
                    style={{ marginBottom: "3px", marginRight: "8px" }}
                    alt="Google sign-in"
                    src="https://user-images.githubusercontent.com/700503/58114683-e12ffd00-7c2a-11e9-8d40-d7dc14342950.jpg"
                  />
                 <div>Continue with Google</div> 
                </a>
              </div>
              {/* <button onClick={handleSignOut}>signout</button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;

