import React, { useEffect, useState } from "react";
import { Dropdown, DropdownButton, Badge } from "react-bootstrap";
import { SpinStretch } from "react-cssfx-loading";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { db, auth } from "./firebase";
import {
  doc,
  getDoc,
  query,
  updateDoc,
  serverTimestamp,
  collection,
  where,
  Timestamp,
  arrayRemove,
} from "firebase/firestore";
import { DockRounded } from "@mui/icons-material";
import firebase from "firebase/compat/app";
import { useNavigate } from "react-router-dom";
import { withRouter } from "react-router-dom";
import InvitePopup from "./ui/invite-popup";
import { AlertDialog } from "./ui/alert-dialog";
import { useRecoilState, useSetRecoilState } from "recoil";
import { modalAnimateNotificationState, modalAnimateState, modalNotificationState, modalState } from "../recoilState/modalState";
import { spaceState } from "../recoilState/spaceState";

function Notifications() {
  const [isOpen,setIsOpen] = useRecoilState(modalState);
  const setIsAnimateOpen=useSetRecoilState(modalAnimateState);
  // { admin_name, focus_time,spaceId  }
  const navigate = useNavigate();
  // const [space, setSpace] = useRecoilState(spaceState);

  const [user] = useAuthState(auth);
  const userRef = user ? doc(db, "users", user.uid) : null;
  const [notification, setNotification] = useRecoilState(
    modalNotificationState
  );
  const setAnimateNotification = useSetRecoilState(
    modalAnimateNotificationState
  );
  const currentTime = new Date().getTime();
  const fiveMinutesAgo = currentTime - 10 * 60 * 1000;
  const queryRef = query(userRef);

  const [roomreqarray] = useDocumentData(queryRef, { idField: "id" });
  useEffect(() => {
    if (
      roomreqarray &&
      roomreqarray.room_notifications &&
      roomreqarray.room_notifications.length
    ) {

      //there are two types of notifications now,one is for space another is for animate
      // if it is animate notification we will perform different operations accordingly
      if(roomreqarray.room_notifications[0].callId){
        const { admin_name, callId } = roomreqarray.room_notifications[0];
        setAnimateNotification({
          createdBy: admin_name,
          callId
        });
        setIsAnimateOpen(true);
      }
      else{
      const { admin_name, focus_time, spaceId } = roomreqarray.room_notifications[0];
      setNotification({
        createdBy: admin_name,
        timer: { duration: focus_time },
        spaceId,
      });
      setIsOpen(true);
    }
  }
  }, [roomreqarray, setIsOpen, setNotification]);

  // function handleacceptance(noti) {
  //   deleteNotification(noti);
  //   localStorage.setItem("FocusPeriod", noti.focus_time);
  //   navigate("/", { state: { starttimer: true } });
  // }
  // function handledenial(noti) {
  //   deleteNotification(noti);
  // }
  // const deleteNotification = async (notificationId) => {
  //   try {
  //     await updateDoc(userRef, {
  //       room_notifications:
  //         firebase.firestore.FieldValue.arrayRemove(notificationId),
  //     });
  //     console.log("Notification deleted successfully.");
  //   } catch (error) {
  //     console.error("Error deleting notification:", error);
  //   }
  // };

  const roomnotifications = (
    <Popover id="popover-basic">
      <Popover.Header
        style={{ backgroundColor: "black", borderBottomColor: "grey" }}
        as="h3"
      >
        Room Requests :
      </Popover.Header>
      {/* <Popover.Body
        style={{
          backgroundColor: "black",
          color: "white",
          width: "250px",
          borderBottomLeftRadius: "3px",
          borderBottomRightRadius: "3px",
        }}
      >
        {user &&
          roomreqarray &&
          roomreqarray.room_notifications &&
          roomreqarray.room_notifications.map((noti) => {
            const { admin_name, focus_time, room_name, timestamp } = noti;

            if (timestamp.toMillis() > fiveMinutesAgo) {
              return (
                <div className="room-notification" key={timestamp}>
                  <p>
                    <strong>{admin_name} </strong> from{" "}
                    <strong>{room_name}</strong> is asking you for a{" "}
                    <strong>{focus_time}</strong> minutes study together
                    session.
                  </p>
                  <div className="room-notification-footer">
                    <button
                      className="acceptroom btn btn-outline"
                      onClick={() => {
                        handleacceptance(noti);
                      }}
                    >
                      Accept
                    </button>
                    <button
                      onClick={() => {
                        handledenial(noti);
                      }}
                      className="rejectroom btn btn-outline"
                    >
                      Deny
                    </button>
                  </div>
                </div>
              );
            } else {
              deleteNotification(noti);
              return null;
            }
          })}
      </Popover.Body> */}
    </Popover>
  );

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      overlay={roomnotifications}
      rootClose
    >
      <button className="btn btn-outline-secondary nav-butt ">
        {roomreqarray && roomreqarray.room_notifications.length != 0 && (
          <div className="noti-active">
            {roomreqarray.room_notifications.length}
          </div>
        )}
        <NotificationsNoneOutlinedIcon />
      </button>
    </OverlayTrigger>
  );
}

export default Notifications;

// async function sendNotification(recipient) {
//   // console.log(recipient.name)
//   const ts=firebase.firestore.Timestamp.now();
//   const notification={
//       focus_time:value,
//       admin_name:auth.currentUser.displayName,
//       room_name:props.roomData.roomname,
//       timestamp:ts
//   }
//   // Check if the recipient user exists in the users collection
//   const userRef = doc(db, 'users', recipient.uid);
//   const userSnapshot = await getDoc(userRef);

//   if (!userSnapshot.exists()) {
//     // User doesn't exist, add them to the users collection
//     await setDoc(userRef, {
//       status: {
//         online: false,
//         studying: false,
//         email: recipient.email,
//         name: recipient.name,
//         img: recipient.imageurl,
//       },
//       room_notifications: [],
//     });
//   }

//   // Retrieve the user document
//   const userDoc = userSnapshot.data();
//   // console.log(userDoc);
//   // Check if the notificationsArray exists, initialize if it doesn't
//   if (!userDoc.room_notifications) {
//     await updateDoc(userRef, {
//       room_notifications: [],
//     });
//   }

//   // Add the notification to the user's notificationsArray
//   await updateDoc(userRef, {
//     room_notifications: arrayUnion(notification),
//   },{merge:true});

//   console.log('Notification sent successfully!');
// }
