import { realtimeDb,firebase } from '../components/firebase';
import { useRecoilState } from 'recoil';
import { spaceState } from '../recoilState/spaceState';



export const joinSpace = async (spaceId, user) => {
  try {
    const spaceRef = realtimeDb.ref(`spaces/${spaceId}`);
    const joinedRef = spaceRef.child('joined');
    
    const snapshot = await joinedRef.once('value');
    const members = snapshot.val() || [];

    if (!members.some(member => member.id === user.id)) {
      members.push(user);
      await joinedRef.set(members);
      console.log('User added to space');
    } else {
      console.log('User already a member of the space');
    }

    // Retrieve the updated space data
    const updatedSpaceSnapshot = await spaceRef.once('value');
    const updatedSpace = updatedSpaceSnapshot.val();

    return updatedSpace;

  } catch (error) {
    console.error('Error joining space:', error);
    throw error; // Re-throw the error so the caller can handle it if needed
  }
};

  
  // Usage example
//   joinSpace('spaceId123', 'user456');
  