import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import { SpinStretch } from "react-cssfx-loading";
import UserContext from "../UserContext";
import Notifications from "./Notifications";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { HouseOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
function Navbar(props) {
  const navigate = useNavigate();
  const [friendreqarray, setfriendreqarray] = useState([]);
  // console.log(friendreqarray)
  const { handleSignOut } = useContext(UserContext);
  const handlelogout = () => {
    handleSignOut();
    localStorage.removeItem("authToken");
    localStorage.removeItem("userEmail");
    navigate("/login");
  };
  async function handleacceptance(friendmail) {
    let useremail = localStorage.getItem("userEmail");
    friendmail = friendmail.email;
    // console.log(friendmail);
    let response = await fetch(
      "https://animedoro-backend.vercel.app/api/acceptrequest",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          useremail: useremail,
          friendmail: friendmail,
        }),
      }
    ).then();
    fetchUserData();
  }
  const fetchUserData = async () => {
    console.log("getting data---");
    const oldDate = new Date();
    var curdate = oldDate.toDateString();
    let userEmail = localStorage.getItem("userEmail") || "example@gmail.com";
    let response = await fetch(
      "https://animedoro-backend.vercel.app/api/getstats",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: userEmail,
          curDate: curdate,
        }),
      }
    );
    // console.log("hang");
    const json = await response.json();
    // console.log(json);
    if (response.status === 200) {
      // console.log(json.epiAllowed);
      // console.log("inside")
      let newarr = [];
      // console.log(json.userFriendRequests);
      for (var i = 0; i < json.userFriendRequests.length; i++) {
        newarr.push({
          name: json.reqnames[i],
          email: json.userFriendRequests[i],
        });
      }

      setfriendreqarray(newarr);
      if (newarr.length === 0) {
        setfriendreqarray([{ name: "", email: "" }]);
      }
      // console.log(newarr);
    }
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header
        style={{ backgroundColor: "black", borderBottomColor: "grey" }}
        as="h3"
      >
        Account
      </Popover.Header>
      <Popover.Body
        style={{
          backgroundColor: "black",
          color: "white",
          borderBottomLeftRadius: "3px",
          borderBottomRightRadius: "3px",
        }}
      >
        <em>Name : </em> <strong>{localStorage.getItem("userName")}</strong>
        <br />
        <em> Signed in</em> :{" "}
        <strong>{localStorage.getItem("userEmail")}</strong>
        {/* <hr style={{backgroundColor:"white",height:"0.7px",borderTop:""}}/> */}
        {/* <br /> */}
        <div className="mt-3" style={{ marginLeft: "-6px" }}>
          <button
            type="submit"
            className="btn btn-outline-secondary nav-butt "
            onClick={handlelogout}
          >
            <Link className="nav-link " to="/login">
              Logout
            </Link>
          </button>
        </div>
      </Popover.Body>
    </Popover>
  );

  const notificationpop = (
    <Popover id="popover-basic">
      <Popover.Header
        style={{ backgroundColor: "black", borderBottomColor: "grey" }}
        as="h3"
      >
        Friend Requests :
      </Popover.Header>
      <Popover.Body
        style={{
          backgroundColor: "black",
          color: "white",
          width: "250px",
          borderBottomLeftRadius: "3px",
          borderBottomRightRadius: "3px",
        }}
      >
        {friendreqarray.length === 0 ? (
          <SpinStretch color="#F5E8C7" />
        ) : (
          friendreqarray.map(({ name, email }) => (
            <div key={email}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    fontSize: "20px",
                    marginBottom: "5px",
                    marginTop: "9px",
                  }}
                >
                  {name.length === 0 ? "No Requests" : name}
                </p>
                {/* <SpinStretch></SpinStretch> */}
                {name.length !== 0 && (
                  <button
                    type="submit"
                    className="btn btn-outline-secondary nav-butt btn-sm"
                    onClick={() => {
                      handleacceptance({ email });
                      setfriendreqarray([]);
                    }}
                    style={{ fontSize: "12px", alignSelf: "flex-end" }}
                  >
                    <strong>+</strong>
                  </button>
                )}
              </div>
              {/* <em>Email: </em> */}
              {email}
            </div>
          ))
        )}
        {/* implement a function getname from mailid */}
      </Popover.Body>
    </Popover>
  );
  useEffect(() => {
    fetchUserData();
  }, []);
  return (
    <nav
      style={{ width: "100%" }}
      className={`navbar navbar-expand-md navbar-expand-lg navbar-dark bg-body-tertiary z-[15] ${
             props.timerrunning? "hide-nav" : "open-nav"
          }`}
    >
      <div className="container-fluid ">
        <Link className="navbar-brand" to="/">
          <img
            src="./images/Otakufy3.png"
            width="100"
            height="29"
            className="d-inline-block align-top"
            alt="Otakufy"
          />
        </Link>

        {/* home */}

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasDarkNavbar"
          aria-controls="offcanvasDarkNavbar"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="offcanvas offcanvas-start  bgblack"
          tabIndex="-1"
          id="offcanvasDarkNavbar"
          aria-labelledby="offcanvasDarkNavbarLabel"
          data-bs-scroll="true"
        >
          <div className="offcanvas-header">
            <Link className="navbar-brand" to="/">
              <img
                src="./images/Otakufy3.png"
                width="100"
                height="29"
                className="d-inline-block align-top"
                alt="Otakufy"
                style={{minWidth:"100px"}}
              />
            </Link>
            <div style={{ marginLeft: "45%" }}>
              {/* <OverlayTrigger
                trigger="click"
                placement="bottom"
                overlay={popover}
                rootClose
              >
                <button className="btn btn-outline-secondary nav-butt ">
                  <strong>
                    {localStorage.getItem("userName")
                      ? localStorage.getItem("userName")[0].toUpperCase()
                      : "N"}
                  </strong>
                </button>
              </OverlayTrigger> */}
            </div>
            {/* crosss button */}
            {/* <button type="button" class="" data-bs-dismiss="offcanvas" aria-label="Close"
      style={{}}>X</button> */}
          </div>
          <div className="offcanvas-body ">
            {/* <div className="collapse navbar-collapse" id="navbarNav"> */}
            <div className="m-2 ml-3 mr-3 font-mod">
              <Link className="nav-item nav-link navbar-nav" to="/">
                {/* Home */}
                <div className="navbox">
                <svg
                // style={{marginLeft:"12px",marginTop:"5px"}}
                  width="22px"
                  height="22px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 16C13.6569 16 15 14.6568 15 13C15 11.3431 13.6569 9.99998 12 9.99998C10.3431 9.99998 9 11.3431 9 13C9 14.6568 10.3431 16 12 16Z"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3 10.5651C3 9.9907 3 9.70352 3.07403 9.43905C3.1396 9.20478 3.24737 8.98444 3.39203 8.78886C3.55534 8.56806 3.78202 8.39175 4.23539 8.03912L11.0177 2.764C11.369 2.49075 11.5447 2.35412 11.7387 2.3016C11.9098 2.25526 12.0902 2.25526 12.2613 2.3016C12.4553 2.35412 12.631 2.49075 12.9823 2.764L19.7646 8.03913C20.218 8.39175 20.4447 8.56806 20.608 8.78886C20.7526 8.98444 20.8604 9.20478 20.926 9.43905C21 9.70352 21 9.9907 21 10.5651V17.8C21 18.9201 21 19.4801 20.782 19.908C20.5903 20.2843 20.2843 20.5903 19.908 20.782C19.4802 21 18.9201 21 17.8 21H6.2C5.07989 21 4.51984 21 4.09202 20.782C3.71569 20.5903 3.40973 20.2843 3.21799 19.908C3 19.4801 3 18.9201 3 17.8V10.5651Z"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div >Home</div>
                </div>
              </Link>
            </div>
            {/* {localStorage.getItem("authToken") && ( */}
            <div className="m-2 ml-3 mr-3">
              <Link className="nav-link nav-item font-mod" to="/stats">
                {/* Stats{" "} */}
                <div className="navbox">
                <svg
                // style={{marginLeft:"12px",marginTop:"5px"}}
                  width="22px"
                  height="22px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 21H6.2C5.07989 21 4.51984 21 4.09202 20.782C3.71569 20.5903 3.40973 20.2843 3.21799 19.908C3 19.4802 3 18.9201 3 17.8V3M7 10.5V17.5M11.5 5.5V17.5M16 10.5V17.5M20.5 5.5V17.5"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div>Stats</div>
                </div>
              </Link>
            </div>
            
              <div className="m-2 ml-3 mr-3 font-mod">
                <Link className="nav-link nav-item " to="/rooms">
                
                  <div className="navbox">
                  <svg
                  // style={{marginLeft:"12px",marginTop:"5px"}}
                    width="22px"
                    height="22px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20.5 7.27783L12 12.0001M12 12.0001L3.49997 7.27783M12 12.0001L12 21.5001M21 16.0586V7.94153C21 7.59889 21 7.42757 20.9495 7.27477C20.9049 7.13959 20.8318 7.01551 20.7354 6.91082C20.6263 6.79248 20.4766 6.70928 20.177 6.54288L12.777 2.43177C12.4934 2.27421 12.3516 2.19543 12.2015 2.16454C12.0685 2.13721 11.9315 2.13721 11.7986 2.16454C11.6484 2.19543 11.5066 2.27421 11.223 2.43177L3.82297 6.54288C3.52345 6.70928 3.37369 6.79248 3.26463 6.91082C3.16816 7.01551 3.09515 7.13959 3.05048 7.27477C3 7.42757 3 7.59889 3 7.94153V16.0586C3 16.4013 3 16.5726 3.05048 16.7254C3.09515 16.8606 3.16816 16.9847 3.26463 17.0893C3.37369 17.2077 3.52345 17.2909 3.82297 17.4573L11.223 21.5684C11.5066 21.726 11.6484 21.8047 11.7986 21.8356C11.9315 21.863 12.0685 21.863 12.2015 21.8356C12.3516 21.8047 12.4934 21.726 12.777 21.5684L20.177 17.4573C20.4766 17.2909 20.6263 17.2077 20.7354 17.0893C20.8318 16.9847 20.9049 16.8606 20.9495 16.7254C21 16.5726 21 16.4013 21 16.0586Z"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div>Rooms</div>
                  </div>
                  {/* </div> */}
                </Link>
                </div>
                <div className="m-2 ml-3 mr-3 font-mod">
                <Link className="nav-link nav-item " to="/about">
                  
                  <div className="navbox">
                    <svg
                      width="22px"
                      height="22px"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 20H5.2C4.07989 20 3.51984 20 3.09202 19.782C2.71569 19.5903 2.40973 19.2843 2.21799 18.908C2 18.4802 2 17.9201 2 16.8V7.2C2 6.07989 2 5.51984 2.21799 5.09202C2.40973 4.71569 2.71569 4.40973 3.09202 4.21799C3.51984 4 4.07989 4 5.2 4H5.6C7.84021 4 8.96031 4 9.81596 4.43597C10.5686 4.81947 11.1805 5.43139 11.564 6.18404C12 7.03968 12 8.15979 12 10.4M12 20V10.4M12 20H18.8C19.9201 20 20.4802 20 20.908 19.782C21.2843 19.5903 21.5903 19.2843 21.782 18.908C22 18.4802 22 17.9201 22 16.8V7.2C22 6.07989 22 5.51984 21.782 5.09202C21.5903 4.71569 21.2843 4.40973 20.908 4.21799C20.4802 4 19.9201 4 18.8 4H18.4C16.1598 4 15.0397 4 14.184 4.43597C13.4314 4.81947 12.8195 5.43139 12.436 6.18404C12 7.03968 12 8.15979 12 10.4"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    <div>About</div>
                  </div>
                  {/* </div> */}
                </Link>
              </div>
              
              
            

            {/* rightitems */}
            {!localStorage.getItem("authToken") ? (
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <button
                    className="btn btn-outline-secondary nav-butt"
                    style={{ marginRight: "8px" }}
                  >
                    <Link className="nav-link" to="/login">
                      Log In
                    </Link>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    type="submit"
                    className="btn btn-outline-secondary nav-butt"
                  >
                    <Link className="nav-link" to="/signup">
                      Sign up
                    </Link>
                  </button>
                </li>
              </ul>
            ) : (
              <ul className="navbar-nav ml-auto">
                <li
                  className="nav-item"
                  style={{ marginTop: "2px", marginLeft: "11px" }}
                >
                  <Notifications />
                </li>
                <li
                  className="nav-item"
                  style={{
                    marginTop: "2px",
                    marginLeft: "12px",
                    marginRight: "-5px",
                    marginBottom: "5px",
                  }}
                >
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    overlay={notificationpop}
                    rootClose
                  >
                    <button className={`btn btn-outline-secondary nav-butt `}>
                   {friendreqarray.length!=0&&friendreqarray[0].name&& <div className="noti-active">{friendreqarray.length}</div>}
                      <GroupAddOutlinedIcon />
                    </button>
                  </OverlayTrigger>
                </li>
                <li
                  className="nav-item"
                  style={{
                    marginTop: "2px",
                    marginLeft: "15px",
                    marginRight: "-30px",
                    marginBottom: "0px",
                  }}
                >
                  <OverlayTrigger
                    trigger="click"
                    placement="bottom"
                    overlay={popover}
                    rootClose
                  >
                    <button
                      className="btn btn-outline-secondary nav-butt "
                      style={{ width: "45px" }}
                    >
                      <strong>
                        {localStorage.getItem("userName")
                          ? localStorage.getItem("userName")[0].toUpperCase()
                          : "N"}
                      </strong>
                    </button>
                  </OverlayTrigger>
                </li>
                <li
                  className="nav-item"
                  style={{
                    marginLeft: "15px",
                    marginRight: "10px",
                    marginBottom: "5px",
                  }}
                ></li>

                {/* this is for notification/friend requests */}
              </ul>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}
export default Navbar;
