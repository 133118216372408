// export function onlyFriends(data,userfriends) {
//     for(var i=0;i<data.length;i++)
//       {
//         if(userfriends.includes(data[i].useremail)){
//           newdata.push(data[i]);
//         }
//       }
//       let newarr=json.userFriends;
//         newarr.push(userEmail);
//         setuserfriends(json.userFriends);
//   }

// export const fetchLeaderboardData = async () => {
//     const oldDate = new Date();
//     var curdate = oldDate.toDateString();

//     let response = await fetch(
//       "https://animedoro-backend-production.up.railway.app/api/leaderboard",
//       {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify({
//           curDate: curdate,
//         }),
//       }
//     );
//     const json = await response.json();
//     if (response.status === 200) {
//       return json["allUsers"];
//     }
//   };

  export const fetchUserData = async () => {
    const oldDate = new Date();
    var curdate = oldDate.toDateString();
    let userEmail = localStorage.getItem("userEmail")||"example@gmail.com";

    let response = await fetch(
      "https://animedoro-backend.vercel.app/api/getstats",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: userEmail,
          curDate: curdate,
        }),
      }
    );
    const json = await response.json();
    if (response.status === 200) {
      return json;
      // newarr.push(userEmail);
      // return newarr;
      // setuserfriends(json.userFriends);
      // console.log(newarr);
    }
  };

  function comparebytoday(a, b) {
    if (a.todayminutes > b.todayminutes) return -1;
    if (a.todayminutes > b.todayminutes) return 1;
    return 0;
  }
  function comparebytotal(a, b) {
    if (a.totalminutes > b.totalminutes) return -1;
    if (a.totalminutes < b.totalminutes) return 1;
    return 0;
  }