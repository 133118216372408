import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "./alert-dialog";
import React, { useState } from "react";
import { Button } from "./button";
import { useRecoilState } from "recoil";
import {
  modalNotificationState,
  modalState,
} from "../../recoilState/modalState";
import { deleteNotification } from "../../actions/delete-notification";
import { joinSpace } from "../../actions/join-space";
import { spaceState } from "../../recoilState/spaceState";
import { useToast } from "./use-toast";

const InvitePopup = ({ user }) => {
  const { toast } = useToast();
  const [notification, setNotification] = useRecoilState(
    modalNotificationState
  );
  const [isOpen, setIsOpen] = useRecoilState(modalState);
  const [space, setSpace] = useRecoilState(spaceState);
  // const spaceId = space.spaceId;
  if (!notification) {
    return null;
  }
  
  async function acceptInvite() {
    await deleteNotification(user);
    const userData = {
      name: user.displayName,
      email: user.email,
      imageurl: user.photoURL,
      id: user.uid,
    };
    // console.log(notification);
    const newSpace = await joinSpace(notification.spaceId, userData);
    // console.log(newSpace);
    if (newSpace) {
      setSpace({ ...newSpace, isAdmin: false });
      toast({
        title: "Accepted Invite",
        description: "Invite Accepted. Joined the Space.",
      });
    }
    else{
      toast({
        title: "Error",
        description: "Could not join the space.",
        variant: "destructive",
      });
    }
  }

  return (
    <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
      <AlertDialogContent className="dark  !border-[#363131]">
        <AlertDialogHeader>
          <AlertDialogTitle className="text-3xl">
            Space Invitation
          </AlertDialogTitle>
          <AlertDialogDescription className="!text-base not-italic ">
            <span className="font-bold">{notification.createdBy}</span> invited you for
            a <span className="font-bold">{notification.timer.duration} minutes</span>{" "}
            focus session in their space
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel
            onClick={async() => {
              await deleteNotification(user);
              toast({
                title: "Declined Invite",
                description: "Declined the space Invitation",
                variant: "destructive",
              });
            }}
            className="!border-[#8c888870] bg-black hover:!bg-red-500 hover:!border-none !px-4 "
          >
            Ignore
          </AlertDialogCancel>
          <AlertDialogAction
            onClick={async () => {
              await acceptInvite();
            }}
            className="!border-[#8c888870] bg-black hover:!bg-green-500 hover:!border-none !px-4"
          >
            Join
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default InvitePopup;
