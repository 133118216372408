import React, { useEffect, useState } from 'react';


const App = () => {
    
//   const isOnline = navigator.onLine;
  const [isOnline, setisOnline] = useState(navigator.onLine);
//   console.log(isOnline)

// useEffect(() => {
//     setisOnline(navigator.onLine);
// }, []);

  return (
    <div>
      {isOnline ? null :(
         <div className="offline-popup">
              <p>You are currently offline. Please check your internet .</p>
            </div>
      )}
      {/* The rest of your app */}
    </div>
  );
};

export default App;
