import React, { useState } from "react";
import SideBar from "./SideBar";
import Chat from "./Chat";
import Members from "./Members";
import Rules from "./Rules";
import CancelPresentationRoundedIcon from '@mui/icons-material/CancelPresentationRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Requests from "./Requests";
import {auth,db} from "./firebase";

const JoinedRoom = (props) => {
  const [mainmode, setmainmode] = useState(0);
  //0-> chat, 1-> members,2->desc
  // console.log(" joined rooooom");
  return (
    <div className="joinedwrapper ">
      <div className="joinedcard ">
        <div className="sidebar  ">
          <SideBar  setmainmode={setmainmode} roomData={props.roomData} />
        </div>
        
        <div className="main-joined-container col ">
        <div className='closebtn'
        onClick={()=>{props.setclicked(-1);}}>
        {/* <CancelPresentationRoundedIcon fontSize="large"/> */}
        <CloseRoundedIcon/>
        </div>
        
          {mainmode===0&&<Chat roomData={props.roomData}/>}
          {mainmode===1&&<Members roomData={props.roomData}/>}
          {mainmode===2&&<Rules roomData={props.roomData}/>}
          {props.roomData.admin_email===auth.currentUser.email&&mainmode===3&&<Requests roomData={props.roomData}/>}
        </div>
      </div>
    </div>
  );
};

export default JoinedRoom;
