import firebase from "firebase/compat/app";
import "firebase/compat/firestore";


const firestore = firebase.firestore();

const newservers = {
  iceServers: [
    {
      urls: [
        "stun:stun1.l.google.com:19302",
        "stun:stun2.l.google.com:19302",
        "stun:stun3.l.google.com:19302",
        "stun:stun4.l.google.com:19302",
      ],
    },
    {
      urls: 'turn:openrelay.metered.ca:80',
      username: 'openrelayproject',
      credential: 'openrelayproject'
  }
  ],
  iceCandidatePoolSize: 10,
};

// const servers = {
//   iceServers: [
//     {
//       urls: ["stun:stun1.l.google.com:19302", "stun:stun2.l.google.com:19302"],
//     },
//   ],
//   iceCandidatePoolSize: 10,
// };

// Global State
const pc = new RTCPeerConnection(newservers);
export let localStream = null;
export let remoteStream = null;
export let callId;
export let screenStream = null;
export let cameraStream = null;

export const NewAdminPermissionsStream = async () => {
  try {
    // Capture screen with audio
    screenStream = await navigator.mediaDevices.getDisplayMedia({
      video: true,
      audio: true,
    });

    // Capture camera and microphone
    cameraStream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true,
    });

    // Create a new MediaStream to combine both streams
    const combinedStream = new MediaStream();

    // Add screen video track
    const screenVideoTrack = screenStream.getVideoTracks()[0];
    combinedStream.addTrack(screenVideoTrack);

    // Add camera video track
    const cameraVideoTrack = cameraStream.getVideoTracks()[0];
    combinedStream.addTrack(cameraVideoTrack);

    // Decide which audio to use (screen audio or microphone audio)
    // Here we're using microphone audio, but you can change this as needed
    // const audioTrack = cameraStream.getAudioTracks()[0];
    // combinedStream.addTrack(audioTrack);
    const systemAudioTrack = screenStream.getAudioTracks()[0];
    // console.log(systemAudioTrack);
    if (systemAudioTrack) {
      // console.log("sending system audio");
      combinedStream.addTrack(systemAudioTrack);
    }


    // Add microphone audio track
    const micAudioTrack = cameraStream.getAudioTracks()[0];
    combinedStream.addTrack(micAudioTrack);

    // Send this combined stream to connected peers via WebRTC
    combinedStream.getTracks().forEach((track) => {
      pc.addTrack(track, combinedStream);
    });

    // Set up remote stream handling
    const remoteStream = new MediaStream();
    pc.ontrack = (event) => {
      event.streams[0].getTracks().forEach((track) => {
        remoteStream.addTrack(track);
      });
    };

    // Return the streams if needed elsewhere
    return { screenStream, cameraStream, remoteStream };
  } catch (error) {
    console.error("Error capturing media:", error);
  }
};

export const NewParticipantPermissionsStream = async () => {
  try {
    // Capture local camera and microphone
     localStream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true,
    });

    // Send local stream to connected peers via WebRTC
    localStream.getTracks().forEach((track) => pc.addTrack(track, localStream));

    // Set up remote streams
    const remoteScreenStream = new MediaStream();
    const remoteCameraStream = new MediaStream();

    pc.ontrack = (event) => {
      event.streams[0].getTracks().forEach((track) => {
        console.log("receiving something");
        if (track.kind === "video") {
          // Assume the first video track is the screen share, the second is the camera
          console.log("receiving",remoteScreenStream,remoteCameraStream.getAudioTracks());
          if (!remoteScreenStream.getVideoTracks().length) {
            remoteScreenStream.addTrack(track);
          } else {
            remoteCameraStream.addTrack(track);
          }
        } else if (track.kind === "audio") {
          // Add audio to both streams (you might want to adjust this based on your needs)
          remoteScreenStream.addTrack(track);
          remoteCameraStream.addTrack(track);
        }
      });
    };
    cameraStream = localStream;
    return { localStream, remoteScreenStream, remoteCameraStream };
  } catch (error) {
    console.error("Error setting up participant streams:", error);
  }
};

export const callButtonFunction = async () => {
  // Reference Firestore collections for signaling
  const callDoc = firestore.collection("calls").doc();
  const offerCandidates = callDoc.collection("offerCandidates");
  const answerCandidates = callDoc.collection("answerCandidates");

  //   callInput.value = callDoc.id;
  callId = callDoc.id;

  // Get candidates for caller, save to db
  pc.onicecandidate = (event) => {
    event.candidate && offerCandidates.add(event.candidate.toJSON());
  };

  // Create offer
  const offerDescription = await pc.createOffer();
  await pc.setLocalDescription(offerDescription);

  const offer = {
    sdp: offerDescription.sdp,
    type: offerDescription.type,
  };

  await callDoc.set({ offer });

  // Listen for remote answer
  callDoc.onSnapshot((snapshot) => {
    const data = snapshot.data();
    if (!pc.currentRemoteDescription && data?.answer) {
      const answerDescription = new RTCSessionDescription(data.answer);
      console.log('answer recieved');
      pc.setRemoteDescription(answerDescription);
    }
  });

  // When answered, add candidate to peer connection
  answerCandidates.onSnapshot((snapshot) => {
    snapshot.docChanges().forEach((change) => {
      if (change.type === "added") {
        console.log('candidate added to PC');
        const candidate = new RTCIceCandidate(change.doc.data());
        pc.addIceCandidate(candidate);
      }
    });
  });
  return callId;
  //   hangupButton.disabled = false;
};


export const answerButtonFunction = async (id) => {
  // const callId = callInput.value;
  callId = id;
  //   callId = callDoc.id;

  const callDoc = firestore.collection("calls").doc(callId);
  const answerCandidates = callDoc.collection("answerCandidates");
  const offerCandidates = callDoc.collection("offerCandidates");

  pc.onicecandidate = (event) => {
    event.candidate && answerCandidates.add(event.candidate.toJSON());
  };

  const callData = (await callDoc.get()).data();

  const offerDescription = callData.offer;
  await pc.setRemoteDescription(new RTCSessionDescription(offerDescription));

  const answerDescription = await pc.createAnswer();
  await pc.setLocalDescription(answerDescription);

  const answer = {
    type: answerDescription.type,
    sdp: answerDescription.sdp,
  };
  //   console.log(callId);
  await callDoc.update({ answer });

  offerCandidates.onSnapshot((snapshot) => {
    snapshot.docChanges().forEach((change) => {
        // console.log(change);
      if (change.type === "added") {
        let data = change.doc.data();
        pc.addIceCandidate(new RTCIceCandidate(data));
      }
    });
  });
};


export const hangUp = async () => {
  // Stop all local media tracks
  if (localStream) {
    localStream.getTracks().forEach(track => track.stop());
  }
  if (screenStream) {
    screenStream.getTracks().forEach(track => track.stop());
  }
  if (cameraStream) {
    cameraStream.getTracks().forEach(track => track.stop());
  }

  // Close the peer connection
  if (pc) {
    pc.close();
  }

  // Delete the signaling data from Firestore
  if (callId) {
    const callDoc = firestore.collection("calls").doc(callId);
    const offerCandidates = callDoc.collection("offerCandidates");
    const answerCandidates = callDoc.collection("answerCandidates");

    // Delete offer and answer candidates
    const deleteCollection = async (collectionRef) => {
      const snapshot = await collectionRef.get();
      const batch = firestore.batch();
      snapshot.docs.forEach(doc => batch.delete(doc.ref));
      await batch.commit();
    };

    await deleteCollection(offerCandidates);
    await deleteCollection(answerCandidates);

    // Delete the call document
    await callDoc.delete();
  }

  // Reset variables
  localStream = null;
  remoteStream = null;
  screenStream = null;
  cameraStream = null;
  callId = null;
};
