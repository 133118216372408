import React, { useState } from "react";

export default function CustomBots({ user }) {
    if(!user){return;}
    console.log("tig")
    const baseApiUrl = "https://api.dicebear.com/6.x/bottts/svg";
    function rand_ind(size) {
        return Math.floor(Math.random() * size);
    }
    const bgcolor = ["b6e3f4", "c0aede", "d1d4f9", "ffd5dc", "ffdfbf"];
    const basecolor = [
        "00acc1",
        "1e88e5",
        "5e35b1",
        "6d4c41",
        "7cb342",
        "8e24aa",
        "039be5",
        "43a047",
        "546e7a",
        "00897b",
        "3949ab",
        "757575",
        "c0ca33",
        "d81b60",
        "e53935",
        "f4511e",
        "fb8c00",
        "fdd835",
        "ffb300",
    ];
    const eyesarr = [
        "bulging",
        "dizzy",
        "eva",
        "frame1",
        "frame2",
        "glow",
        "happy",
        "hearts",
        "robocop",
        "round",
        "roundFrame01",
        "roundFrame02",
        "sensor",
        "shade01",
    ];
    const facearr = [
        "round01",
        "round02",
        "square01",
        "square02",
        "square03",
        "square04",
    ];
    const moutharr = [
        "bite",
        "diagram",
        "grill01",
        "grill02",
        "grill03",
        "smile01",
        "smile02",
        "square01",
        "square02",
    ];
    
    const toparr = ["antenna", "antennaCrooked", "bulb01", "glowingBulb01", "glowingBulb02"
        , "horns", "lights", "pyramid", "radar"];
    const sidesarr = ["antenna01", "antenna02", "cables01", "cables02"
        , "round", "square", "squareAssymetric"];
    console.log("cust");
    // console.log(user)
    const parameters = {
        backgroundColor: bgcolor[rand_ind(bgcolor.length)],
        eyes: eyesarr[rand_ind(eyesarr.length)],
        baseColor: basecolor[rand_ind(basecolor.length)],
        mouth: moutharr[rand_ind(moutharr.length)],
        face: facearr[rand_ind(facearr.length)],
        scale: "80",
        top: toparr[rand_ind(toparr.length)],
        sides:sidesarr[rand_ind(sidesarr.length)]
    };
    async function updatedp() {
        await user.updateProfile({ photoURL: avatarUrl })
        .catch((error) => {
            // Error updating user profile
            console.log(error);
          });
    }
    
    // Generate the URL with parameters
    const avatarUrl = `${baseApiUrl}?${new URLSearchParams(parameters)}`;
    if(user!=null&&!user.photoURL){
        updatedp();
        console.log("updated")
    }
        // console.log(user);
    return null;
}
