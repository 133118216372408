import React, { useRef } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { storage } from "./firebase";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";

const ProfileScroll = (props) => {
  const scrollRef = useRef(null);

  const scrollLeft = () => {
    scrollRef.current.scrollBy({
      top: 0,
      left: -200,
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    scrollRef.current.scrollBy({
      top: 0,
      left: 200,
      behavior: "smooth",
    });
  };

  // console.log(props);
  return (
    <>
      {!props.memberDisplay && <div className="profiletitle">Members</div>}
      <div className="profile-scroll">
        <button
          className="scroll-btn left btn btn-outline-dark"
          onClick={scrollLeft}
        >
          <KeyboardArrowLeftIcon />
        </button>
        <div className="profile-scroll" ref={scrollRef}>
          {props.members.map((profile) => (
            <div className="profile-card " key={profile.email}>
              {/* <img src={profile.imageurl} /> */}
              <Avatar className="  border-[#3d3b3b] !w-10 !h-10 mb-2">
                <AvatarImage className="" src={profile.imageurl} />
                <AvatarFallback className=" text-white font-bold bg-[#302d2d] ">
                  {profile.name[0]}
                </AvatarFallback>
              </Avatar>
              <p className="w-full truncate"
              >{profile.name}</p>
            </div>
          ))}
        </div>
        <button
          className="scroll-btn right btn btn-outline-dark"
          onClick={scrollRight}
        >
          <KeyboardArrowRightIcon />
        </button>
      </div>
    </>
  );
};

export default ProfileScroll;
