import React, { useState } from "react";
import Requestcards from "./Requestcards";
import { updateDoc,arrayUnion,setDoc ,doc} from "firebase/firestore";
import {db} from "./firebase";
import { collection,  deleteDoc } from "firebase/firestore";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useNavigate } from "react-router-dom";

export default function Requests(props) {
  const navigate=useNavigate();
    // async function addarray() {
    //     const roomRef = doc(db, "Rooms", props.roomData.roomname);
    //     await updateDoc(roomRef, {
    //         requests:[]
    //     });
    // }
    // if(!props.roomData.requests){addarray();
    // console.log("added array");}
    // console.log(props.roomData.requests);
    // console.log()
    const RoomRef = doc(collection(db, "Rooms"), props.roomData.roomname);
    const ChatsRef = doc(collection(db, "roomchats"), props.roomData.roomname);

    function deleteRoom() {
  deleteDoc(RoomRef)
  .then(() => {
    console.log("room successfully deleted.");
  })
  .catch((error) => {
    console.error("Error deleting document: ", error);
  });
  deleteDoc(ChatsRef)
  .then(() => {
    console.log("roomchats successfully deleted.");
  })
  .catch((error) => {
    console.error("Error deleting document: ", error);
  });
    }

  return (
    <>
    <div className="top-bar  ">
        <div className="joined-heading mt-2">Admin Panel</div>
      </div>
      <div className="seperaterline mt-2" style={{ width: "100%" }}></div>
    <div className="reqcont">
      <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 p-2 ">
        {props.roomData.requests.map((profile,index)=>(
            <Requestcards
            key={profile.email}
            index={index}
            profile={profile}
            roomname={props.roomData.roomname}
            />
        ))}
      </div>
    </div>
    <button className="redbutt d-flex align-items-center "
    onClick={()=>{
      const confirmed = window.confirm("Confirm Deletion");
      if(confirmed){deleteRoom();
      navigate("/rooms");
      }
      }}>
    <div className="" style={{marginBottom:"2px"}}
    ><DeleteOutlineOutlinedIcon/></div>
    Delete Room</button>

    </>
  );
}
