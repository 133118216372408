import React,{useRef} from 'react';

const Tags = (props) => {
    const scrollRef = useRef(null);
  let alltags=[props.tag1,props.tag2];
  const scrollLeft = () => {
    scrollRef.current.scrollBy({
      top: 0,
      left: -200,
      behavior: 'smooth',
    });
  };

  const scrollRight = () => {
    scrollRef.current.scrollBy({
      top: 0,
      left: 200,
      behavior: 'smooth',
    });
  };
  function randomrgb(){
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);
    return `rgb(${red}, ${green}, ${blue})`;
  }
  
    return (
        
      <div className="tag-scroll" style={{justifyContent:"center"}} ref={scrollRef}>
        {alltags.map((tag) => (
          <div className="tag-card" key={tag}>
          <div className="tag-dot" style={{ backgroundColor: randomrgb() }}></div>
            <p>{tag}</p>
          </div>
        ))}
      </div>
      
    );
}

export default Tags;
