import React, { useState } from 'react';
import CalendarHeatmap from 'react-calendar-heatmap';


function convertdataformat(data)
{
  if(!data){return ;}
  for(var i=0;i<data.length;i++)
  {
      data[i]["count"]=Math.min( 4,Math.floor((data[i]["dayminutes"]/40))+1);
  }
  return data;
}
const Calendar = (props) => {
  const data=convertdataformat (props.data);
  // console.log(data);
  // console.log(props.data);
  // const [daywisedata, setdaywisedata] = useState(data);
  // console.log(data);

  const [year, setYear] = useState(new Date().getFullYear());
  return(
    <>

  <div className="heatmap " >
    
  <div style={{padding:"1.5em", borderRadius:"6px"}}>
  {/* Year : {year} */}
  <br />
    <CalendarHeatmap
      startDate={new Date((year)+'-01-01')}
      endDate={new Date(year+'-12-31')}
      values={data}
      showOutOfRangeDays
      gutterSize={1.5}
      classForValue={(value) => {
        if (!value) {
          return 'color-empty';
        }
        return `color-github-${value.count}`;
      }}
      weekdayLabels={["Su ","Mo ","Tu ","We ","Th ","Fr ","Sa "]}
      // onMouseOver={(event,value)=>{console.log(event,value)}}
      tooltipDataAttrs={value => {
          return {
            'data-tooltip': ` has count: ${
              value.count
            }`
          };
        }}
      showWeekdayLabels={true}
    />
    </div>
  </div>
  </>
)};

export default Calendar;
