import React, { useState, useEffect } from "react";
import "./board.css";
import Profiles from "./Profiles";
import { SpinStretch } from "react-cssfx-loading";

const Leaderboard = () => {
  // console.log("in leaderboard");
  const [allusers, setallusers] = useState([]);
  const [mode, setmode] = useState(1);
  const [onlyFriends, setonlyFriends] = useState(0);

  //mode 0 means today data and 1 means all data
  const fetchUserData = async () => {
    // console.log("getting leader data");
    const oldDate = new Date();
    var curdate = oldDate.toDateString();

    let response = await fetch(
      //   "http://localhost:5000/api/leaderboard",
      "https://animedoro-backend.vercel.app/api/leaderboard",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          curDate: curdate,
        }),
      }
    );
    const json = await response.json();
    if (response.status === 200) {
      setallusers(json["allUsers"].sort(comparebytotal));
    }
  };
  function convertdata(data) {
    return data.sort();
  }
  function handleClick(cmod) {
    // console.log(cmod);
    if (cmod !== mode) {
      setmode(cmod);
      if (cmod === 0) {
        setallusers(allusers.sort(comparebytoday));
      } else {
        setallusers(allusers.sort(comparebytotal));
      }
    }
  }
  // console.log(allusers);
  function comparebytoday(a, b) {
    if (a.todayminutes > b.todayminutes) return -1;
    if (a.todayminutes > b.todayminutes) return 1;
    return 0;
  }
  function comparebytotal(a, b) {
    if (a.totalminutes > b.totalminutes) return -1;
    if (a.totalminutes < b.totalminutes) return 1;
    return 0;
  }
  useEffect(() => {
    fetchUserData();
  }, []);
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div className="board">
        <link rel="stylesheet" href="./board.css" />
        <h1
          className="leaderboard "
          style={{ textAlign: "center", alignItems: "center" }}
        >
          Leaderboard
        </h1>
        <div className="duration">
          <button
            onClick={() => {
              handleClick(0);
            }}
            data-id="0"
            className={`${mode === 0 && "clicked"}`}
          >
            Today
          </button>
          {/* 0 means for current date and 1 means for all the dates */}
          <button
            onClick={() => {
              handleClick(1);
            }}
            data-id="1"
            className={`${mode === 1 && "clicked"}`}
          >
            All Time
          </button>
        </div>

        <div className="duration" style={{ paddingTop: "30px" }}>
          <button
            onClick={() => {
              setonlyFriends(0);
            }}
            className={`${onlyFriends === 0 && "clicked"}`}
          >
            All{" "}
          </button>
          <button
            onClick={() => {
              setonlyFriends(1);
            }}
            className={`${onlyFriends === 1 && "clicked"}`}
          >
            Friends
          </button>
        </div>
        {allusers.length!==0? (
          <Profiles
            boarddata={allusers}
            mode={mode}
            onlyFriends={onlyFriends}
          ></Profiles>
        ):(
          <div className="my-5" style={{ marginLeft: "48%" }}>
          <SpinStretch color="#F5E8C7" />
        </div>
        )}
      </div>
    </div>
  );
};

export default React.memo(Leaderboard);
