import React, { useState, useEffect } from "react";
import Calendar from "../components/CalenderHeatmap";
import { Link, useLocation } from "react-router-dom";

import { Ring, Hypnosis, TwinSpin, SpinStretch } from "react-cssfx-loading";
import Navbar from "../components/Navbar";
import LineChart from "../components/LineChart";
import Leaderboard from "../components/Leaderboard.jsx";
import { db } from "../components/firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";
import {
  setDoc,
  doc,
  collection,
  addDoc,
  query,
  updateDoc,
  arrayUnion,
  orderBy,
  limit,
  getDocs,
  where,
} from "firebase/firestore";
import BreathingCircle from "../components/BreathingCircle.jsx";
import { Statbox } from "../components/Statbox.jsx";
import NumberTicker from "../components/ui/number-ticker";
import BlurIn from "../components/ui/blur-text";
// import { CleaningServices } from "@mui/icons-material";

const data = [
  { date: "2022-03-26", count: 3 },
  { date: "2022-03-02", count: 1 },
  { date: "2022-03-03", count: 3 },
  { date: "2022-03-04", count: 1 },
  { date: "2022-03-05", count: 4 },
  // add more data for the month
];

function convertDataMonth(datarec) {
  var monthcounts = [];
  var monthdates = [];
  if (!datarec) {
    return {};
  }
  for (var i = 0; i < datarec.length; i++) {
    //todo
    monthcounts.push(datarec[i]["dayminutes"]);
    monthdates.push(datarec[i]["date"]);
  }
  // console.log(monthcounts,monthdates)
  return { monthcounts, monthdates };
}
async function statsExtract(data) {
  let max_dur = 0,
    max_streak = 0,
    cur_streak = 0;
  if (data.length == 0) {
    return { max_dur, max_streak, cur_streak };
  }
  const oneDayInMillis = 24 * 60 * 60 * 1000;
  let dateObject;
  //to extract max duration in a day, max streak , cur streak
  for (var i = 0; i < data.length; i++) {
    //todo
    // console.log(data[i]["dayminutes"])
    const parts = data[i]["date"].split("-");
    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1] - 1, 10); // Months are zero-based in JavaScript
    const day = parseInt(parts[2], 10);

    dateObject = new Date(year, month, day);

    if (max_dur < parseInt(data[i]["dayminutes"])) {
      max_dur = parseInt(data[i]["dayminutes"]);
    }

    if (i != 0) {
      const parts = data[i - 1]["date"].split("-");
      const year = parseInt(parts[0], 10);
      const month = parseInt(parts[1] - 1, 10); // Months are zero-based in JavaScript
      const day = parseInt(parts[2], 10);
      const prev = new Date(year, month, day);
      const cd = dateObject;
      // const prev = new Date(data[i - 1]["date"]);
      // console.log(data[i]["date"],dateObject);
      const time_dif = cd - prev;
      // console.log(time_dif)
      if (time_dif == oneDayInMillis) {
        cur_streak++;
      } else {
        cur_streak = 0;
      }
    }
    if (cur_streak + 1 > max_streak) {
      max_streak = cur_streak + 1;
    }
  }
  const cur_date = new Date().toDateString();
  const yesterday = new Date(
    new Date().getTime() - oneDayInMillis
  ).toDateString();
  const tomorrow = new Date(
    new Date().getTime() + oneDayInMillis
  ).toDateString();
  cur_streak++;
  // console.log(cur_streak)
  // console.log(yesterday);
  // console.log(tomorrow);
  // console.log(cur_date);
  const lastfocusday = dateObject.toDateString();

  if (
    lastfocusday != cur_date &&
    lastfocusday != tomorrow &&
    lastfocusday != yesterday
  ) {
    // console.log("y")
    cur_streak = 0;
  }
  // console.log(max_dur,max_streak,cur_streak)
  return { max_dur, max_streak, cur_streak };
}
const Stats = (props) => {
  const location = useLocation();
  const propsData = location.state;
  // console.log(propsData.email);

  const [userEmail, setuserEmail] = useState(
    localStorage.getItem("userEmail") || "example@gmail.com"
  );
  // console.log(userEmail);

  const usersRef = collection(db, "newusers");
  const allusersQuery = query(usersRef);
  const [allusers] = useCollectionData(allusersQuery, { idField: "id" });
  // console.log(allusers);
  console.log("in Stats");
  // console.log(allusers);

  // console.log("use eff");
  if (propsData && propsData.email != userEmail) {
    setuserEmail(propsData.email);
  }

  // console.log("render stats");

  const [calendardata, setcalendardata] = useState(data);
  const [monthlydata, setmonthlydata] = useState({});
  const [totalfocusminutes, settotalfocusminutes] = useState(-1);
  const [maxfocusdur, setMaxfocusdur] = useState(0);
  const [userName, setuserName] = useState("");
  const [showScrollBtn, setShowScrollBtn] = useState(false);
  const [maxStreak, setMaxStreak] = useState(0);
  const [maxDuration, setMaxDuration] = useState(0);
  const [currentStreak, setCurrentStreak] = useState(0);
  const [photodp, setphotodp] = useState("");
  const fetchUserData = async () => {
    // settotalfocusminutes(-1);
    // console.log("getting data");
    const oldDate = new Date();
    var curdate = oldDate.toDateString();

    // console.log(userEmail);

    let response = await fetch(
      // "http://localhost:5000/api/getstats",
      "https://animedoro-backend.vercel.app/api/getstats",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: userEmail,
          curDate: curdate,
        }),
      }
    );
    const json = await response.json();
    if (response.status === 200) {
      settotalfocusminutes(json.totalFocusMinutes);
      // console.log(json.heatmapdays)
      setcalendardata(json.heatmapdays);
      setuserName(json.name);
      setmonthlydata(convertDataMonth(json.heatmapdays));
      let { max_dur, max_streak, cur_streak } = await statsExtract(
        json.heatmapdays
      );
      // console.log(sts);
      setMaxDuration(max_dur);
      setMaxStreak(max_streak);
      setCurrentStreak(cur_streak);
    }
  };
  useEffect(() => {
    fetchUserData();
  }, [userEmail]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setShowScrollBtn(scrollTop > 4000);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleGoToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Optional: Add smooth scrolling effect
    });
  };
  // console.log("cd");
  // console.log(calendardata);
  return (
    <div
      className="statsscroll font-mod"
      style={{ backgroundColor: "black", minHeight: "100vh" }}
    >
      <Navbar />
      {/* <Statbox/> */}
      {/* <BreathingCircle/> */}

      <button
        id="ScrollTopBtn"
        style={{ display: showScrollBtn ? "" : "none" }}
        onClick={handleGoToTop}
      >
        ↑
      </button>

      {totalfocusminutes === -1 ? (
        <div className="my-5" style={{ marginLeft: "49vw" }}>
          <SpinStretch color="#F5E8C7" />
        </div>
      ) : (
        <div>
          <div className="flex flex-col justify-center items-center mt-5">
            {photodp.length !== 0 && (
              <img
                className="w-[80px] h-[80px] rounded-2xl"
                src={`${photodp}`}
                alt="user dp"
              />
            )}
            <p
              className="stats-namehead font-bold mb-2 "
              style={{
                textAlign: "center",
                backgroundColor: "black",
                fontWeight: "1000",
                fontSize: "3em",
                paddingLeft: "1em",
                paddingRight: "1em",
                marginTop: "20px",
              }}
            >
              {userName && (
                <BlurIn
                className="!text-5xl mb-4"
                  word={userName.charAt(0).toUpperCase() + userName.slice(1)}
                />
              )}
            </p>
          </div>
          <div className="flex justify-center items-center flex-wrap gap-4 mx-5 my-3">
            {monthlydata && monthlydata["monthdates"] && (
              <LineChart
                monthdata={monthlydata}
                totalfocusminutes={totalfocusminutes}
              />
            )}
            <div>
              <div className="focuschart smallstatcard p-2 mb-4 ">
                <div className="text-lg font-bold text-center mb-3">
                  Streak 🔥
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // marginBottom:"10px"
                  }}
                >
                  {/* <div className="text-sm">Current Streak :</div> */}
                  <div className="text-5xl text-center font-bold">
                    <NumberTicker value={currentStreak} />
                  </div>
                </div>
                <div
                  className="mt-2"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div className="text-gray-600 text-sm ml-2">
                    Max Focus Streak:{" "}
                  </div>
                  <div className="text-gray-600 text-2sm mr-2 font-bold">
                    <NumberTicker value={maxStreak} />
                  </div>
                </div>
              </div>
              <div className="focuschart smallstatcard p-2">
                <div className="ml-3 text-lg font-bold text-center mb-3">
                  Focus Days
                  <svg
                    className=" fontdull inline-block "
                    // style={{display:"inline-block"}}
                    width="22px"
                    height="22px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M20 20V13M12 20V10M4 20L4 16M13.4067 5.0275L18.5751 6.96567M10.7988 5.40092L5.20023 9.59983M21.0607 6.43934C21.6464 7.02513 21.6464 7.97487 21.0607 8.56066C20.4749 9.14645 19.5251 9.14645 18.9393 8.56066C18.3536 7.97487 18.3536 7.02513 18.9393 6.43934C19.5251 5.85355 20.4749 5.85355 21.0607 6.43934ZM5.06066 9.43934C5.64645 10.0251 5.64645 10.9749 5.06066 11.5607C4.47487 12.1464 3.52513 12.1464 2.93934 11.5607C2.35355 10.9749 2.35355 10.0251 2.93934 9.43934C3.52513 8.85355 4.47487 8.85355 5.06066 9.43934ZM13.0607 3.43934C13.6464 4.02513 13.6464 4.97487 13.0607 5.56066C12.4749 6.14645 11.5251 6.14645 10.9393 5.56066C10.3536 4.97487 10.3536 4.02513 10.9393 3.43934C11.5251 2.85355 12.4749 2.85355 13.0607 3.43934Z"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // marginBottom:"10px"
                  }}
                >
                  {/* <div className="text-sm">Current Streak :</div> */}

                  <div className="text-5xl text-center font-bold">
                    <NumberTicker value={calendardata.length} />
                  </div>
                </div>
                <div
                  className="mt-2"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* <div style={{display:"flex",alignItems:"center"}}> <div style={{fontSize:"0.6em",margin:"4px"}}>mins</div>⚒️</div> */}
                  <div className="text-gray-600 text-sm ml-2">
                    Max Focus Day:{" "}
                  </div>
                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    className="text-gray-600 text-2sm mr-2 font-bold"
                  >
                    <NumberTicker value={maxDuration} />
                    <div style={{ fontSize: "0.6em", margin: "4px" }}>mins</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {propsData &&
            allusers &&
            Object.values(allusers[0]).map((usr) => {
              if (usr.email === propsData.email) {
                {
                  /* {console.log(usr)} */
                }
                if (usr.img && usr.img !== photodp) {
                  {
                    /* console.log(usr); */
                  }
                  setphotodp(usr.img);
                }
                return (
                  <div
                    key={usr.id}
                    className="d-flex"
                    style={{ flexDirection: "column", alignItems: "center" }}
                  >
                    <div
                      className="tag-card rounded-xl shadow-xl my-3"
                      style={{ width: "200px", height: "50px" }}
                    >
                      <div
                        className="tag-dot "
                        style={{
                          backgroundColor: usr.studying ? "green" : "red",
                          width: "15px",
                          height: "15px",
                          marginRight: "20px",
                        }}
                      ></div>

                      <p style={{ fontSize: "1.2em" }}>
                        {usr.studying ? "Studying" : "Resting"}
                      </p>
                    </div>
                  </div>
                );
              }
            })}
        </div>
      )}
      <hr style={{ borderColor: "white" }} />
      <h2
        className="ml-4 font-bold mt-3 text-center"
        style={{ fontSize: "2rem" }}
      >
        Calendar Heatmap
      </h2>
      <div className="flex justify-center items-center">
        <div style={{ overflowX: "scroll", whiteSpace: "nowrap" }}>
          <div className="" style={{ width: "1000px" }}>
            <Calendar data={calendardata} />
          </div>
        </div>
      </div>
      {/* <hr style={{ borderColor: "white" }} /> */}

      <hr style={{ borderColor: "white" }} />
      <div style={{ padding: "1em" }} id="main">
        <Leaderboard />
      </div>
    </div>
  );
};

export default React.memo(Stats);
