import React from "react";
import { Avatar, AvatarFallback, AvatarImage } from "./ui/avatar";

export default function MemberCards(props) {
  return (
    <div className=" mb-3 col d-flex justify-content-center ">
      <div className="requestcard">
        {/* <img
          style={{ width: "50px", height: "50px", marginBottom: "15px" }}
          src={props.profile.imageurl}
          alt=""
        /> */}
        <Avatar className="  border-[#3d3b3b] !w-[50px] !h-[50px] mb-2">
          <AvatarImage className="!w-[50px] !h-[50px]" src={props.profile.imageurl} />
          <AvatarFallback className=" text-white font-bold bg-[#302d2d] ">
            {props.profile.name[0]}
          </AvatarFallback>
        </Avatar>
        <div className="requestname">{props.profile.name}</div>
      </div>
    </div>
  );
}
