import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

export function SortableItem({
  id,
  priority,
  title,
  completed,
  setchecked,
  deletetodo,
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const colorarr = ["#ef4444", "#facc15", "#22c55e"];
  return (
    <div
      className="flex items-center justify-between cursor-default group overflow-x-hidden w-[300px] h-[48px] bg-[#232020] hover:bg-[#333030] font-semibold rounded-xl my-2   light-black  px-3 "
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
    <div className="flex justify-center items-center">
      <div
        onClick={() => {
          setchecked(id);
        }}
        className="cursor-pointer   left-9 rounded-3xl w-4 h-4  border-2 border-[#302d2d] mr-3 flex"
        style={{ background: !completed && colorarr[parseInt(priority)] }}
      ></div>
      <div
        onClick={() => {
          setchecked(id);
        }}
        className="text-center cursor-pointer"
        style={{
          textDecoration: completed ? "line-through" : "",
        }}
      >
        {title}
      </div>
      </div>
      {completed===false?<div
        {...listeners}
        className="hidden group-hover:block  justify-self-end cursor-row-resize w-12"
      >
        <svg
          width="20px"
          height="20px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 9L2 12M2 12L5 15M2 12H22M9 5L12 2M12 2L15 5M12 2V22M15 19L12 22M12 22L9 19M19 9L22 12M22 12L19 15"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          
        </svg>
        
      </div>
      :
      <div
        className="cursor-pointer hidden group-hover:block   w-12"
        onClick={()=>deletetodo(id)}
      >
      <svg
          className="text-red-600 "
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 6V5.2C16 4.0799 16 3.51984 15.782 3.09202C15.5903 2.71569 15.2843 2.40973 14.908 2.21799C14.4802 2 13.9201 2 12.8 2H11.2C10.0799 2 9.51984 2 9.09202 2.21799C8.71569 2.40973 8.40973 2.71569 8.21799 3.09202C8 3.51984 8 4.0799 8 5.2V6M10 11.5V16.5M14 11.5V16.5M3 6H21M19 6V17.2C19 18.8802 19 19.7202 18.673 20.362C18.3854 20.9265 17.9265 21.3854 17.362 21.673C16.7202 22 15.8802 22 14.2 22H9.8C8.11984 22 7.27976 22 6.63803 21.673C6.07354 21.3854 5.6146 20.9265 5.32698 20.362C5 19.7202 5 18.8802 5 17.2V6"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          </div>}
    </div>
  );
}
