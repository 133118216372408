import { CleaningServices } from "@mui/icons-material";

import Draggable from "react-draggable";
import { useRef } from "react";
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import VolumeOffOutlinedIcon from '@mui/icons-material/VolumeOffOutlined';
import React, { useState, useEffect } from "react";
function BreathingCircle({breathevisible}) {
    const nodeRef = useRef(null);
    const bounds = { left: -100, top: -20, right: 1050, bottom: 300 };

  const [startBreathe, setstartBreathe] = useState(0);
  const [status, setStatus] = useState("Start");
  let inhaleaudio = new Audio("./audios/wind2.mp3");
  inhaleaudio.playbackRate = 0.9;
  inhaleaudio.volume = 0.4;
  let exhaleaudio = new Audio("./audios/wind2rev.mp3");
  exhaleaudio.playbackRate = 0.75;
  exhaleaudio.volume = 0.4;
  //   const [duration, setduration] = useState(0);
  useEffect(() => {
    const circle = document.getElementById("breathing-circle");
    // let count=0;
    function delay(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
    const breathe = async (count) => {
      setStatus("Inhale");
      inhaleaudio.play();
      circle.style.animation = "breathe 19s ease-in-out infinite";
      await delay(4000);
      inhaleaudio.pause();
      inhaleaudio.currentTime=0;
      setStatus("Hold");
      await delay(6000);
      setStatus("Exhale");
      exhaleaudio.play();
      await delay(8000);
      exhaleaudio.pause();
      exhaleaudio.currentTime=0;
      if (count < 3) {
        await breathe(count + 1);
      }
    //   console.log("end")
    };
    async function checkBreathe() {
      if (startBreathe === 1) {
        breathe(1).then(() => {
        //   console.log("done");
          setStatus("Start");
          setstartBreathe(0);
          circle.style.animation = "";

        });
      }
    }
    checkBreathe();
  }, [startBreathe]);
  return (
    <Draggable nodeRef={nodeRef} handle=".header" 
    bounds="parent"
    defaultPosition={{ x: 10, y: 10 }}
    >
    <div ref={nodeRef} style={{visibility:breathevisible?"visible":"hidden"}} className="breathing-widget absolute">
    <div className="header d-flex  justify-content-center align-items-center">
    <div className="grabbar"></div>
             </div>
      <div className="breathe-title">Breathe 🍃 </div>
      <div className="breathe-duration" >1 min</div>
        {/* <div style={{display:"inline-block"}}><VolumeUpOutlinedIcon/></div> */}
      
      <div className="d-flex justify-content-center align-items-center breathe-container">
        <div id="breathing-circle" className="d-flex justify-content-center align-items-center breathing-circle">
        <div
        className="breathe-status "
        onClick={() => {
          if (status === "Start") {
            setstartBreathe(1);
          }
        }}
      >
        {status}
      </div>
        </div>
      </div>
      
    </div>
    </Draggable>
  );
}

export default BreathingCircle;
