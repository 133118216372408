import { realtimeDb } from "../components/firebase";

export const checkSpaceExists = async (spaceId) => {
    try {
      const spaceRef = realtimeDb.ref(`spaces/${spaceId}`);
      const snapshot = await spaceRef.once('value');
      // console.log(snapshot.val());
      return snapshot.exists()?snapshot.val():false;
    } catch (error) {
      console.error('Error checking space existence:', error);
      return false;
    }
  };
  