import { realtimeDb } from "../components/firebase";
import { deleteSpace } from "./delete-space";
import { exitSpace } from "./exit-space";

export const listenForSpaceChanges = async (
  spaceKey,
  onTimerStart,
  onUserJoin,
  isAdmin,
  userId
) => {
  const spaceRef = realtimeDb.ref(`/spaces/${spaceKey}`);

  // Attach a listener to the entire space object
  spaceRef.on("value", (snapshot) => {
    const spaceData = snapshot.val();
    if (spaceData&&spaceData.timer.isRunning) {
      onTimerStart();
    }
    if (spaceData&&spaceData.joined) {
      onUserJoin(spaceData.joined);
    }
  });

  // Return a function to detach the listener
  return async () => {
    spaceRef.off("value");
    if (isAdmin) {
      await deleteSpace(spaceKey);
    }
    else{
      await exitSpace(spaceKey,)
    }
  };
};

// Usage example:
// const spaceKey = 'your-space-key';
// const handleTimerStart = () => {
//   console.log('Timer has started running!');
//   // Add your function logic here
// };

// const handleUserJoin = (participants) => {
//   console.log('New user joined:', participants);
//   // Add your function logic here
// };

// const detachListener = listenForSpaceChanges(spaceKey, handleTimerStart, handleUserJoin);

// To detach the listener when it's no longer needed
// detachListener();
