// createSpace.js
import { realtimeDb,firebase } from '../components/firebase';
import { useRecoilState } from 'recoil';
import { spaceState } from '../recoilState/spaceState';

// Define a function to create a space
export const createSpace = async (spaceData) => {
    try {
      // Generate a new key for the space
      const newSpaceKey = realtimeDb.ref().child('spaces').push().key;
    //  console.log(newSpaceKey);
      // Create the space object
      const space = {
        id: newSpaceKey,
        ...spaceData,
        createdAt: firebase.database.ServerValue.TIMESTAMP
      };
  
      // Write the new space's data to the database
      const updates = {};
      updates[`/spaces/${newSpaceKey}`] = space;
  
      await realtimeDb.ref().update(updates);
     
      console.log('Space created successfully');
      return space;
    } catch (error) {
      console.error('Error creating space:', error);
      return null;
    }
  };
  
  // Usage example

  
