import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';
// import ""
function AutohideToast(props) {

  return (<div
 className='noti-toast '
  >
        <Toast onClose={() => props.setShow(false)} show={props.show} delay={props.delay}  
         autohide>
          <Toast.Header className='toast-dark'>
            <div
            style={{width:"20px",height:"20px"}}
              className="logo rounded me-2"
            />
            <strong className="me-auto">Study Together </strong>
            {/* <small>11 mins ago</small> */}
          </Toast.Header>
          <Toast.Body
          className='toast-dark-body'
          style={{color:"black",fontWeight:"500"}}
          >Study Together initiates in 5 seconds! ✅✅  !</Toast.Body>
        </Toast>
    </div>
  );
}

export default AutohideToast;