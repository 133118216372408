import { useEffect, useState } from "react";

import AnimatedCircularProgressBar from "../../ui/circular-animated-progressbar"; 
import Timer from "../create/timer";

export function AnimatedTimer({time}) {
  const [value, setValue] = useState(0);
  // console.log(time);
  const [timerFocusMinutes, settimerFocusMinutes] = useState(time);
  // console.log(timerFocusMinutes);
  return (
    <AnimatedCircularProgressBar
    className=" text-white "
      max={time}
      min={0}
      value={time-value-1}
      gaugePrimaryColor="#e7d877" //"#e7d877"//"#3a3030" //"#867777" //"#e7d877"
      gaugeSecondaryColor="white"
    >
        <Timer timerFocusMinutes={timerFocusMinutes} runMode={true} setMinutesValue={setValue}/>
    </AnimatedCircularProgressBar>
  );
}
