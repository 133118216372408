// spaceState.js
import { atom } from 'recoil';

export const spaceState = atom({
  key: 'spaceState',
  default: null
  // {
  //   isAdmin:true,
  //   createdBy: "Divyam",
  //   timer: {
  //     duration: 5,
  //     isRunning: false,
  //     // endTime: ,
  //   },
  //   participants: [],
  //   joined: [],
  // }
});

// const spaceData = {
//   createdBy: "Divyam",
//   timer: {
//     duration: 40,
//     isRunning: false,
//     // endTime: ,
//   },
//   participants: [],
//   joined: [],
// };
