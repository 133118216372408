import { realtimeDb } from "../components/firebase";

export const exitSpace = async (spaceId, userId) => {
  try {
    const spaceRef = realtimeDb.ref(`spaces/${spaceId}/joined`);
    const snapshot = await spaceRef.once("value");
    const members = snapshot.val() || [];

    const updatedMembers = members.filter((member) => member.id !== userId);

    await spaceRef.set(updatedMembers);
    console.log(`User with ID ${userId} has exited space with ID ${spaceId}.`);

    return updatedMembers;
  } catch (error) {
    console.error("Error exiting space:", error);
    return null;
  }
};
